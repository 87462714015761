import type { DBTables } from './db-tables.gen'

export type PkTableNames = {
  [P in keyof DBTables]: DBTables[P] extends { id: number | string } ? P : never
}[keyof DBTables]

export type PkTables = Pick<DBTables, PkTableNames>

export type UUIDTableNames = {
  [P in keyof DBTables]: DBTables[P] extends { uuid: string } ? P : never
}[keyof DBTables]

export type UUIDTables = Pick<DBTables, UUIDTableNames>
