import { useCallback, useEffect } from 'react'
import { useDatabaseWorker } from '../components/Context/DatabaseWorkerContext'
import { downloadFile } from './downloadFile'
import type {
  FullLogMessageReady,
  GetFullLogMessage,
} from '../webworkers/database.worker'

export function useHandleDownloadMessage() {
  const { worker } = useDatabaseWorker()
  const handleDownloadMessage = useCallback(
    (eventId: string | number) => {
      worker.postMessage({
        type: 'GET_FULL_LOG_MESSAGE',
        payload: {
          eventId,
        },
      } as GetFullLogMessage)
    },
    [worker]
  )
  useEffect(() => {
    function handleDownload(event: MessageEvent<FullLogMessageReady>) {
      switch (event.data.type) {
        case 'FULL_LOG_MESSAGE_READY':
          downloadFile(event.data.payload)
          break
        default:
          break
      }
    }
    worker.addEventListener('message', handleDownload)
    return () => {
      worker.removeEventListener('message', handleDownload)
    }
  }, [worker])
  return {
    handleDownloadMessage,
  }
}
