import React, { useEffect, useState } from 'react'
import { useReplayContext } from '../../Context/ReplayContext'
import { ResizableWrapper } from '../ResizableWrapper/ResizableWrapper'
import { DevToolTabIds, DevToolsHeader } from './DevToolsHeader/DevToolsHeader'
import { SnapshotChangeContextProvider } from '../../Context/SnapshotChangeContext'
import { NetworkPanel } from './NetworkPanel/NetworkPanel'
import { ConsolePanel } from './ConsolePanel/ConsolePanel'
import styles from './DevTools.module.scss'
import cs from 'clsx'

export const DevTools: React.FC = () => {
  const {
    devtools: { toggle },
    helpers: { pin, clearPinned, setIsDragging, isDragging },
  } = useReplayContext()
  const [tabId, setTabId] = useState<DevToolTabIds>('network')

  // ensures that pin state and devtools state remain in sync,
  // like when mounting test-replay with URL params about pins:
  useEffect(() => {
    if (pin?.section === 'console-logs') {
      setTabId('console')
    } else if (pin?.section === 'network-calls') {
      setTabId('network')
    }
  }, [pin?.section])

  return (
    <ResizableWrapper
      min={400}
      initial={425}
      max={800}
      drag="left"
      setIsDragging={setIsDragging}
    >
      <div
        className={cs(
          styles['devtools-container'],
          // prevent interacting with internals of the
          // devtools while resizing the container:
          isDragging && styles['disable-mouse']
        )}
        data-cy="devtools-container"
      >
        <div className={styles['devtools-header-container']}>
          <DevToolsHeader
            pin={pin ?? undefined}
            clearPinned={clearPinned}
            selectedTab={tabId}
            onSelectedTab={setTabId}
            onHeaderClose={toggle}
          />
        </div>
        <div className={styles['devtools-body-container']}>
          {tabId === 'network' && (
            <SnapshotChangeContextProvider>
              <NetworkPanel />
            </SnapshotChangeContextProvider>
          )}
          {tabId === 'console' && (
            <SnapshotChangeContextProvider>
              <ConsolePanel />
            </SnapshotChangeContextProvider>
          )}
        </div>
      </div>
    </ResizableWrapper>
  )
}
