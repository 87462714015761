/**
 * Ensures that the href is like a url. Useful for resolving against the document.
 * converts things like: <style href='test.css'> into /test.css to resolve against the document properly.
 * https://www.w3.org/TR/WD-html40-970917/htmlweb.html
 * see 5.2.1 Relative URLS
 *
 * @param href any url within the document
 * @returns processed href for resolving
 */
export const ensureHrefLikeUrl = (href?: string) => {
  if (!href) {
    return undefined
  }
  return !(
    href.startsWith('http') ||
    href.startsWith('/') ||
    href.startsWith('.')
  )
    ? `./${href}`
    : href
}
