import React from 'react'
import styles from './NetworkPanel.module.scss'

export const NetworkPanelEmptyState = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 12C4 10.3431 5.34315 9 7 9H24C25.6569 9 27 10.3431 27 12V16C27 17.6569 25.6569 19 24 19H7C5.34315 19 4 17.6569 4 16V12ZM7 11C6.44772 11 6 11.4477 6 12V16C6 16.5523 6.44772 17 7 17H24C24.5523 17 25 16.5523 25 16V12C25 11.4477 24.5523 11 24 11H7ZM11 14C11 13.4477 11.4477 13 12 13H22C22.5523 13 23 13.4477 23 14C23 14.5523 22.5523 15 22 15H12C11.4477 15 11 14.5523 11 14ZM9 15C9.55228 15 10 14.5523 10 14C10 13.4477 9.55228 13 9 13C8.44772 13 8 13.4477 8 14C8 14.5523 8.44772 15 9 15Z"
          fill="#9095AD"
        />
        <rect x="6" y="23" width="27" height="6" rx="1" fill="#2E3247" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 24C4 22.3431 5.34315 21 7 21H32C33.6569 21 35 22.3431 35 24V28C35 29.6569 33.6569 31 32 31H7C5.34314 31 4 29.6569 4 28V24ZM7 23C6.44772 23 6 23.4477 6 24V28C6 28.5523 6.44772 29 7 29H32C32.5523 29 33 28.5523 33 28V24C33 23.4477 32.5523 23 32 23H7ZM11 26C11 25.4477 11.4477 25 12 25H30C30.5523 25 31 25.4477 31 26C31 26.5523 30.5523 27 30 27H12C11.4477 27 11 26.5523 11 26ZM9 27C9.55228 27 10 26.5523 10 26C10 25.4477 9.55228 25 9 25C8.44772 25 8 25.4477 8 26C8 26.5523 8.44772 27 9 27Z"
          fill="#9095AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 36C4 34.3431 5.34315 33 7 33H39C40.6569 33 42 34.3431 42 36V40C42 41.6569 40.6569 43 39 43H7C5.34315 43 4 41.6569 4 40V36ZM7 35C6.44772 35 6 35.4477 6 36V40C6 40.5523 6.44772 41 7 41H39C39.5523 41 40 40.5523 40 40V36C40 35.4477 39.5523 35 39 35H7ZM11 38C11 37.4477 11.4477 37 12 37H37C37.5523 37 38 37.4477 38 38C38 38.5523 37.5523 39 37 39H12C11.4477 39 11 38.5523 11 38ZM9 39C9.55228 39 10 38.5523 10 38C10 37.4477 9.55228 37 9 37C8.44772 37 8 37.4477 8 38C8 38.5523 8.44772 39 9 39Z"
          fill="#9095AD"
        />
        <path
          d="M38 15L41 18M41 18L44 15M41 18L41 11M32 7L35 4M35 4L38 7M35 4L35 11"
          stroke="#9095AD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <span className={styles['emptyStateDescription']}>
        No network requests available
      </span>
    </>
  )
}
