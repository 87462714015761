import { useCallback, useState } from 'react'

export const useDrawerOpenStorage = (drawerName: 'commands' | 'devtools') => {
  const PANEL_KEY = `test-replay:drawer:open:${drawerName}`
  const openState = localStorage.getItem(PANEL_KEY)

  // if no state is found, then we force it open:
  const isOpenInStorage = openState === null ? true : !!Number(openState)
  const [isOpen, setIsOpen] = useState(isOpenInStorage)

  const update = useCallback(() => {
    localStorage.setItem(PANEL_KEY, isOpen ? '0' : '1')
    setIsOpen(!isOpen)
  }, [PANEL_KEY, isOpen])

  const forceOpen = useCallback(() => setIsOpen(true), [])

  return {
    isOpen,
    updateIsOpen: update,
    forceOpen,
  }
}
