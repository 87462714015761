import 'css-chunk:src/components/ReplayFooter/Scrubber/ScrubberThumb/ScrubberThumb.module.scss';export default {
  "mock-thumb-container": "_mock-thumb-container_1tp1t_7",
  "mock-thumb": "_mock-thumb_1tp1t_7",
  "pads": "_pads_1tp1t_18",
  "mock-thumb-dynamic": "_mock-thumb-dynamic_1tp1t_27",
  "thumb-hit-box": "_thumb-hit-box_1tp1t_33",
  "scrubber-time": "_scrubber-time_1tp1t_42",
  "thumb-hit-peak": "_thumb-hit-peak_1tp1t_50",
  "passing": "_passing_1tp1t_60",
  "failing": "_failing_1tp1t_70",
  "ghost": "_ghost_1tp1t_80",
  "ghost-light-mode": "_ghost-light-mode_1tp1t_90",
  "ghost-range-container": "_ghost-range-container_1tp1t_100"
};