import 'css-chunk:src/components/ReplayBody/DevTools/ConsolePanel/ConsoleLogListItem/ConsoleLogListItem.module.scss';export default {
  "tableContainer": "_tableContainer_1ceo9_7",
  "leadingContainer": "_leadingContainer_1ceo9_42",
  "waterfallPillContainer": "_waterfallPillContainer_1ceo9_49",
  "icon": "_icon_1ceo9_54",
  "icon-info": "_icon-info_1ceo9_59",
  "icon-ghost": "_icon-ghost_1ceo9_64",
  "message": "_message_1ceo9_68",
  "italicMessage": "_italicMessage_1ceo9_76",
  "ghost": "_ghost_1ceo9_79",
  "logCount": "_logCount_1ceo9_83",
  "logCount-warning": "_logCount-warning_1ceo9_98",
  "logCount-error": "_logCount-error_1ceo9_102",
  "logCount-ghost": "_logCount-ghost_1ceo9_105"
};