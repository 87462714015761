export interface GithubOAuthIdentity {
  provider: 'github'
  connection: string // github, github-enterprise?
  isSocial: boolean
  user_id: number
  access_token?: string
  profileData?: Auth0GithubProfile
}

interface CommonGoogleOAuthIdentity {
  connection: string // google-oauth2, google-apps?
  profileData?: Auth0GoogleProfile & { id: string }
  access_token?: string
  expires_in?: number
  user_id: string
  isSocial: boolean
}

export interface GoogleOAuthIdentity extends CommonGoogleOAuthIdentity {
  provider: 'google-oauth2'
}

export interface GoogleAppsIdentity extends CommonGoogleOAuthIdentity {
  provider: 'google-apps'
}

export interface Auth0PasswordIdentity {
  profileData: {
    email: string
    email_verified: boolean
  }
  user_id: '57c76896ffaaa08113073ff6'
  provider: 'auth0'
  connection: string
  isSocial: boolean
}

export interface WaadIdentity {
  user_id: string
  provider: 'waad'
  connection: string // bluestem-waad
  isSocial: boolean
  profileData?: Auth0WaadProfile
}

export interface SamlIdentity {
  provider: 'samlp'
  user_id: string
  connection: string // disney
  isSocial: boolean // false
  profileData?: Auth0SamlProfile
}

export interface Auth0SamlProfile {
  sessionIndex: string
  firstname: string
  preferred_username: string
  email: string
  lastname: string
  nameIdAttributes: {
    value: string
    Format: string
  }
  authenticationmethod: string
  issuer: string
  updated_at: string
  user_id: string
  name: string
  picture: string
  nickname: string
  identities: Auth0Identity[]
  created_at: string
  last_ip: string
  last_login: string
  logins_count: number
  blocked_for: any[]
  guardian_authenticators: any[]
}

export interface Auth0WaadProfile {
  upn: string
  azure_id: string
  given_name: string
  family_name: string
  nickname: string
  tenantid: string
  email: string
  oid: string
  email_verified: boolean
  name: string
  updated_at: string
  user_id: string
  picture: string
  identities: Auth0Identity[]
  created_at: string
  last_ip: string
  last_login: string
  logins_count: 27
  blocked_for: Array<any>
  guardian_authenticators: Array<any>
}

export type Auth0Identity =
  | GithubOAuthIdentity
  | GoogleOAuthIdentity
  | GoogleAppsIdentity
  | WaadIdentity
  | SamlIdentity
  | Auth0PasswordIdentity

export interface Auth0BaseInfo {
  user_id: string // "github|154748" | "google-apps|tim@cypress.io",
  email: string
  logins_count: number
  last_ip: string
  last_login: string | Date
  created_at: string | Date
}

export interface Auth0GoogleProfile extends Auth0BaseInfo {
  id?: string
  email_verified: boolean
  name: string
  given_name: string
  family_name: string
  picture: string
  locale: string
  updated_at: string | Date
  nickname: string
  identities: Auth0Identity[]
}

export interface Auth0GithubProfile extends Auth0BaseInfo {
  name: string
  picture: string
  nickname: string
  node_id: string
  gravatar_id: string
  url: string
  html_url: string
  followers_url: string
  following_url: string
  gists_url: string
  starred_url: string
  subscriptions_url: string
  organizations_url: string
  repos_url: string
  events_url: string
  received_events_url: string
  type: string
  site_admin: boolean
  company?: string
  blog: string
  location: string
  public_repos: number
  public_gists: number
  followers: number
  following: number
  updated_at: string | Date
  emails?: {
    email: string
    primary: boolean
    verified: boolean
    visibility: string
  }[]
  email_verified: boolean
  identities: Auth0Identity[]
  user_metadata: {}
}

export type Auth0LoginProfile =
  | Auth0GithubProfile
  | Auth0GoogleProfile
  | Auth0SamlProfile
  | Auth0WaadProfile
