export function getTextSize(message: string) {
  const bytes = new Blob([message], { type: 'text/plain' }).size
  const singleDecimal = (n: number) => Number(n.toFixed(1))
  const megabytes = singleDecimal(bytes / (1024 * 1024))
  const kilobytes = singleDecimal(bytes / 1024)
  return {
    kilobytes,
    megabytes,
  }
}
