import React from 'react'
import styles from './ScrubberThumb.module.scss'
import cs from 'clsx'

type ScrubberTime = {
  min: number
  max: number
  value: number
}
export type ThumbVariant = 'ghost' | 'passing' | 'failing'
export type ThumbPosition = React.CSSProperties['left']
const THUMB_WIDTH = 2

const ScrubberTime: React.FC<{
  time: ScrubberTime
}> = ({ time: { value: float, min, max } }) => {
  const numWidth = 8
  const msWidth = 14
  const integer = Math.ceil(float - min)
  const side = integer > (max - min) / 2 ? 'left' : 'right'
  // create consistent width to prevent jiggering due
  //  to individual numbers having different widths:
  const width = String(integer).length * numWidth + msWidth
  return (
    <div
      data-cy="scrubber-time"
      className={styles['scrubber-time']}
      style={{
        width,
        textAlign: side === 'left' ? 'right' : 'left',
        left: side === 'left' ? -1 * (4 + width) : 12,
        top: -2,
      }}
    >
      {integer}ms
    </div>
  )
}

export const ScrubberThumb: React.FC<{
  dynamic?: boolean
  variant: ThumbVariant
  leftOffset?: ThumbPosition
  lightMode?: boolean
  'data-cy'?: string
  time?: ScrubberTime
}> = ({ leftOffset, variant, dynamic = true, lightMode, time, ...rest }) => {
  const mockThumbStyles: Record<string, boolean> = {}

  if (styles['mock-thumb-dynamic']) {
    mockThumbStyles[styles['mock-thumb-dynamic']] = dynamic
  }

  return (
    <div
      data-cy={rest['data-cy']}
      className={cs(
        styles['mock-thumb-container'],
        styles[variant],
        lightMode && styles['ghost-light-mode']
      )}
    >
      <div
        className={cs(styles['mock-thumb'], mockThumbStyles)}
        style={{
          width: THUMB_WIDTH,
          // minus half the width of the scrubber stick, such that it
          // correctly falls on the center of the timestamp position:
          left: dynamic
            ? `calc(${leftOffset} - ${THUMB_WIDTH / 2}px)`
            : undefined,
        }}
      >
        <div className={styles['thumb-hit-box']}>
          {time && <ScrubberTime time={time} />}
        </div>
        <div className={styles['thumb-hit-peak']} />
        <div className={styles['pads']} style={{ left: 2 }} />
        <div className={styles['pads']} style={{ left: -1 }} />
      </div>
    </div>
  )
}

export const ScrubberThumbRange: React.FC<{
  leftOffset: ThumbPosition
  width: number
}> = ({ leftOffset, width }) => {
  return (
    <div
      data-cy="scrubber-thumb-range"
      className={styles['ghost-range-container']}
      style={{ width, left: `calc(${leftOffset} - ${THUMB_WIDTH / 2}px)` }}
    >
      <div>
        <ScrubberThumb dynamic={false} variant="ghost" lightMode />
      </div>
      <div className="mr-[-2px]">
        <ScrubberThumb dynamic={false} variant="ghost" lightMode />
      </div>
    </div>
  )
}
