import { useState } from 'react'
import type { NetworkItemDetailsProps } from './NetworkItemDetails/NetworkItemDetails'
import { useResponseBody } from '~/src/utils/useResponseBody'
import { useRequestBody } from '~/src/utils/useRequestBody'

export const useNetworkItemDetails = () => {
  const [detailProps, setDetailProps] =
    useState<NetworkItemDetailsProps | null>(null)

  // loads network response async
  // for performance improvements:
  const responseBody = useResponseBody(detailProps?.attNum, detailProps?.reqId)
  const requestBody = useRequestBody(detailProps?.attNum, detailProps?.reqId)
  if (detailProps) {
    detailProps.responseBody = responseBody
    detailProps.requestBody = requestBody
  }

  return {
    detailProps,
    setDetailProps,
  }
}
