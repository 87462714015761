import React, { useCallback, useEffect, useRef } from 'react'
import { useReplayContext } from '~/src/components/Context/ReplayContext'
import {
  getErrorPanels,
  scrollToErrorPanels,
} from '~/src/components/Context/ReplayContext/useReporterEffects/useTestBodyEffect'
import { CypressAppCollapsible, CypressAppAttempt } from '~/submodules'
import { useShouldScrollToEdge } from '~/src/utils/useShouldScrollToEdge'
import styles from './Attempt.module.scss'

/**
 * IMPORTANT:
 *
 * Enforce all Reporter panels to mount in expanded state. The ghost effects
 * logic make this assumption when creating state-trackers on our side.
 */
CypressAppCollapsible.defaultProps.isOpen = true

export const Attempt: React.FC = () => {
  const {
    timer: { time },
    attempts: { selectedOption },
    helpers: { clearHoveredSnapshots, pin },
    testBodyScrollTargetRef,
    attemptsModel,
  } = useReplayContext()

  const attemptId = selectedOption.value
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const shouldScroll = useShouldScrollToEdge(time, 'command-logs', pin)

  const autoScroll = useCallback(
    (delay?: number) => {
      // yield to main thread to allow us to catch up with
      // Reporter changes before we attempt to auto-scroll:
      setTimeout(() => {
        if (testBodyScrollTargetRef.current) {
          testBodyScrollTargetRef.current.scrollIntoView({
            block: 'center',
          })
        }
      }, delay)
    },
    [testBodyScrollTargetRef]
  )

  useEffect(() => {
    if (attemptsModel && scrollContainerRef.current) {
      const { errorRegion, errorName } = getErrorPanels()
      if (errorRegion || errorName) {
        scrollToErrorPanels()
      } else {
        const fullScroll = scrollContainerRef.current.scrollHeight
        scrollContainerRef.current.scrollTop = fullScroll
      }

      // Attempts to scroll to separator at the time of mounting.
      // Include delay onMount to allow Reporter to finish rendering.
      autoScroll(225)
    }
    // only run once per attempt selection;
    // and once the attemptModel is defined:
    // eslint-disable-next-line
  }, [attemptId, attemptsModel])

  if (!attemptsModel) {
    return null
  }

  return (
    <div
      data-cy="CloudAttempt"
      data-pendo="replay-cloud-attempt-container"
      className={styles['attempt-container']}
      // manual "key" induces Reporter remounting when switching attempts,
      // so that all collapsible elements are forced to expand and
      // we can index them for the ghost styling effects:
      key={attemptId}
    >
      <div className="reporter">
        <div className="runnable" ref={scrollContainerRef}>
          <div className="attempts" onMouseLeave={clearHoveredSnapshots}>
            <CypressAppAttempt
              studioActive={false}
              model={attemptsModel}
              scrollIntoView={() => {
                if (shouldScroll) {
                  autoScroll()
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
