import React from 'react'
import useResizeObserver from 'use-resize-observer'
import { Header } from './Header/Header'
import { useReplayContext } from '../../Context/ReplayContext'
import styles from './Browser.module.scss'
import { Dom } from './Dom/Dom'
import { AutControls } from './AutControls/AutControls'

export const Browser: React.FC = () => {
  const {
    timer: { playing },
    display: { url, viewport, pageLoading, autContext },
    devtools: { open, toggle },
    helpers: { isDragging },
    iframeRef,
  } = useReplayContext()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const containerSize = useResizeObserver({
    ref: containerRef,
  })
  return (
    <div className={styles['replay-browser-container']}>
      <Header
        devtoolsOpen={open}
        onClickDevtools={toggle}
        viewport={`${viewport.width}x${viewport.height}`}
        url={url}
        isPageLoading={pageLoading}
      />
      <div ref={containerRef} className={styles['replay-dom-wrapper']}>
        <Dom
          domLocked={playing || isDragging}
          zoomToFit={containerSize}
          iframeRef={iframeRef}
          currentViewport={viewport}
        />
        <div className={styles['aut-control-wrapper']}>
          {autContext && <AutControls {...autContext} />}
        </div>
      </div>
    </div>
  )
}
