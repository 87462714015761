import React from 'react'
import { formatTestDuration } from '../../../utils/formatTestDuration'
import styles from './TimerDisplay.module.scss'

export const TimerDisplay: React.FC<{
  time: number
  min: number
  max: number
}> = ({ time, min, max }) => {
  const end = max - min
  const current = time - min
  const forceSeconds = max - min >= 1000

  // NOTE: times are always length 5:
  // if in milliseconds, we have 000ms
  // if in seconds, we have 00:00
  const endFormat = formatTestDuration(end).padStart(5, '0')
  const currFormat = formatTestDuration(current, {
    // preserves "seconds" formatting
    // if the total time is in seconds:
    forceSeconds,
  }).padStart(5, '0')

  return (
    <div
      data-cy="TimerDisplay"
      className={`${styles['tr-timer-display-container']} hide-in-percy`}
    >
      <p>
        {currFormat} / {endFormat}
      </p>
    </div>
  )
}
