import { safelyApplyAttribute } from './safelyApplyAttribute'
import { warnOnce } from './Replay/warnOnce'
import type { DBNodePayload } from './useDomReplay'

export const safelyCreateElement = (doc: Document, node: DBNodePayload) => {
  const nodeLocalName: string = node?.localName
  let createdNode:
    | ReturnType<typeof doc.createElement>
    | ReturnType<typeof doc.createElementNS>

  try {
    if (node?.isSVG) {
      createdNode = doc.createElementNS(
        'http://www.w3.org/2000/svg',
        nodeLocalName
      )
    } else {
      createdNode = doc.createElement(nodeLocalName)
    }
  } catch (err) {
    warnOnce(
      `invalid-element-${nodeLocalName}`,
      `Invalid element ${nodeLocalName} replaced`
    )
    createdNode = doc.createElement('invalid-element')
    safelyApplyAttribute(
      createdNode,
      'data-cy-replay-previous-name',
      nodeLocalName
    )
  }
  return createdNode
}
