import 'css-chunk:src/components/ReplayBody/Browser/AutControls/AutControls.module.scss';export default {
  "aut-controls-container": "_aut-controls-container_cn2zu_7",
  "leading": "_leading_cn2zu_19",
  "title": "_title_cn2zu_25",
  "button-list": "_button-list_cn2zu_31",
  "active": "_active_cn2zu_63",
  "highlights-controls": "_highlights-controls_cn2zu_67",
  "close-button": "_close-button_cn2zu_78",
  "custom-switch": "_custom-switch_cn2zu_95",
  "lever": "_lever_cn2zu_111"
};