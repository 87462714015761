import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Separator } from '../../../ReplayBody/Separator/Separator'
import { useSyncWithReporter } from './useSyncWithReporter'
import type { AttemptHookContext } from '../useAttemptModel'
import type { GroupedLogs } from './useGroupedCommands'
import { LogProps, runnablesStore } from '~/submodules'
import ReactDOM from 'react-dom'

const GHOST_BOUNDARY_CLASS = 'test-replay--ghost-boundary'

type SectionState = {
  node: Element
  cleanup: () => void
  count: number
  open: boolean
}

const makeSeparatorContainer = () => {
  const node = document.createElement('div')
  node.style.display = 'block' // fill horizontal space
  node.style.position = 'relative' // for <Separator/> positioning
  node.className = 'test-replay--container--command-separator'
  return node
}

export const getErrorPanels = (): {
  errorRegion?: Element
  errorName?: Element
} => {
  const errorRegion = document.getElementsByClassName('attempt-error-region')[0]
  const errorName = document.getElementsByClassName('runnable-err-name')[0]
  return { errorRegion, errorName }
}

export const scrollToErrorPanels = () => {
  const panels = Object.values(getErrorPanels())
  panels.forEach((panel) => {
    panel?.scrollIntoView({
      block: 'start',
    })
  })
}

const getSectionsWithSeparator = () => {
  return document.querySelectorAll('.runnable-commands-region .hook-item')
}

type GhostEffectHelperArgs = {
  firstGhostIdx: number
  sections: SectionState[]
}

/**
 * Calculates what panel should include the separator,
 * accounting for panels being expanded/collapsed:
 */
const getSectionWithSeparatorIdx = ({
  firstGhostIdx,
  sections,
}: GhostEffectHelperArgs) => {
  let total = 0
  for (let i = 0; i < sections.length; i++) {
    const { count } = sections[i] as SectionState
    total += count
    if (total >= firstGhostIdx) {
      return i
    }
  }
  // all sections are fully active,
  // and no separator will be applied:
  return null
}

/**
 * Calculates the first DOM node to be ghosted,
 * accounting for panels being expanded/collapsed:
 */
const getFirstGhostNodeIndex = ({
  firstGhostIdx,
  sections,
}: GhostEffectHelperArgs) => {
  let finalIndex = firstGhostIdx
  let total = 0
  for (const state of sections) {
    const { count, open } = state
    total += count
    if (total - 1 < firstGhostIdx) {
      if (!open) {
        finalIndex -= count
      }
    } else {
      break
    }
  }
  return finalIndex
}

/**
 * adds and removes events from the Reporter to set
 * active and ghost logs data in the correct status:
 */
const useTestBodyTimeEffect = (
  commandGroup: any[],
  ctx: AttemptHookContext
) => {
  // Updates commands based
  // on current timestamp:
  const prevInProgress = useRef<any[]>([])
  useEffect(() => {
    if (!commandGroup) {
      return
    }
    // reset:
    for (const [_, data] of prevInProgress.current) {
      for (const e of data.events) {
        const info = e.payload as unknown as LogProps
        runnablesStore.updateLog(info)
      }
    }
    // find:
    const inProgress = commandGroup.filter(([_, data]) => {
      const began = data.start <= ctx.timer.time
      const unfinished = data.end > ctx.timer.time
      return began && unfinished
    })
    // apply:
    for (const [, data] of inProgress) {
      for (const e of data.events) {
        if (e.timestamp <= ctx.timer.time) {
          const info = e.payload as unknown as LogProps
          runnablesStore.updateLog(info)
        }
      }
    }
    // remember for future reset:
    prevInProgress.current = inProgress
  }, [commandGroup, ctx.timer.time])
}

/**
 * calculates which Reporter command
 * should have ghost style injected
 * if all panels were expanded:
 */
const useGhostCommandIdx = (
  commandInstruments: GroupedLogs['command'] = [],
  time: number
) => {
  const firstGhostIdx = useMemo(() => {
    const idx = commandInstruments.findIndex(([, data]) => {
      // Do not use ">=" since we want things
      // that have not yet happened at all:
      return data.start > time
    })
    return idx === -1 ? commandInstruments.length : idx
  }, [commandInstruments, time])

  return {
    // @ts-ignore: at() needs support
    allDone: time > (commandInstruments?.at(-1)?.[1].end ?? -Infinity),
    firstGhostIdx,
  }
}

/**
 * tracks if collapsible panels are opened or closed
 * and signals the other hooks to react to changes
 */
const useTestBodyPanelOpenState = ({ attemptId }: { attemptId: number }) => {
  const [target, setTarget] = useState<{ idx: number } | null>(null)
  const [sections, setSections] = useState<SectionState[]>([])

  useEffect(() => {
    // yields to main thread to allow Reporter
    // to finish rendering before we parse it:
    setTimeout(() => {
      const sectionsWithSeparator = getSectionsWithSeparator()

      const list: SectionState[] = []

      for (let i = 0; i < sectionsWithSeparator.length; i++) {
        const sectionNode = sectionsWithSeparator[i] as Element

        const collapsibleNode =
          sectionNode.getElementsByClassName('collapsible')[0]

        // we need to declare function within the closure
        // eslint-disable-next-line no-inner-declarations
        function handleToggle(event: Event) {
          let targetElement = event.target as HTMLElement
          // upwards traversal through parent nodes
          // to determine if click was within header:
          while (targetElement !== null && targetElement !== collapsibleNode) {
            if (targetElement.classList.contains('hook-header')) {
              setTarget({ idx: i })
              return
            }
            if (targetElement.parentElement) {
              targetElement = targetElement.parentElement
            }
          }
        }

        collapsibleNode?.addEventListener('click', handleToggle)

        list.push({
          node: sectionNode,
          cleanup: () => sectionNode.removeEventListener('click', handleToggle),
          count: sectionNode.getElementsByClassName('command').length,
          open: true, // we assume all sections begin expanded
        })
      }

      setSections(list)
    })

    return () => {
      for (const state of sections) {
        state.cleanup()
      }
    }
    // only called once per test-attempt mounting:
    // eslint-disable-next-line
  }, [attemptId])

  // only called when a collapsible commands
  // section is toggled to open or close:
  useEffect(() => {
    if (!target) {
      return
    }

    const sectionsWithSeparator = getSectionsWithSeparator()

    for (let i = 0; i < sectionsWithSeparator.length; i++) {
      if (target.idx === i) {
        const sectionNode = sectionsWithSeparator[i] as Element
        const collapsibleNode = sectionNode.getElementsByClassName(
          'collapsible'
        )[0] as Element

        // hack: if className is missing, then the
        // node is queued to open on the next render:
        const open = !collapsibleNode.className.includes('is-open')

        // clean up target state:
        setTarget(null)

        // update toggle state:
        setSections((secs) => {
          // only induce state change if
          // collapsible state did change:
          const section = secs[i]
          if (section && section.open !== open) {
            section.open = !section.open
            return [...secs]
          }
          // return original list
          // if no changes detected:
          return secs
        })
      }
    }
  }, [target])

  return {
    sections,
  }
}

/**
 * applies ghost styles to the commands rows
 * inside the Reporter drawer, if applicable.
 */
const useAssertionsEffect = ({
  sections,
  firstGhostIdx,
  scrollTargetRef,
  allDone,
}: {
  sections: SectionState[]
  firstGhostIdx: number
  scrollTargetRef: React.MutableRefObject<Element | null>
  allDone: boolean
}) => {
  // hack to keep in sync with Reporter:
  const { sync, retry } = useSyncWithReporter()
  useEffect(() => sync(), [sync, sections])

  useEffect(() => {
    // still waiting for ui to fully
    // render, or nothing to append to:
    if (!sections.length) {
      return
    }

    const sectionWithSeparatorIdx = getSectionWithSeparatorIdx({
      firstGhostIdx,
      sections,
    })

    if (typeof sectionWithSeparatorIdx === 'number') {
      // all sections after the one that has the separator
      // must also receive the ghost styling respectively:
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        if (section && i > sectionWithSeparatorIdx) {
          section.node.classList.add(GHOST_BOUNDARY_CLASS)
        }
      }

      // if section is open, then we want to inject
      // the separator and use it as the scroll target.
      const sectionIsExpanded = sections[sectionWithSeparatorIdx]?.open

      if (sectionIsExpanded) {
        const firstGhostNodeIdx = getFirstGhostNodeIndex({
          firstGhostIdx,
          sections,
        })

        const lastActiveNode: Element | undefined =
          document.getElementsByClassName('command')[firstGhostNodeIdx - 1]

        const firstGhostNode: Element | undefined =
          document.getElementsByClassName('command')[firstGhostNodeIdx]

        // missing nodes can mean we are still waiting
        // for useSyncWithReporter to induce a rerender:
        const someElementsExist = firstGhostNode || lastActiveNode

        if (someElementsExist) {
          const allCommandsDisabled = firstGhostIdx === 0
          firstGhostNode?.classList.add(GHOST_BOUNDARY_CLASS)
          const separatorContainerNode = makeSeparatorContainer()

          // eslint-disable-next-line no-inner-declarations
          function injectSeparator(parent?: Element) {
            if (parent && !allDone) {
              ReactDOM.render(
                <Separator
                  data-cy="test-replay--commands--separator"
                  forwardRef={
                    scrollTargetRef as React.LegacyRef<HTMLDivElement>
                  }
                  style={{
                    width: '100%',
                    position: 'absolute',
                    top: -6,
                  }}
                />,
                separatorContainerNode
              )
            }
          }

          // displays separator at the bottom of the active node, unless all are
          // disabled, in which case we display at the top of the first ghost node:
          if (allCommandsDisabled) {
            firstGhostNode?.prepend(separatorContainerNode)
            injectSeparator(firstGhostNode)
          } else {
            lastActiveNode?.append(separatorContainerNode)
            injectSeparator(lastActiveNode)
          }

          return function cleanup() {
            ReactDOM.unmountComponentAtNode(separatorContainerNode)

            // cleanup based on same conditions
            // that injected the separator:
            if (allCommandsDisabled) {
              firstGhostNode?.removeChild(separatorContainerNode)
            } else {
              lastActiveNode?.removeChild(separatorContainerNode)
            }

            firstGhostNode?.classList.remove(GHOST_BOUNDARY_CLASS)
            scrollTargetRef.current = null

            for (const state of sections) {
              state.node.classList.remove(GHOST_BOUNDARY_CLASS)
            }
          }
        }
      } else {
        // if the section is closed, then we want
        // to scroll to the collapsed section's header:
        scrollTargetRef.current = document.querySelectorAll(
          '.runnable-commands-region .hook-item'
        )[sectionWithSeparatorIdx] as Element
      }
    } else {
      if (allDone) {
        // scroll down to error region
        // if all commands are done:
        scrollToErrorPanels()
      }
    }

    return function cleanup() {
      scrollTargetRef.current = null
    }
  }, [allDone, firstGhostIdx, scrollTargetRef, sections, retry])
}

export const useTestBodyEffect = ({
  ctx,
  time,
  attemptId,
  grouped,
}: {
  attemptId: number
  grouped: GroupedLogs
  ctx: AttemptHookContext
  time: number
}) => {
  useTestBodyTimeEffect(grouped.command, ctx)

  const scrollTargetRef = useRef<Element | null>(null)
  const { sections } = useTestBodyPanelOpenState({ attemptId })
  const { firstGhostIdx, allDone } = useGhostCommandIdx(grouped.command, time)

  useAssertionsEffect({
    sections,
    firstGhostIdx,
    scrollTargetRef,
    allDone,
  })

  return {
    scrollTargetRef,
  }
}
