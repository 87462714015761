import React from 'react'
import type { TimerSpeed } from '~/src/utils/useGlobalTimer'
import { IconCheckmarkSmall } from '@cypress-design/react-icon'
import { SingleSelect } from '@frontend/design-system/src/components/Select/Select'
import { CustomSVGIcon } from '@frontend/design-system/src/components/custom-icons/CustomIcon'
import {
  SpeedPercent_25X16,
  SpeedPercent_50X16,
  SpeedPercent_75X16,
  SpeedPercent_100X16,
} from '@frontend/design-system/src/lib/img/icons/index'
import styles from './SpeedPicker.module.scss'
import cs from 'clsx'

const speedMap = {
  [0.25]: SpeedPercent_25X16,
  [0.5]: SpeedPercent_50X16,
  [0.75]: SpeedPercent_75X16,
  [1]: SpeedPercent_100X16,
} as const

const SpeedIcon: React.FC<{
  speed: keyof typeof speedMap
  isSelected: boolean
}> = ({ speed, isSelected }) => {
  return (
    <span
      className={cs(
        isSelected ? styles['tr-option-selected'] : styles['tr-option']
      )}
    >
      <CustomSVGIcon SVGComponent={speedMap[speed]} width={16} />
    </span>
  )
}

const SPEED_OPTS = [0.25, 0.5, 0.75, 1] as const

function itemFactory({ value }: { value: (typeof SPEED_OPTS)[number] }) {
  const CustomSpeedItem: React.FC<{
    isSelected: boolean
    asOption: boolean
    asValue: boolean
  }> = ({ isSelected, asOption, asValue }) => {
    return (
      <div
        data-cy={`SpeedOpt-${asValue ? 'asValue' : 'asOption'}`}
        data-pendo="replay-footer-speed-picker"
        key={String(value)}
        className={cs(
          styles['tr-speed-option'],
          isSelected && styles['selected']
        )}
      >
        <div
          data-cy="speed-value-display"
          className={styles['speed-value-display']}
        >
          <SpeedIcon speed={value} isSelected={isSelected} />{' '}
          <span>{value}x</span>
        </div>
        <div className={styles['checkmark-container']}>
          {asOption && isSelected ? (
            <IconCheckmarkSmall color="indigo-100" />
          ) : null}
        </div>
      </div>
    )
  }
  return CustomSpeedItem
}

export const SpeedPicker = React.memo(
  (props: { setSpeed: (n: TimerSpeed) => void }) => {
    const options = SPEED_OPTS.map((v) => ({
      CustomListItem: itemFactory({ value: v }),
      value: v,
    }))

    return (
      <div className={styles['tr-speed-picker-wrapper']}>
        <SingleSelect
          displaySelected
          customListItems
          removeListShadow
          options={options}
          menuPlacement="top"
          defaultValue={options[3]}
          optionsTitle="Replay speed"
          onChange={(opt: any) => {
            const speed = opt.value as TimerSpeed
            props.setSpeed(speed)
          }}
          // We need to force the root theme into allowing
          // us to set the height fo the control picker:
          theme={(defaults) => ({
            ...defaults,
            spacing: {
              ...defaults.spacing,
              controlHeight: 28,
            },
          })}
          styles={{
            menu: (styles) => ({
              ...styles,
              border: 'none',
              boxShadow: 'none !important',
            }),
          }}
        />
      </div>
    )
  }
)
