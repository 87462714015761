import type {
  CSSAdoptedStyleSheets,
  EventsPayload,
} from '@packages/app-capture-protocol/src/db/schemas/latest'
import type { HrefResolver } from '../Replay/hrefResolver'
import type { NodeMap } from '../Replay/nodeMap'
import { ATTRIBUTE_NAME_MAP } from '../safelyApplyAttribute'
import { rewriteCss } from '../rewriteCss'
import type { NetworkResources } from '../useDomReplay'
import * as Sentry from '@sentry/browser'
import type { Base64AssetMetadata } from '../toBase64Asset'

type Modifiable = {
  insertRule: (rule: string, index: number) => void
  deleteRule: (index: number) => void
}

type StyleSheetAndElement = {
  sheet: CSSStyleSheet
  element?: HTMLStyleElement
}

export const swallowCssErrors = (fn: Function) => {
  // If the client code is trying to insert a rule that is invalid (e.g. firefox or ms syntax), we don't want to throw an error
  try {
    fn()
  } catch (error: any) {
    if (
      error.name === 'SyntaxError' &&
      error.message.includes('Failed to parse')
    ) {
      return
    }
    Sentry.captureException(error)
  }
}

export const formatStyleSheetText = ({
  ctx,
  id,
  index,
  styleSheetId,
  styleSheetText,
  styleSheetHref,
  base64AssetMetadata,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  id: number
  index?: number
  styleSheetId: string
  styleSheetText: string
  styleSheetHref: string
  base64AssetMetadata?: Base64AssetMetadata
}) => {
  return rewriteCss(
    ctx.iframeRef.current!.contentDocument!,
    ctx.networkResources,
    styleSheetText,
    ctx.hrefResolver.current,
    styleSheetHref || '',
    `${id}_${styleSheetId}${index ? `_${index}` : ''}`,
    false,
    base64AssetMetadata
  )
}

const formatCSSText = ({
  ctx,
  id,
  index,
  cssText,
  styleSheetElement,
  frameId,
  styleSheetId,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  id: number
  index?: number
  cssText: string
  styleSheetElement?: HTMLStyleElement
  frameId: string
  styleSheetId: string
}): string => {
  let possibleStylesheetHref

  const attributeNameMapHref = ATTRIBUTE_NAME_MAP['href']
  if (attributeNameMapHref) {
    possibleStylesheetHref = styleSheetElement?.getAttribute(
      attributeNameMapHref()
    )
  }

  return formatStyleSheetText({
    ctx,
    id,
    index,
    styleSheetId,
    styleSheetText: cssText,
    styleSheetHref:
      possibleStylesheetHref ||
      ctx.hrefResolver.current.getFrameLocation(frameId) ||
      '',
  })
}

const getModifiable = (
  sheetOrGroupingRule: CSSStyleSheet | CSSGroupingRule,
  index: number[]
): Modifiable | undefined => {
  if (index.length > 1) {
    return getModifiable(
      sheetOrGroupingRule.cssRules[index[0] as number] as CSSGroupingRule,
      index.slice(1)
    )
  }
  return sheetOrGroupingRule
}

const getStyleRule = (
  sheetOrGroupingRule: CSSStyleSheet | CSSGroupingRule,
  index: number[]
): CSSStyleRule | undefined => {
  if (index.length > 1) {
    return getStyleRule(
      sheetOrGroupingRule.cssRules[index[0] as number] as CSSGroupingRule,
      index.slice(1)
    )
  }

  // TODO: Understand why this sheetOrGroupingRule is undefined sometimes
  return sheetOrGroupingRule?.cssRules[index[0] as number] as CSSStyleRule
}

const getStyleSheetAndElementFromPayload = ({
  ctx,
  payload,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  payload: {
    elementId?: number
    styleSheetId?: string
  }
}): StyleSheetAndElement | undefined => {
  let sheet: CSSStyleSheet | undefined
  let styleElement: HTMLStyleElement | undefined
  if (payload.elementId) {
    styleElement = ctx.nodeMap.current.getNode(
      payload.elementId
    ) as HTMLStyleElement
    if (styleElement && styleElement.sheet) {
      sheet = styleElement.sheet
    }
  } else if (payload.styleSheetId) {
    sheet = ctx.nodeMap.current.getAdoptedStylesheetForModification(
      payload.styleSheetId
    )?.sheet
  }

  if (sheet) {
    return {
      sheet,
      element: styleElement,
    }
  }
  return
}

export const applyCSSRuleInserted = ({
  ctx,
  eventId,
  cssRuleInsertedEventPayload,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  eventId: number
  cssRuleInsertedEventPayload: EventsPayload['cdp']['dom:css-rule-inserted']
}) => {
  const sheetAndElement = getStyleSheetAndElementFromPayload({
    ctx,
    payload: cssRuleInsertedEventPayload,
  })

  const doInsert = (sheetAndElement: StyleSheetAndElement) => {
    const index = cssRuleInsertedEventPayload.insertion.index || [0]
    const modifiable = getModifiable(sheetAndElement.sheet, index)
    const modifiableIndex = index[index.length - 1] as number
    const rule = formatCSSText({
      ctx,
      id: eventId,
      styleSheetElement: sheetAndElement.element,
      cssText: cssRuleInsertedEventPayload.insertion.rule,
      frameId: cssRuleInsertedEventPayload.frameId,
      styleSheetId: cssRuleInsertedEventPayload.styleSheetId,
    })
    swallowCssErrors(() => {
      modifiable?.insertRule(rule, modifiableIndex)
    })
    if (sheetAndElement.element) {
      ctx.nodeMap.current.queueElementStyleSheetUpdate(sheetAndElement.element)
    } else {
      ctx.nodeMap.current.queueAdoptedStylesheetUpdate(
        cssRuleInsertedEventPayload.frameId
      )
    }
  }

  // If we have the sheet and element, we can insert the rule
  // if we don't have the sheet and element, we need to wait until the element is added
  if (sheetAndElement) {
    doInsert(sheetAndElement)
  } else if (cssRuleInsertedEventPayload.elementId) {
    ctx.nodeMap.current.elementIdToDelayedCallbacksArrayMap.set(
      cssRuleInsertedEventPayload.elementId,
      () => {
        const sheetAndElement = getStyleSheetAndElementFromPayload({
          ctx,
          payload: cssRuleInsertedEventPayload,
        })
        if (sheetAndElement) {
          doInsert(sheetAndElement)
        }
      }
    )
  }
}

export const applyCSSRuleDeleted = ({
  ctx,
  cssRuleDeletedEventPayload,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  cssRuleDeletedEventPayload: EventsPayload['cdp']['dom:css-rule-deleted']
}) => {
  const sheetAndElement = getStyleSheetAndElementFromPayload({
    ctx,
    payload: cssRuleDeletedEventPayload,
  })

  const doDeletion = (sheetAndElement: StyleSheetAndElement) => {
    const index = cssRuleDeletedEventPayload.deletion.index || [0]
    const modifiable = getModifiable(sheetAndElement.sheet, index)
    const modifiableIndex = index[index.length - 1] as number
    swallowCssErrors(() => {
      modifiable?.deleteRule(modifiableIndex)
    })

    if (sheetAndElement.element) {
      ctx.nodeMap.current.queueElementStyleSheetUpdate(sheetAndElement.element)
    } else {
      ctx.nodeMap.current.queueAdoptedStylesheetUpdate(
        cssRuleDeletedEventPayload.frameId
      )
    }
  }

  // If we have the sheet and element, we can delete the rule
  // if we don't have the sheet and element, we need to wait until the element is added
  if (sheetAndElement && sheetAndElement.sheet.cssRules.length > 0) {
    doDeletion(sheetAndElement)
  } else if (cssRuleDeletedEventPayload.elementId) {
    ctx.nodeMap.current.elementIdToDelayedCallbacksArrayMap.set(
      cssRuleDeletedEventPayload.elementId,
      () => {
        const sheetAndElement = getStyleSheetAndElementFromPayload({
          ctx,
          payload: cssRuleDeletedEventPayload,
        })
        if (sheetAndElement && sheetAndElement.sheet.cssRules.length > 0) {
          doDeletion(sheetAndElement)
        }
      }
    )
  }
}

type ApplyCSSReplacedParamsSync = {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  eventId: number
  cssReplacedEventPayload: EventsPayload['cdp']['dom:css-replaced-sync']
  sync: true
}

type ApplyCSSReplacedParamsAsync = {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  eventId: number
  cssReplacedEventPayload: EventsPayload['cdp']['dom:css-replaced']
  sync: false
}

export const applyCSSReplaced = ({
  ctx,
  eventId,
  cssReplacedEventPayload,
  sync,
}: ApplyCSSReplacedParamsAsync | ApplyCSSReplacedParamsSync) => {
  const sheetAndElement = getStyleSheetAndElementFromPayload({
    ctx,
    payload: cssReplacedEventPayload,
  })

  if (sheetAndElement) {
    const rule = formatCSSText({
      ctx,
      id: eventId,
      styleSheetElement: sheetAndElement.element,
      cssText: sync
        ? cssReplacedEventPayload.replacementSync
        : cssReplacedEventPayload.replacement,
      frameId: cssReplacedEventPayload.frameId,
      styleSheetId: cssReplacedEventPayload.styleSheetId,
    })

    swallowCssErrors(() => {
      if (sync) {
        sheetAndElement.sheet.replaceSync(rule)
      } else {
        sheetAndElement.sheet.replace(rule)
      }
    })

    ctx.nodeMap.current.queueAdoptedStylesheetUpdate(
      cssReplacedEventPayload.frameId
    )
  }
}

export const applyCSSAdoptedStyleSheets = ({
  ctx,
  eventId,
  cssAdoptedStyleSheetsEventPayload,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  eventId: number
  cssAdoptedStyleSheetsEventPayload: EventsPayload['cdp']['dom:css-adopted-style-sheets']
}) => {
  const formattedCSSAdoptedStyleSheets: CSSAdoptedStyleSheets = {
    frameId: cssAdoptedStyleSheetsEventPayload.frameId,
    styleSheetIds: cssAdoptedStyleSheetsEventPayload.styleSheetIds,
    addedStyles: cssAdoptedStyleSheetsEventPayload.addedStyles.map(
      (addedStyle) => {
        return {
          styleSheetId: addedStyle.styleSheetId,
          additions: addedStyle.additions.map((addition, index) => {
            const updatedText = formatCSSText({
              ctx,
              id: eventId,
              index,
              cssText: addition.rule,
              frameId: cssAdoptedStyleSheetsEventPayload.frameId,
              styleSheetId: addedStyle.styleSheetId,
            })
            const updatedAddition = {
              index: addition.index,
              rule: updatedText,
            }
            return updatedAddition
          }),
        }
      }
    ),
    sequence: cssAdoptedStyleSheetsEventPayload.sequence,
    shadowHostElementId: cssAdoptedStyleSheetsEventPayload.shadowHostElementId,
  }
  ctx.nodeMap.current.resolveAdoptedStylesheets(formattedCSSAdoptedStyleSheets)
}

export const applyCSSStyleDeclarationPropertySet = ({
  ctx,
  eventId,
  cssStyleDeclarationPropertySetEventPayload,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  eventId: number
  cssStyleDeclarationPropertySetEventPayload: EventsPayload['cdp']['dom:css-style-declaration-property-set']
}) => {
  const sheetAndElement = getStyleSheetAndElementFromPayload({
    ctx,
    payload: cssStyleDeclarationPropertySetEventPayload,
  })

  const doDeclarationPropertySet = (sheetAndElement: StyleSheetAndElement) => {
    const index = cssStyleDeclarationPropertySetEventPayload.setProperty.index
    const styleRule = getStyleRule(sheetAndElement.sheet, index)
    const value = formatCSSText({
      ctx,
      id: eventId,
      cssText: cssStyleDeclarationPropertySetEventPayload.setProperty.value,
      styleSheetElement: sheetAndElement.element,
      frameId: cssStyleDeclarationPropertySetEventPayload.frameId,
      styleSheetId: cssStyleDeclarationPropertySetEventPayload.styleSheetId,
    })
    swallowCssErrors(() => {
      styleRule?.style.setProperty(
        cssStyleDeclarationPropertySetEventPayload.setProperty.property,
        value,
        cssStyleDeclarationPropertySetEventPayload.setProperty.priority
      )
    })

    if (sheetAndElement.element) {
      ctx.nodeMap.current.queueElementStyleSheetUpdate(sheetAndElement.element)
    } else {
      ctx.nodeMap.current.queueAdoptedStylesheetUpdate(
        cssStyleDeclarationPropertySetEventPayload.frameId
      )
    }
  }

  // If we have the sheet and element, we can set the property
  // if we don't have the sheet and element, we need to wait until the element is added
  if (sheetAndElement) {
    doDeclarationPropertySet(sheetAndElement)
  } else if (cssStyleDeclarationPropertySetEventPayload.elementId) {
    ctx.nodeMap.current.elementIdToDelayedCallbacksArrayMap.set(
      cssStyleDeclarationPropertySetEventPayload.elementId,
      () => {
        const sheetAndElement = getStyleSheetAndElementFromPayload({
          ctx,
          payload: cssStyleDeclarationPropertySetEventPayload,
        })
        if (sheetAndElement) {
          doDeclarationPropertySet(sheetAndElement)
        }
      }
    )
  }
}

export const applyCSSStyleDeclarationPropertyRemoved = ({
  ctx,
  cssStyleDeclarationPropertyRemovedEventPayload,
}: {
  ctx: {
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    nodeMap: React.MutableRefObject<NodeMap>
    hrefResolver: React.MutableRefObject<HrefResolver>
    networkResources: NetworkResources
  }
  cssStyleDeclarationPropertyRemovedEventPayload: EventsPayload['cdp']['dom:css-style-declaration-property-removed']
}) => {
  const sheetAndElement = getStyleSheetAndElementFromPayload({
    ctx,
    payload: cssStyleDeclarationPropertyRemovedEventPayload,
  })

  const doDeclarationPropertyRemoved = (
    sheetAndElement: StyleSheetAndElement
  ) => {
    const index =
      cssStyleDeclarationPropertyRemovedEventPayload.removedProperty.index
    const styleRule = getStyleRule(sheetAndElement.sheet, index)
    swallowCssErrors(() => {
      styleRule?.style.removeProperty(
        cssStyleDeclarationPropertyRemovedEventPayload.removedProperty.property
      )
    })

    if (sheetAndElement.element) {
      ctx.nodeMap.current.queueElementStyleSheetUpdate(sheetAndElement.element)
    } else {
      ctx.nodeMap.current.queueAdoptedStylesheetUpdate(
        cssStyleDeclarationPropertyRemovedEventPayload.frameId
      )
    }
  }

  // If we have the sheet and element, we can remove the property
  // if we don't have the sheet and element, we need to wait until the element is added
  if (sheetAndElement) {
    doDeclarationPropertyRemoved(sheetAndElement)
  } else if (cssStyleDeclarationPropertyRemovedEventPayload.elementId) {
    ctx.nodeMap.current.elementIdToDelayedCallbacksArrayMap.set(
      cssStyleDeclarationPropertyRemovedEventPayload.elementId,
      () => {
        const sheetAndElement = getStyleSheetAndElementFromPayload({
          ctx,
          payload: cssStyleDeclarationPropertyRemovedEventPayload,
        })
        if (sheetAndElement) {
          doDeclarationPropertyRemoved(sheetAndElement)
        }
      }
    )
  }
}
