import { useEffect } from 'react'
import { runnablesStore } from '~/submodules'
import type { GroupedLogs } from './useGroupedCommands'
import type { AttemptHookContext } from '../useAttemptModel'

/**
 * mutates data fed into the Reporter so it has
 * the effect of adding "route" matches over time:
 */
export const useRoutesEffect = (
  routeGroup: GroupedLogs['route'],
  ctx: AttemptHookContext
) => {
  useEffect(() => {
    if (!routeGroup) {
      return
    }
    for (const [, data] of routeGroup) {
      if (data.events.length < 1) {
        continue
      }
      // @ts-ignore: fix lastEvent type:
      const lastEvent = data.events.at(-1)
      const totalCalls = lastEvent?.payload?.numResponses ?? '?'
      if (totalCalls === 0) {
        // allow the Reporter to naturally handle,
        // do not highjack if nothing happened.
        continue
      }
      const isActiveRoute =
        data.events[0] && data.events[0].timestamp <= ctx.timer.time
      if (isActiveRoute) {
        for (const e of data.events) {
          if (e.timestamp <= ctx.timer.time) {
            const info = e.payload
            runnablesStore.updateLog({
              ...info,
              // @ts-ignore: we want to pass a
              // formatted string for TestReplay:
              numResponses: `${info.numResponses}/${totalCalls}`,
            })
          }
        }
      } else {
        // push update that represents
        // the empty 0/totalCalls state:
        runnablesStore.updateLog({
          // @ts-ignore: fix lastEvent type:
          ...lastEvent.payload,
          // @ts-ignore: we want to pass a
          // formatted string for TestReplay:
          numResponses: `0/${totalCalls}`,
        })
      }
    }
  }, [routeGroup, ctx.timer.time])
}
