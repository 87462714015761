// Queue for pending additions to the DOM. This is used to ensure that all child nodes have been added prior to adding the node
// to the live DOM and that the DOM is in a ready state before adding new elements.

export class PendingAdditionQueue {
  private queue: (() => boolean)[]

  constructor() {
    this.queue = []
  }

  add(addition: () => boolean) {
    this.queue.push(addition)
  }

  flush() {
    let numberOfUnsuccessfulCallbacks = 0
    while (this.queue.length) {
      const currentLength = this.queue.length
      const currentCallback = this.queue.shift()!

      // If the callback is not successful, we need to push it back onto the queue.
      const isSuccessful = currentCallback()
      if (!isSuccessful) {
        this.queue.push(currentCallback)
        numberOfUnsuccessfulCallbacks += 1
      } else {
        numberOfUnsuccessfulCallbacks = 0
      }

      if (numberOfUnsuccessfulCallbacks === currentLength) {
        // If all callbacks are unsuccessful, we need to break the loop to prevent an infinite loop.
        break
      }
    }
  }
}
