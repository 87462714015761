import React from 'react'
import { IconArrowSquareDown } from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'
import styles from './LargeDownload.module.scss'
import cs from 'clsx'

export const LargeDownload: React.FC<{
  onClick: (event: any) => void
  size: string
  message: string
  className?: string
  'data-cy'?: string
}> = ({ size, message, className, onClick, ...rest }) => {
  return (
    <Button
      variant="outline-dark"
      className={cs(styles['container'], className)}
      data-cy={rest['data-cy'] ?? 'tr-large-download'}
      onClick={onClick}
      title={message}
    >
      <p className={styles['message']}>{message}</p>
      <div className={styles['details']}>
        <p className={styles['size']}>{size}</p>
        <IconArrowSquareDown size="16" className={styles['icon']} />
      </div>
    </Button>
  )
}
