export type Maybe<T> = T | null | undefined

export type FilteredKeys<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never
}[keyof T]

export type ServerEnvs = 'test' | 'staging' | 'development' | 'production'

export type ThunkMap<T> = { [K in keyof T]: T[K] | (() => T[K]) }

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer Y>
    ? ReadonlyArray<DeepPartial<Y>>
    : T[P] extends Date
    ? T[P]
    : DeepPartial<T[P]>
}

/**
 * Like DeepPartial, except it enforces the key's existence but allows
 * for an undefined value.
 */
export type DeepOptional<T> = {
  [P in keyof T]: T[P] extends Array<infer U>
    ? Array<DeepOptional<U> | undefined>
    : T[P] extends ReadonlyArray<infer V>
    ? ReadonlyArray<DeepOptional<V> | undefined>
    : DeepOptional<T[P]> | undefined
}

/**
 * Make only the members of the type optional, but not the type itsself
 */
export type DeepOptionalMembers<T> = { [K in keyof T]: DeepOptional<T[K]> }

/**
 * Like DeepPartial, except it enforces the key's existence but allows
 * for an undefined value.
 */
export type DeepReadonly<T> = Readonly<{
  [P in keyof T]: T[P] extends Array<infer U>
    ? ReadonlyArray<DeepReadonly<U>>
    : DeepReadonly<T[P]>
}>

export type OmitTs<T, K extends keyof T> = Pick<
  T,
  Exclude<keyof T, K | 'createdAt' | 'updatedAt'>
>

export type AtLeastOne<
  T,
  // https://github.com/microsoft/TypeScript/issues/31931
  U = Omit<{ [K in keyof T]: Pick<T, K> }, 'name'>
> = Partial<T> & U[keyof U]

export type ArgsFor<T> = T extends (...args: infer U) => any ? U : never

export type Values<T> = T extends Record<any, infer U> ? U : never

export type ResolvedType<T extends (...args: any[]) => any> =
  ReturnType<T> extends Promise<infer U> ? U : never
