import React, { useMemo } from 'react'
import cx from 'classnames'
import Tabs from '@cypress-design/react-tabs'
import styles from './NetworkPanel.module.scss'
import { SearchInput } from '../SearchInput/SearchInput'
import { TabsShim } from '../../../../utils/TabsShim/TabsShim'
import {
  ReplayContextValue,
  useReplayContext,
} from '~/src/components/Context/ReplayContext'
import { NetworkItem } from './NetworkItem/NetworkItem'
import {
  NetworkItemDetails,
  NetworkItemDetailsProps,
} from './NetworkItemDetails/NetworkItemDetails'
import { useSnapshotChangeContext } from '~/src/components/Context/SnapshotChangeContext'
import { usePreviousValueOf } from '@frontend/dashboard/src/lib/hooks/usePreviousValueOf'
import { useClearParamIfCommandHidden } from '~/src/utils/useClearParamIfCommandHidden'
import { NoResultsFilter } from '../../../../utils/NoResultsFilter/NoResultsFilter'
import { useShouldScrollToEdge } from '~/src/utils/useShouldScrollToEdge'
import { NetworkPanelEmptyState } from './NetworkPanelEmptyState'
import { useNetworkItemDetails } from './useNetworkItemDetails'
import { makeNetworkDetails } from './makeNetworkDetails'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
import { finder, rollupNetworkEvents } from '~/src/utils/rollupNetworkEvents'
import { useTargetIsMounted } from '~/src/utils/useTargetIsMounted'

export type NetworkTabId = 'all' | 'errors' | 'fetch'
export type OutOfBoundsKind = 'start' | 'end' | 'startAndEnd'

function renderNetworkItem({
  key,
  index,
  style,
  tuple,
  timer,
  pin,
  clearPinned,
  setPinned,
  attIdx,
  setDetailProps,
  setHoveredSnapshots,
  clearHoveredSnapshots,
  topSeparator,
}: {
  tuple: ReplayContextValue['events']['network'][number]
  style?: React.CSSProperties
  index: number
  key?: any
  attIdx: number
  timer: ReplayContextValue['timer']
  pin: ReplayContextValue['helpers']['pin']
  clearPinned: ReplayContextValue['helpers']['clearPinned']
  setPinned: ReplayContextValue['helpers']['setPinned']
  setDetailProps: (p: NetworkItemDetailsProps) => void
  setHoveredSnapshots: ReplayContextValue['helpers']['setHoveredSnapshots']
  clearHoveredSnapshots: ReplayContextValue['helpers']['clearHoveredSnapshots']
  topSeparator: boolean
}) {
  const reqId = tuple[0]
  const stream = tuple[1]

  const {
    source,
    outOfBoundsKind,
    startTime,
    endTime,
    status,
    method,
    path,
    shortPath,
    reqType,
    isServiceWorkerRequest,
  } = rollupNetworkEvents(stream, timer)

  // Network events are NOT guaranteed to always be in the same position within the stream, so we use
  // types to pick out each event correctly. Also, no matter its timestamp value, we default to consider the
  // network call as completed based on the finishedEvt: even when others events look like they came afterwards.
  const requestEvt = source.requestEvt
  const requestExtraEvt = source.requestExtraEvt
  const responseEvt = source.responseEvt
  const responseExtraEvt = source.responseExtraEvt
  const finishedEvt = source.finishedEvt

  const isGhost = startTime > timer.time
  // We might never get a 'finished' event for some network calls within
  // the test's lifetime, in which case it should always show as loading:
  const isLoading =
    !isGhost && timer.time < (finishedEvt?.timestamp ?? Infinity)

  const isPinned = pin?.section === 'network-calls' && pin?.id === reqId
  const timeline = {
    min: timer.min,
    max: timer.max,
    eventStart: startTime,
    eventEnd: endTime,
  }

  const eventPinInfo = {
    id: reqId,
    section: 'network-calls',
  } as const

  return (
    <NetworkItem
      key={key}
      index={index}
      style={style}
      isGhost={isGhost}
      data-cy-event-id={eventPinInfo.id}
      data-cy-event-start={startTime}
      data-cy-event-end={endTime}
      topSeparator={topSeparator}
      reqType={reqType}
      outOfBoundsKind={outOfBoundsKind}
      loading={isLoading}
      pinned={isPinned}
      timeline={timeline}
      isServiceWorkerRequest={isServiceWorkerRequest}
      networkResponse={{
        status,
        method,
        path,
        shortPath,
      }}
      onClickContainer={() => {
        if (isPinned) {
          clearPinned()
        } else {
          setPinned(eventPinInfo)
        }
      }}
      onClickCaret={() => {
        const detailProps = makeNetworkDetails({
          requestEvt,
          responseEvt,
          timeline,
          outOfBoundsKind,
          requestExtraEvt,
          responseExtraEvt,
          attNum: attIdx + 1,
          reqId,
          isServiceWorkerRequest,
        })
        setDetailProps(detailProps)
      }}
      snapshotsUpdate={() => {
        setHoveredSnapshots(eventPinInfo, [
          {
            name: 'Request',
            timestamp: startTime,
            elementsToHighlight: [],
          },
          {
            name: 'Response',
            timestamp: endTime,
            elementsToHighlight: [],
          },
        ])
      }}
      snapshotsReset={clearHoveredSnapshots}
    />
  )
}

const NetworkItems: React.FC<{
  setDetailProps: (props: NetworkItemDetailsProps) => void
}> = ({ setDetailProps }) => {
  const {
    timer,
    events: { network },
    helpers: {
      pin,
      setPinned,
      clearPinned,
      setHoveredSnapshots,
      clearHoveredSnapshots,
    },
    attempts: {
      selectedOption: { value: attIdx },
    },
  } = useReplayContext()

  const [filterText, setFilterText] = React.useState('')
  const [tabId, setTabId] = React.useState<NetworkTabId>('all')
  const { clearParamIfCommandHidden } = useClearParamIfCommandHidden()
  const { listContainerRef } = useSnapshotChangeContext()

  // helps wait to check if we should auto-scroll into
  // the Separator at the time that the List is mounted:
  const { nodeRef: listRef, mounted: isListMounted } =
    useTargetIsMounted<List>()

  const shouldScrollToEdge = useShouldScrollToEdge(
    timer.time,
    'network-calls',
    pin
  )
  const prevTabId = usePreviousValueOf(tabId)
  const prevFilterText = usePreviousValueOf(filterText)
  const filterTextChange = prevFilterText !== filterText
  const tabIdChanged = tabId !== prevTabId
  const shouldScrollDown =
    !isListMounted || shouldScrollToEdge || filterTextChange || tabIdChanged

  const filterResults = useMemo(() => {
    let scrollToIdx = -1
    const itemsAfterFilters = network.filter((tuple) => {
      const commandId = tuple[0]
      const stream = tuple[1]
      const requestEvt = stream.find(finder.getRequest)
      const responseEvt = stream.find(finder.getResponse)
      const startTime = requestEvt?.timestamp ?? timer.min
      const status = responseEvt?.payload?.response?.status ?? null
      const reqType = requestEvt?.payload.type

      let skip = false
      if (tabId === 'errors') {
        if (status < 400) {
          skip = true
        }
      }
      if (tabId === 'fetch') {
        if (reqType !== 'XHR' && reqType !== 'Fetch') {
          skip = true
        }
      }
      if (filterText) {
        const lowerCasePath = requestEvt?.payload.request.lowerCaseUrl ?? ''
        if (!lowerCasePath.includes(filterText)) {
          skip = true
        }
      }
      if (skip) {
        clearParamIfCommandHidden(commandId)
        return false
      }

      // this must happen AFTER all
      // conditions that return false:
      if (startTime <= timer.time) {
        // Whatever item last met this condition
        // is the latest item we need to scroll to:
        scrollToIdx += 1
      }
      return true
    })
    return {
      itemsAfterFilters,
      scrollToIdx,
    }
  }, [
    filterText,
    network,
    tabId,
    timer.min,
    timer.time,
    clearParamIfCommandHidden,
  ])
  const { itemsAfterFilters, scrollToIdx } = filterResults

  const hasResults = itemsAfterFilters.length
  const hasFilters = filterText || tabId !== 'all'
  const defaultEmpty = !hasResults && !hasFilters

  if (defaultEmpty) {
    return (
      <div
        data-cy="empty-network-panel"
        className={styles['emptyContainer']}
        role="tabpanel"
        id="network-tabpanel"
      >
        <NetworkPanelEmptyState />
      </div>
    )
  }

  return (
    <>
      <div className={styles['network-filters']}>
        <TabsShim>
          <Tabs
            data-cy="network-filter-tabs"
            variant="dark-small"
            onSwitch={({ id }) => setTabId(id as NetworkTabId)}
            activeId={tabId}
            tabs={[
              {
                id: 'all' as NetworkTabId,
                label: 'All',
                'data-pendo': 'replay-network-all-tab',
                'aria-controls': 'all-tabpanel',
              },
              {
                id: 'errors' as NetworkTabId,
                label: 'Errors',
                'data-pendo': 'replay-network-errors-tab',
                'aria-controls': 'errors-tabpanel',
              },
              {
                id: 'fetch' as NetworkTabId,
                label: 'Fetch/XHR',
                'data-pendo': 'replay-network-fetch-tab',
                'aria-controls': 'fetch-tabpanel',
              },
            ]}
          />
        </TabsShim>
        <div className={styles['search-input-filter']}>
          <SearchInput
            value={filterText}
            onChange={(e) =>
              setFilterText((e.target.value as string).toLowerCase())
            }
            onReset={() => setFilterText('')}
            data-cy="network-text-filter"
            placeholder="Filter"
            data-pendo="replay-network-text-filter"
          />
        </div>
      </div>

      {hasResults ? (
        <div
          role="tabpanel"
          id={`${tabId}-tabpanel`}
          className={styles['network-item-list']}
          data-cy="network-item-list"
          ref={listContainerRef}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listRef}
                height={height}
                width={width}
                rowHeight={30}
                overscanRowCount={5}
                scrollToIndex={
                  scrollToIdx < network.length - 1
                    ? scrollToIdx + 1
                    : scrollToIdx
                }
                rowCount={itemsAfterFilters.length}
                rowRenderer={({ key, index, style }) => {
                  const tuple = itemsAfterFilters[
                    index
                  ] as ReplayContextValue['events']['network'][number]

                  const topSeparator =
                    // if -1, then no item receives separator yet:
                    scrollToIdx === -1 ? false : index - 1 === scrollToIdx

                  return renderNetworkItem({
                    attIdx,
                    index,
                    key,
                    pin,
                    style,
                    timer,
                    tuple,
                    clearHoveredSnapshots,
                    clearPinned,
                    setDetailProps,
                    setHoveredSnapshots,
                    setPinned,
                    topSeparator,
                  })
                }}
                scrollToAlignment={shouldScrollDown ? 'center' : undefined}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoResultsFilter
          id={`${tabId}-tabpanel`}
          clearFilterText={() => {
            setTabId('all')
            setFilterText('')
          }}
        />
      )}
    </>
  )
}

export const NetworkPanel = () => {
  const {
    helpers: { clearHoveredSnapshots },
  } = useReplayContext()

  // Keeps <NetworkItemDetails/> in view until user
  // manually closes it. At that point we render whatever
  // is the correct list of <NetworkItems/> for current timestamp.
  const { detailProps, setDetailProps } = useNetworkItemDetails()

  const onClose = React.useCallback(() => {
    clearHoveredSnapshots()
    setDetailProps(null)
  }, [clearHoveredSnapshots, setDetailProps])

  return (
    <div
      data-cy="network-panel"
      className={styles['container']}
      id="network-tabpanel"
      role="tabpanel"
    >
      <div
        className={cx(
          styles['listContainer'],
          !!detailProps && styles['hidden']
        )}
      >
        <NetworkItems setDetailProps={setDetailProps} />
      </div>
      {detailProps && (
        <div className={styles['detailsContainer']}>
          <NetworkItemDetails {...detailProps} onClose={onClose} />
        </div>
      )}
    </div>
  )
}
