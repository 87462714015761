import React, { useRef } from 'react'
import styles from './SearchInput.module.scss'
import {
  IconObjectMagnifyingGlass,
  IconActionDelete,
} from '@cypress-design/react-icon'

/**
 * NOTE:
 *
 *
 * This component is meant to be replaced by the
 * design-system implementation, once it exists.
 */

type SearchInputProps = {
  value: string
  onChange: (event: any) => void
  onReset: (event: any) => void
  placeholder?: string
  'data-cy'?: string
  'data-pendo'?: string
}
export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onReset,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div
      className={styles['search-input-container']}
      onClick={() => inputRef?.current?.focus()}
      data-cy="tr-search-input-container"
    >
      <div className={styles['icon-container']}>
        <IconObjectMagnifyingGlass size="16" fillColor="gray-400" />
      </div>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        aria-label={placeholder}
        data-cy={rest['data-cy']}
        data-pendo={rest['data-pendo']}
        ref={inputRef}
      />
      {value && (
        <div className={styles['icon-container']}>
          <button
            type="button"
            onClick={onReset}
            aria-label="reset-input"
            data-cy="tr-reset-input-btn"
          >
            <IconActionDelete />
          </button>
        </div>
      )}
    </div>
  )
}
