import React from 'react'
import { Attempt } from './Attempt/Attempt'
import { ResizableWrapper } from '../ResizableWrapper/ResizableWrapper'
import type { RunnableStatus } from '~/src/webworkers/database.worker'
import StatusIcon from '@cypress-design/react-statusicon'
import styles from './CommandLogs.module.scss'
import cs from 'clsx'
import { IconChevronRightSmall } from '@cypress-design/react-icon'
import Tooltip from '@cypress-design/react-tooltip'
import { useReplayContext } from '../../Context/ReplayContext'

const TitleParts: React.FC<{
  titleParts: string[]
  isNested?: boolean
}> = ({ titleParts, isNested }) => {
  const [titlePart, ...rest] = titleParts
  return (
    <div className={cs(isNested && styles['tooltip-title-row'])}>
      <div className={styles['tooltip-title']}>
        <span className={styles['title-parts']} data-cy="test-title-fragment">
          {titlePart}
        </span>
        {rest.length > 0 && <IconChevronRightSmall color="#747994" />}
      </div>
      {rest.length > 0 && <TitleParts titleParts={rest} isNested />}
    </div>
  )
}

const Title = () => {
  const { titleParts } = useReplayContext()

  const lastIdx = titleParts.length - 1
  const testPath = titleParts.slice(0, lastIdx)
  const testName = titleParts[lastIdx]

  return (
    <Tooltip
      interactive
      placement="bottom-start"
      tabIndex={0}
      popper={
        <div
          data-cy="title-parts-tooltip"
          className={styles['tooltip-container']}
        >
          <TitleParts titleParts={titleParts} />
        </div>
      }
    >
      <div className={styles['titles-container']}>
        <div data-cy="title" className={styles['title']}>
          {(testPath || []).map((titlePart: string, idx: number) => (
            <React.Fragment key={`${titlePart}-${idx}`}>
              <span
                className={styles['title-parts']}
                data-cy="test-title-fragment"
              >
                {titlePart}
              </span>
              {idx !== testPath.length - 1 && (
                <IconChevronRightSmall color="#747994" />
              )}
            </React.Fragment>
          ))}
        </div>
        <div data-cy="subtitle" className={styles['subtitle']}>
          <span>{testName}</span>
        </div>
      </div>
    </Tooltip>
  )
}

const CommandLogsHeader = () => {
  const { testState } = useReplayContext()
  const runnableState = testState.toLowerCase()

  return (
    <div data-cy="CommandLogsHeader" className={styles['header-container']}>
      <div
        title={testState}
        data-cy="test-status"
        className={styles['status-icon-container']}
      >
        <StatusIcon
          size="16"
          variant="solid"
          status={runnableState as RunnableStatus}
        />
      </div>
      <Title />
    </div>
  )
}

export const CommandLogs: React.FC = () => {
  const {
    helpers: { setIsDragging },
  } = useReplayContext()
  return (
    <ResizableWrapper
      min={360}
      initial={425}
      max={550}
      drag="right"
      setIsDragging={setIsDragging}
    >
      <div data-cy="CommandLogs" className={styles['command-logs-container']}>
        <CommandLogsHeader />
        <div className={styles['attempt-wrapper']}>
          <Attempt />
        </div>
      </div>
    </ResizableWrapper>
  )
}
