import type { TestRunnerAuthPayload } from '@packages/common/src/isomorphicTypes'
import type { Profile as GithubProfileInternal } from 'passport-github'
import type { Profile as GoogleProfileInternal } from 'passport-google-oauth'

export type GoogleProfile = Omit<GoogleProfileInternal, 'emails' | '_json'> & {
  provider: 'google'
  emails: {
    value: string
    verified: boolean
  }[]
  /**
   * The organization id if we're using this for SSO
   */
  organizationId?: number
  _json: {
    /**
     * Hosted domain, the domain we are logging in for
     */
    hd?: string
    sub: string
    name: string
    email: string
    locale: string
    picture: string
    given_name: string
    family_name: string
    email_verified: boolean
  }
}

export type GithubProfile = Omit<GithubProfileInternal, 'emails'> & {
  provider: 'github'
  token: string
  emails: {
    value: string
    primary: boolean
    verified: boolean
  }[]
}

export interface LocalProfile {
  provider: undefined // TODO: Revisit this, maybe structure this similar to other providers
  userId: number
  email: string
}

export interface SamlProfile {
  nameID: string
  name?: string
  email?: string
  issuer?: string
  organizationId: number
  provider: 'saml'
  // Default OneLogin Format
  'User.FirstName'?: string
  'User.LastName'?: string
  'User.Email'?: string
}

export interface SamlResponse {}

// https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens
export interface WaadProfile {
  /**
   * Org ID we're associating this profile with
   */
  organizationId: number
  provider: 'waad'
  _json: {
    /**
     * The primary username that represents the user. It could be an email address, phone number,
     * or a generic username without a specified format. Its value is mutable and might change
     * over time. Since it is mutable, this value must not be used to make authorization decisions.
     * The profile scope is required to receive this claim.
     */
    preferred_username: string
    /**
     * The immutable identifier for an object in the Microsoft identity system, in this case,
     * a user account. This ID uniquely identifies the user across applications - two different
     * applications signing in the same user will receive the same value in the oid claim.
     * The Microsoft Graph will return this ID as the id property for a given user account.
     * Because the oid allows multiple apps to correlate users, the profile scope is required
     * to receive this claim. Note that if a single user exists in multiple tenants, the user
     * will contain a different object ID in each tenant - they're considered different accounts,
     * even though the user logs into each account with the same credentials. The oid claim is a
     * GUID and cannot be reused.
     */
    oid: string
    /**
     * The email claim is present by default for guest accounts that have an email address.
     * Your app can request the email claim for managed users (those from the same tenant as the resource)
     * using the email optional claim. On the v2.0 endpoint, your app can also request the
     * email OpenID Connect scope - you don't need to request both the optional claim and the
     * scope to get the claim. The email claim only supports addressable mail from the user's
     * profile information.
     */
    email: string
    /**
     * The name claim provides a human-readable value that identifies the subject of the token.
     * The value isn't guaranteed to be unique, it is mutable, and it's designed to be used only
     * for display purposes. The profile scope is required to receive this claim.
     */
    name: string
  }
}

export type AllPassportProfiles =
  | GoogleProfile
  | GithubProfile
  | SamlProfile
  | WaadProfile
  | LocalProfile

export type AuthLoginControllerQuery =
  | {
      source: 'dashboard'
      connection: string
    }
  | ({
      source: 'test_runner'
      connection: string
    } & TestRunnerAuthPayload)

export type AuthLoginFlowState =
  | {
      source: 'unknown'
    }
  | AuthLoginControllerQuery
