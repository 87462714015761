import { usePreviousValueOf } from '@frontend/dashboard/src/lib/hooks/usePreviousValueOf'
import type { SnapshotInfo } from '../components/Context/ReplayContext/useSnapshotController'

export function useShouldScrollToEdge(
  time: number,
  currSection: SnapshotInfo['section'],
  currPin: SnapshotInfo | null
) {
  const prevTime = usePreviousValueOf(time)
  const timeChanged = time !== prevTime
  return timeChanged && currPin?.section !== currSection
}
