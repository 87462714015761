import React from 'react'
import { useReplayContext } from '../../Context/ReplayContext'
import { CustomSVGIcon } from '@frontend/design-system/src/components/custom-icons/CustomIcon'
import { ALT_CLICKABLE_ELEMENT } from '../AttemptPicker/AttemptPicker'
import {
  ActionPauseLargeX16,
  ActionPlayLargeX16,
  ActionPreviousLargeX16,
  ActionNextLargeX16,
} from '@frontend/design-system/src/lib/img/icons/index'
import styles from './PlayButton.module.scss'
import cs from 'clsx'

type ClickBtnEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

const ScrubberButton: React.FC<{
  active?: boolean
  disabled?: boolean
  onClick: (e: ClickBtnEvent) => void
  SVGComponent: React.ComponentProps<typeof CustomSVGIcon>['SVGComponent']
  variant?: 'small' | 'medium'
  className?: string
  'data-cy'?: string
  'data-pendo'?: string
}> = ({
  active,
  disabled,
  onClick,
  SVGComponent,
  variant = 'medium',
  className,
  ...rest
}) => {
  return (
    <button
      type="button"
      data-cy={rest['data-cy']}
      data-pendo={rest['data-pendo']}
      onClick={onClick}
      disabled={disabled}
      className={cs(
        styles['tr-play-button'],
        active && styles['active-highlight'],
        variant === 'small' && styles['tr-play-button-small'],
        className
      )}
    >
      <CustomSVGIcon
        className={cs(
          styles['tr-play-icon'],
          disabled && styles['tr-play-icon-disabled']
        )}
        width={variant === 'small' ? 12 : 16}
        SVGComponent={SVGComponent}
      />
    </button>
  )
}

export const NextButton: React.FC = () => {
  const {
    attempts: { options, selectedOption, changeAttempt },
  } = useReplayContext()
  const isOnLastAttempt = selectedOption.value === options.length - 1
  return (
    <ScrubberButton
      data-cy="tr-next-button"
      data-pendo="replay-footer-next"
      disabled={isOnLastAttempt}
      SVGComponent={ActionNextLargeX16}
      onClick={() => changeAttempt(selectedOption.value + 1)}
    />
  )
}

export const PreviousButton: React.FC = () => {
  const {
    attempts: { selectedOption, changeAttempt },
  } = useReplayContext()
  const isOnFirstAttempt = selectedOption.value === 0
  return (
    <ScrubberButton
      data-cy="tr-prev-button"
      data-pendo="replay-footer-prev"
      disabled={isOnFirstAttempt}
      SVGComponent={ActionPreviousLargeX16}
      onClick={() => changeAttempt(selectedOption.value - 1)}
    />
  )
}

export const PlayButton: React.FC = () => {
  const {
    timer,
    helpers: { clearPinned },
  } = useReplayContext()
  return (
    <ScrubberButton
      data-cy="tr-play-button"
      data-pendo="replay-footer-play"
      active={timer.playing}
      SVGComponent={timer.playing ? ActionPauseLargeX16 : ActionPlayLargeX16}
      onClick={() => {
        if (timer.time < timer.max) {
          if (timer.playing) {
            timer.pause()
          } else {
            timer.play()
          }
        } else {
          timer.reset()
          timer.play()
        }
        clearPinned()
      }}
    />
  )
}

export const PlayButtonSmall: React.FC<{
  onClick: (e: ClickBtnEvent) => void
}> = ({ onClick }) => {
  return (
    <ScrubberButton
      variant="small"
      data-cy="tr-small-play-button"
      data-pendo="replay-footer-small-play"
      SVGComponent={ActionPlayLargeX16}
      className={ALT_CLICKABLE_ELEMENT}
      onClick={onClick}
    />
  )
}
