import type { EventsPayload as EventsPayloadV9 } from '@packages/app-capture-protocol/src/db/schemas/v9'
import type { EventsPayload } from '@packages/app-capture-protocol/src/db/schemas/latest'
import type { NodeMap } from '../Replay/nodeMap'
import { getElementFromPayload } from '../getElementFromPayload'

export const applyElementFocused = ({
  ctx,
  elementFocusedPayload,
}: {
  ctx: {
    nodeMap: React.MutableRefObject<NodeMap>
  }
  elementFocusedPayload:
    | EventsPayload['cdp']['dom:element-focused']
    | EventsPayloadV9['cdp']['dom:element-focused']
}) => {
  const elementToFocus = getElementFromPayload(
    ctx.nodeMap.current,
    elementFocusedPayload
  )

  if (elementToFocus && elementToFocus.dataset) {
    // add all the focus data attributes to the element
    elementToFocus.dataset.cyReplayFocus = 'true'
    elementToFocus.dataset.cyReplayFocusVisible = 'true'
    elementToFocus.dataset.cyReplayFocusWithin = 'true'

    // add focus-within attribute to all parent elements so we can style them
    // if the element has an assigned slot, we need to add the focus-within attribute to the
    // assigned slot's parent element instead of the element's parent element
    let parentElement =
      elementToFocus.assignedSlot?.parentElement || elementToFocus.parentElement
    while (parentElement) {
      parentElement!.dataset.cyReplayFocusWithin = 'true'
      parentElement =
        parentElement.assignedSlot?.parentElement || parentElement.parentElement
    }
  }
}

export const applyElementBlurred = ({
  ctx,
  elementBlurredPayload,
}: {
  ctx: {
    nodeMap: React.MutableRefObject<NodeMap>
  }
  elementBlurredPayload: EventsPayload['cdp']['dom:element-blurred']
}) => {
  const elementToBlur = getElementFromPayload(
    ctx.nodeMap.current,
    elementBlurredPayload
  )

  // document won't have dataset: https://github.com/cypress-io/cypress-services/pull/8993
  if (elementToBlur && elementToBlur.dataset) {
    // remove all the focus data attributes from the element
    delete elementToBlur.dataset.cyReplayFocus
    delete elementToBlur.dataset.cyReplayFocusVisible
    delete elementToBlur.dataset.cyReplayFocusWithin

    // remove focus-within attribute from all parent elements
    // if the element has an assigned slot, we need to remove the focus-within attribute from the
    // assigned slot's parent element instead of the element's parent element
    let parentElement =
      elementToBlur.assignedSlot?.parentElement || elementToBlur.parentElement
    while (parentElement) {
      delete parentElement.dataset.cyReplayFocusWithin
      parentElement =
        parentElement.assignedSlot?.parentElement || parentElement.parentElement
    }
  }
}
