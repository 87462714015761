import React from 'react'
import Tooltip from '@cypress-design/react-tooltip'
import styles from './WaterfallPill.module.scss'
import cx from 'classnames'

export type Timeline = {
  min: number
  max: number
  eventStart: number
  eventEnd?: number
}

type WaterfallPillProps = {
  isRange?: boolean
  color?: string
  tooltipContent?: React.ReactNode
  timeline: Timeline
  ['data-cy']?: string
  warningDot?: boolean
}

const WarningWrapper: React.FC = ({ children }) => (
  <div className={styles['warningWrapper']}>
    <div data-cy="warning-dot" className={styles['warningDot']} /> {children}
  </div>
)

export const WaterfallPill = (props: WaterfallPillProps) => {
  const {
    color = '#D0D2E0',
    timeline,
    isRange,
    tooltipContent,
    warningDot,
  } = props

  // Returns a percent based approach
  const barProperties = React.useMemo(() => {
    const fullDuration = timeline.max - timeline.min
    const percentSinceStart =
      (timeline.eventStart - timeline.min) / fullDuration
    const width = Math.max(
      timeline.eventEnd
        ? (timeline.eventEnd - timeline.eventStart) / fullDuration
        : 0,
      0.01
    )
    return {
      width: `${width * 100.0}%`,
      ...(isRange && {
        borderTop: 'none',
        borderBottom: 'none',
        borderRadius: 2,
      }),

      // Note: this sets a limit on percent to prevent the highlight
      // from going out of bounds. We can revise the styling math if
      // we need even more precision from this tiny illustration.
      left: `min(${percentSinceStart * 100}%, 97%)`,
    }
  }, [timeline, isRange])

  const body = (
    <Tooltip
      data-cy={props['data-cy']}
      className={cx(styles['container'], tooltipContent && styles['hoverable'])}
      disabled={!tooltipContent}
      popper={tooltipContent}
      type="light"
      placement="bottom-start"
      interactive={true}
    >
      <div
        className={styles['marker']}
        style={{
          borderColor: color,
          ...barProperties,
        }}
      >
        <div
          className={cx(styles['bg'], isRange && styles['isRange'])}
          style={{
            backgroundColor: color,
          }}
        />
      </div>
    </Tooltip>
  )

  if (warningDot) {
    return <WarningWrapper>{body}</WarningWrapper>
  }

  return body
}
