export type ScoreTrackingResultItem = {
  titleHash: string
  score: number
}

export type ScoreTrackingInputItem = {
  titleHash: string
  bodyHash: string
  hooksHash: string
}
