import 'css-chunk:src/components/ReplayFooter/AttemptPicker/AttemptPicker.module.scss';export default {
  "status-icon-container": "_status-icon-container_1qslx_7",
  "status-color-passed": "_status-color-passed_1qslx_13",
  "status-color-failed": "_status-color-failed_1qslx_16",
  "single-select-wrapper": "_single-select-wrapper_1qslx_20",
  "attempt-list-item-container": "_attempt-list-item-container_1qslx_45",
  "att-number": "_att-number_1qslx_52",
  "base-time": "_base-time_1qslx_59",
  "duration-container": "_duration-container_1qslx_64",
  "checkmark-container": "_checkmark-container_1qslx_86",
  "timeline-wrapper": "_timeline-wrapper_1qslx_92"
};