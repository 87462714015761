import React from 'react'
import Button from '@cypress-design/react-button'
import { IconObjectRuler, IconMenuExpandLeft } from '@cypress-design/react-icon'
import styles from './Header.module.scss'
import cs from 'clsx'

export type BrowserHeaderProps = {
  url: string
  viewport: string
  onClickDevtools: () => void
  devtoolsOpen?: boolean
  isPageLoading: boolean
}

const UrlBar: React.FC<{
  url: string
  isPageLoading: boolean
}> = ({ url, isPageLoading }) => {
  return (
    <div
      title={url}
      data-cy="url-bar"
      className={cs(
        styles['url-bar'],
        styles['text-display'],
        isPageLoading && styles['url-page-loading']
      )}
    >
      {url}
    </div>
  )
}

const Viewport: React.FC<{
  viewport: string
}> = ({ viewport }) => {
  return (
    <div className={styles['aspect-ratio-container']}>
      <div className={styles['aspect-ratio-icon']}>
        <IconObjectRuler />
      </div>
      <div
        data-cy="aspect-ratio"
        className={cs(styles['aspect-ratio'], styles['text-display'])}
      >
        {viewport}
      </div>
    </div>
  )
}

const DevtoolsButton: React.FC<{
  onClick: () => void
}> = ({ onClick }) => {
  return (
    <Button
      data-cy="browser-devtools-button"
      className={cs(styles['closeButton'], 'h-full', 'bg-transparent')}
      onClick={onClick}
      variant="outline-dark"
      size="24"
    >
      <span className={cs('text-white-100', 'pr-[8px]')}>Developer tools</span>
      <IconMenuExpandLeft size="16" className="text-white-100" />
    </Button>
  )
}

export const Header: React.FC<BrowserHeaderProps> = ({
  url,
  viewport,
  onClickDevtools,
  devtoolsOpen,
  isPageLoading,
}) => {
  return (
    <div
      data-cy="browser-header-container"
      className={styles['browser-header-container']}
    >
      <UrlBar url={url} isPageLoading={isPageLoading} />
      <Viewport viewport={viewport} />
      {!devtoolsOpen && <DevtoolsButton onClick={onClickDevtools} />}
    </div>
  )
}
