import { useState, useCallback } from 'react'

// unfortunate hack to update closures to
// catch up with Reporter's latest state:
export const useSyncWithReporter = () => {
  const [hack, toggleHack] = useState(1)

  const sync = useCallback(() => {
    toggleHack((n) => n + 1)
  }, [])

  return {
    retry: hack,
    sync,
  }
}
