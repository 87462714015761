import { parse, walk, generate, clone, find } from 'css-tree'
import { assetsBase64MetadataCache, assetsCache } from './assetsCache'
import { warnOnce } from './Replay/warnOnce'
import {
  Base64AssetMetadata,
  toBase64Asset,
  updateMetadataWithAdditionalMetadata,
  updateMetadataWithBase64Asset,
} from './toBase64Asset'

import type {
  CssNode,
  StringNode,
  Url,
  List,
  Selector,
  ListItem,
  WalkContext,
} from 'css-tree'
import type { HrefResolver } from './Replay/hrefResolver'
import type { NetworkResources } from './useDomReplay'

const focusSelectors = [':focus-within', ':focus-visible', ':focus']

const updateUrl = (
  node: StringNode | Url,
  doc: Document,
  networkResources: NetworkResources,
  hrefResolver: HrefResolver,
  href: string,
  currentBase64AssetMetadata: Base64AssetMetadata,
  base64AssetMetadata?: Base64AssetMetadata
) => {
  const imageUrl = node.value
  // if the url is a data url or a fragment, we don't need to process it
  if (imageUrl.startsWith('data') || imageUrl.startsWith('#')) {
    return
  }

  const resolvedUrl = hrefResolver.resolve(href, doc)
  const resolvedImageUrl = hrefResolver.resolveAgainst(
    imageUrl,
    resolvedUrl,
    doc
  )

  if (
    resolvedImageUrl &&
    // Prevent circular imports
    resolvedImageUrl !== resolvedUrl
  ) {
    const possibleHash =
      networkResources.domNetworkAssets.pathHash[resolvedImageUrl]
    if (possibleHash) {
      const hashInCache = assetsCache.get(possibleHash)
      if (hashInCache) {
        node.value = hashInCache

        if (base64AssetMetadata) {
          updateMetadataWithBase64Asset(currentBase64AssetMetadata, hashInCache)
        }
      } else {
        const responseContext =
          networkResources.domNetworkAssets.hashData[possibleHash]

        let processedCss
        if (!responseContext || responseContext?.isBase64) {
          processedCss = toBase64Asset(responseContext)
        } else {
          processedCss = toBase64Asset({
            ...responseContext,
            isBase64: false,
            body: rewriteCss(
              doc,
              networkResources,
              responseContext.body,
              hrefResolver,
              resolvedImageUrl,
              possibleHash,
              false,
              base64AssetMetadata
            ),
          })
        }

        if (base64AssetMetadata) {
          updateMetadataWithBase64Asset(
            currentBase64AssetMetadata,
            processedCss
          )
        }

        node.value = processedCss
        assetsCache.set(possibleHash, processedCss)
      }
    } else {
      warnOnce(
        resolvedImageUrl,
        `Failed to find response for (${resolvedImageUrl})`
      )
      const processedCss = 'data:;base64'
      node.value = processedCss
    }
  }
}

const processCssNode = (node: CssNode) => {
  if (
    node.type === 'PseudoClassSelector' &&
    focusSelectors.includes(`:${node.name}`)
  ) {
    // create a new data-cy-replay-focus attribute selector to replace the focus pseudo class selector
    node = {
      type: 'AttributeSelector',
      name: {
        type: 'Identifier',
        name: `data-cy-replay-${node.name}`,
      },
      matcher: null,
      value: null,
      flags: null,
    }
  } else if ('children' in node && node.children) {
    // if the node has children, we need to recursively call this function
    node.children = node.children.map(processCssNode)
  }

  return node
}

const addCypressFocusAttributes = ({
  node,
  item,
  list,
}: {
  node: Selector
  item: ListItem<CssNode>
  list: List<CssNode>
}) => {
  // create a clone of the node so we can modify it
  const cloneNode = processCssNode(clone(node))

  // insert the new data-cy-replay-focus selector before the focus pseudo class selector
  list.insertData(cloneNode, item)
}

const shouldRewrite = (css: string) => {
  // if the css contains an @import or url or a focus selector, we need to rewrite it
  return (
    css.includes('@import') ||
    css.includes('url(') ||
    focusSelectors.some((selector) => css.includes(selector))
  )
}

const hasFocusPseudoClassSelector = (node: CssNode) => {
  return !!find(
    node,
    (node) =>
      node.type === 'PseudoClassSelector' &&
      focusSelectors.includes(`:${node.name}`)
  )
}

export const rewriteCss = (
  doc: Document,
  networkResources: NetworkResources,
  cssText: string | null,
  hrefResolver: HrefResolver,
  href: string,
  // Cache key if this stylesheet can be cached
  // Dangerous to use outside of network body hash.
  key?: string,
  isElementAttribute: boolean = false,
  base64AssetMetadata?: Base64AssetMetadata
) => {
  if (!cssText) {
    return ''
  }

  // if for some reason the cssText is not a string, we can't rewrite it,
  // so we just return the cssText as is
  if (typeof cssText !== 'string') {
    return cssText
  }

  const result = key && assetsCache.get(key)
  if (result) {
    const cachedIndividualBase64AssetMetadata =
      assetsBase64MetadataCache.get(key)

    if (cachedIndividualBase64AssetMetadata && base64AssetMetadata) {
      updateMetadataWithAdditionalMetadata(
        base64AssetMetadata,
        cachedIndividualBase64AssetMetadata
      )
    }

    return result
  }

  if (!shouldRewrite(cssText)) {
    if (key) {
      assetsCache.set(key, cssText)
    }
    return cssText
  }

  const ast = parse(cssText, {
    context: isElementAttribute ? 'declarationList' : 'stylesheet',
  })
  const currentBase64AssetMetadata = {
    count: 0,
    totalSize: 0,
    largestSize: 0,
  }

  let selectorBeingProcessed: Selector | null = null
  walk(ast, {
    enter(node: CssNode, item: ListItem<CssNode>, list: List<CssNode>) {
      const self = this as WalkContext

      switch (node.type) {
        case 'Url':
          updateUrl(
            node,
            doc,
            networkResources,
            hrefResolver,
            href,
            currentBase64AssetMetadata,
            base64AssetMetadata
          )
          break
        case 'String':
          if (self.atrule?.name === 'import') {
            updateUrl(
              node,
              doc,
              networkResources,
              hrefResolver,
              href,
              currentBase64AssetMetadata,
              base64AssetMetadata
            )
          }
          break
        case 'Selector':
          // if we find a focus selector, we need to add a new selector to the rule,
          // replacing the focus selectors with our custom cy-replay-focus attribute selectors.
          // we only want to process the top-level selectors, since it's possible to have nested
          // selectors, so we need to keep track of the selector we are processing
          if (!selectorBeingProcessed && hasFocusPseudoClassSelector(node)) {
            selectorBeingProcessed = node
            addCypressFocusAttributes({ node, list, item })
          }
          break
        default:
          break
      }
    },
    leave(node: CssNode) {
      // reset the selectorBeingProcessed after we have processed it
      if (node === selectorBeingProcessed) {
        selectorBeingProcessed = null
      }
    },
  })

  if (base64AssetMetadata) {
    updateMetadataWithAdditionalMetadata(
      base64AssetMetadata,
      currentBase64AssetMetadata
    )
  }

  const finalCSS = generate(ast)
  if (key) {
    assetsCache.set(key, finalCSS)
    assetsBase64MetadataCache.set(key, currentBase64AssetMetadata)
  }
  return finalCSS
}
