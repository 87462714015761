import React from 'react'
import styles from './Separator.module.scss'
import cs from 'clsx'

export const Separator: React.FC<{
  style?: React.CSSProperties
  className?: string
  forwardRef?: React.LegacyRef<HTMLDivElement>
}> = ({ style, className, forwardRef }) => (
  <div
    ref={forwardRef}
    data-cy="separator"
    className={cs(styles['container'], className)}
    style={style}
  >
    <svg
      className={styles['arrow']}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="15"
      height="15"
      fill="none"
    >
      <path
        d="M13.2236 7.44721L14.118 7L13.2236 6.55279L2.67082 1.27639C1.67347 0.777717 0.5 1.50296 0.5 2.61803V11.382C0.5 12.497 1.67347 13.2223 2.67082 12.7236L13.2236 7.44721Z"
        fill="#A06CE4"
        stroke="#1B1E2E"
      />
    </svg>
    <div className={styles['line']} />
  </div>
)
