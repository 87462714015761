import * as React from 'react'

export function usePreviousValueOf<T>(value: T) {
  const ref = React.useRef<T | null>(null)
  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
