import React from 'react'
import Tabs from '@cypress-design/react-tabs'
import Button from '@cypress-design/react-button'
import Tooltip from '@cypress-design/react-tooltip'
import {
  IconMenuExpandRight,
  IconTechnologyElementSelector,
  IconArrowOpposingUpDown,
  IconTechnologyTerminal,
  IconGeneralCommandKey,
  IconActionQuestionMarkCircle,
} from '@cypress-design/react-icon'
import styles from './DevToolsHeader.module.scss'
import cx from 'classnames'
import { getOperatingSystem } from '@frontend/dashboard/src/lib/getOperatingSystem'
import { TabsShim } from '../../../../utils/TabsShim/TabsShim'
import type { SnapshotInfo } from '~/src/components/Context/ReplayContext/useSnapshotController'

export type DevToolTabIds = 'network' | 'console'
export type DevToolHeaderProps = {
  pin?: SnapshotInfo
  clearPinned?: () => void
  onHeaderClose: VoidFunction
  onSelectedTab: (displayedTab: DevToolTabIds) => void
  selectedTab: DevToolTabIds
}

export const DevToolsTooltipOverlay: React.FC = () => {
  const os = getOperatingSystem()
  return (
    <div
      data-cy="devtools-tooltip-overlay"
      className={styles['cta-tooltip-overlay']}
    >
      <p className={styles['cta-title']}>Inspect elements</p>
      <p className={styles['cta-description']}>
        Use your browser's DevTools to inspect elements.
      </p>
      <div className={styles['keystrokes-container']}>
        <div className={styles['keystroke']}>
          {os === 'mac' ? (
            <IconGeneralCommandKey
              data-cy="mac-command-icon"
              className={styles['command-icon']}
              size="16"
            />
          ) : (
            'Ctrl'
          )}
        </div>
        <div className={styles['keystroke']}>Shift</div>
        <div className={styles['keystroke']}>C</div>
      </div>
    </div>
  )
}

export const DevToolsHeader = ({
  onHeaderClose,
  onSelectedTab,
  selectedTab,
  pin,
  clearPinned,
}: DevToolHeaderProps) => {
  return (
    <div data-cy={'devtools-header'} className={styles['container']}>
      <Button
        data-cy="devtools-header-close"
        style={{
          width: 33,
          minWidth: 33,
          height: 32,
        }}
        className={cx(styles['closeButton'])}
        variant="outline-dark"
        size="24"
        onClick={onHeaderClose}
        data-pendo="replay-dev-tools-header-close"
      >
        <IconMenuExpandRight className="text-gray-500" size="16" />
      </Button>

      <TabsShim data-cy="devtools-header-tab-container">
        <Tabs
          variant="dark-large"
          activeId={selectedTab}
          tabs={[
            {
              id: 'network',
              label: 'Network',
              icon: IconArrowOpposingUpDown,
              'data-pendo': 'replay-devtools-header-network-tab',
              'aria-controls': 'network-tabpanel',
            },
            {
              id: 'console',
              label: 'Console',
              icon: IconTechnologyTerminal,
              'data-pendo': 'replay-devtools-header-console-tab',
              'aria-controls': 'console-tabpanel',
            },
          ]}
          onSwitch={({ id }) => {
            if (
              pin?.section === 'network-calls' ||
              pin?.section === 'console-logs'
            ) {
              clearPinned?.()
            }
            onSelectedTab(id as DevToolTabIds)
          }}
        />
      </TabsShim>

      <Tooltip
        className={styles['tooltipContainer']}
        popper={<DevToolsTooltipOverlay />}
        open={true}
        placement="bottom-end"
      >
        <div
          data-cy="devtools-header-inspector"
          className="cursor-default flex items-center justify-center shrink-0 text-[14px] leading-[20px]"
          data-pendo="replay-devtools-header-inspector"
        >
          <IconTechnologyElementSelector className="text-gray-500" size="16" />
          <span className="text-gray-500 px-[4px]">Inspect</span>
          <IconActionQuestionMarkCircle
            data-cy="icon-question-mark"
            className={styles['iconQuestionMark']}
            size="16"
          />
        </div>
      </Tooltip>
    </div>
  )
}
