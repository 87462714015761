import React from 'react'
import { useReplayContext } from '../Context/ReplayContext'
import { CommandLogs } from './CommandLogs/CommandLogs'
import { Browser } from './Browser/Browser'
import { DevTools } from './DevTools/DevTools'
import styles from './ReplayBody.module.scss'

export const ReplayBody: React.FC = () => {
  const {
    devtools: { open },
  } = useReplayContext()
  return (
    <div className={styles['replay-body-container']}>
      <div className={styles['command-logs-wrapper']}>
        <CommandLogs />
      </div>
      <div className={styles['browser-wrapper']}>
        <Browser />
      </div>
      {open && (
        <div className={styles['dev-tools-wrapper']}>
          <DevTools />
        </div>
      )}
    </div>
  )
}
