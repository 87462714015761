import type { dbt } from '@packages/types'
import type { RunGroupStatusesValue } from '@packages/common'
import type { ReportIssuesEnum } from './allNexusTypes.gen'

export type GithubCheckStyle = 'group' | 'spec'
export type IntegrationCheckStyle = GithubCheckStyle | 'run'

export type ValidRunStatus =
  | 'running'
  | 'errored'
  | 'failed'
  | 'timedOut'
  | 'noTests'
  | 'passed'
  | 'overLimit'
  | 'cancelled'

export type ValidInstanceStatus =
  | 'unclaimed'
  | 'running'
  | 'errored'
  | 'failed'
  | 'timedOut'
  | 'passed'
  | 'noTests'
  | 'cancelled'

export type ValidGroupStatus =
  | 'running'
  | 'errored'
  | 'failed'
  | 'timedOut'
  | 'passed'
  | 'noTests'
  | 'cancelled'

export type RunGroupResultAggregates = {
  totalSpecs: number
  totalTests: number
  totalPasses: number
  totalFailures: number
  totalPending: number
  groupDuration: number
  groupEndTime: Date
  groupStartTime: Date
}

export type RunGroupResult = Pick<
  dbt.Instances,
  | 'groupId'
  | 'groupName'
  | 'browserName'
  | 'osName'
  | 'osVersion'
  | 'browserVersion'
> &
  RunGroupResultAggregates & {
    statuses: RunGroupStatusesValue[]
  }

// This is the definitive shape of what we're considering a run group
export type RunGroup = RunGroupResultAggregates & {
  buildId: number
  groupId: string
  groupName: string
  browserName: string
  osName: string
  osVersion: string
  browserVersion: string
  status: RunGroupStatusesValue | undefined
  statuses: RunGroupStatusesValue[]
  groupDuration: number
}

export type CIProvider =
  | 'appveyor'
  | 'circle'
  | 'gitlab'
  | 'teamcity'
  | 'teamfoundation'
  | 'travis'
  | 'githubActions'

export type OsName = 'win32' | 'darwin' | 'linux'

export type AverageRunsDurationsByTimeNode = {
  averageRuntime: number | null
  averageConcurrency: number | null
  parallelizationSavings: number | null
  date: Date
  totalRuns: number
  passRate: number
  failureRate: number
}

export type AverageRunsDurationsByRunsNode = {
  averageRuntime: number | null
  averageConcurrency: number | null
  parallelizationSavings: number | null
  startDate: Date
  endDate: Date
  totalRuns: number
  passRate: number
  failureRate: number
  countRunning: number
  countErrored: number
  countFailed: number
  countTimedOut: number
  countNoTests: number
  countPassed: number
  countOverLimit: number
  countCancelled: number
}

export type TestSuiteSizeByTimeNode = {
  totalTests: number | null
  totalSpecs: number | null
  date: Date
  totalRuns: number
  passRate: number
  failureRate: number
}

export type OmittedInstanceFields = 'error'
export type RunInstance = Omit<dbt.Instances, OmittedInstanceFields>
export type ProjectSpecs = Pick<
  dbt.InstanceSpecHashes,
  'spec' | 'hash' | 'projectId'
>

export interface CloudProjectSpec extends ProjectSpecs {
  projectSlug: string
}

export type GitlabStatusStates =
  | 'pending'
  | 'running'
  | 'success'
  | 'failed'
  | 'canceled'

export type BitbucketStatusStates =
  | 'SUCCESSFUL'
  | 'FAILED'
  | 'INPROGRESS'
  | 'STOPPED'

export type ProblemCode =
  | 'FREE_PLAN_EXCEEDS_MONTHLY_TESTS'
  | 'FREE_PLAN_EXCEEDS_MONTHLY_TESTS_V2'
  | 'FREE_PLAN_NEARING_MONTHLY_TESTS_V2'
  | 'FREE_PLAN_NEARING_MONTHLY_TESTS'
  | 'FREE_PLAN_ENTERING_GRACE_PERIOD'
  | 'FREE_PLAN_IN_GRACE_PERIOD_EXCEEDS_MONTHLY_TESTS'
  | 'FREE_PLAN_IN_GRACE_PERIOD_NEARING_MONTHLY_TESTS'
  | 'PAID_PLAN_EXCEEDS_MONTHLY_TESTS'
  | 'PAID_PLAN_NEARING_MONTHLY_TESTS'
  | 'PAID_PLAN_IN_GRACE_PERIOD_EXCEEDS_MONTHLY_TESTS'
  | 'PARALLEL_FEATURE_NOT_AVAILABLE_IN_PLAN'
  | 'PLAN_IN_GRACE_PERIOD_PARALLEL_FEATURE_USED'
  | 'PLAN_IN_GRACE_PERIOD_RUN_GROUPING_FEATURE_USED'
  | 'RUN_GROUPING_FEATURE_NOT_AVAILABLE_IN_PLAN'
  | 'AUTO_CANCEL_MISMATCH'
  | 'AUTO_CANCEL_NOT_AVAILABLE_IN_PLAN'

export interface Warning {
  code: ProblemCode
  name: string
  message: string
  subject: 'billing'
  [others: string]: any
}

export type UsageDetails = {
  amount: number
  percentage: number
}

export type Used = {
  monthlyPrivateTests: number
  monthlyPublicTests: number
  monthlyTestRecordings: number
  details: UsageDetails
}

export type CanAddRunResult = {
  allowed: boolean
  warnings: Warning[]
  errors: Warning[]
  used: Used
}

export type JiraIssueAssignee = {
  displayName: string
  avatarUrls: {
    '24x24': string
  }
}

export type JiraIssueFields = {
  created: string
  status: {
    name: string
  }
  assignee: JiraIssueAssignee
}

export type JiraIssue = {
  key: string
  self: string
  fields: JiraIssueFields
}

export type GroupNameNode = {
  name?: string | null
  machineCount: number
  status: string
}

export interface OrgUsageCounts {
  privateTestsRan: number
  publicTestsRan: number
  totalTestsRan: number
}

export type BrowserName =
  | 'electron'
  | 'chrome'
  | 'chromium'
  | 'firefox'
  | 'webkit'
  | string

export interface RunAQConfig {
  config: any | null
  configSchemaVersion: string
  updatedAt: Date
  minBuildWithSameConfig: number
  isLatestConfig: boolean
}

export type ReportIssue = {
  status: ReportIssuesEnum
  instanceId: number
}
