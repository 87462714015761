import { useStubsEffect } from './useStubsEffect'
import { useRoutesEffect } from './useRoutesEffect'
import { useSessionsEffect } from './useSessionsEffect'
import { useTestBodyEffect } from './useTestBodyEffect'
import { useGroupedCommands } from './useGroupedCommands'
import type { AttemptHookContext } from '../useAttemptModel'

/**
 * These are a series of hacks that add behaviors to Cypress
 * App's Reporter component that it doesn't natively support.
 * May your journey be safe and fruitful.
 */
export const useReporterEffects = (ctx: AttemptHookContext) => {
  const grouped = useGroupedCommands(ctx.commands)

  useStubsEffect(grouped.agent, ctx)
  useRoutesEffect(grouped.route, ctx)
  useSessionsEffect(grouped.command, ctx)

  const { scrollTargetRef } = useTestBodyEffect({
    attemptId: ctx.attemptNumber,
    time: ctx.timer.time,
    grouped,
    ctx,
  })

  return { scrollTargetRef }
}
