import { useMemo, useEffect } from 'react'
import type { TestLifecycleEndEvent } from '@packages/app-capture-protocol/src/types'
import { useReporterEffects } from './useReporterEffects'
import type { Timer } from '~/src/utils/useGlobalTimer'

import {
  AttemptModel,
  LogProps,
  RootRunnable,
  runnablesStore,
  runnablesStoreType,
} from '~/submodules'

import type {
  ConsolePropsCommandLog,
  CypressAppEvent,
} from '~/src/webworkers/database.worker'
import { useSyncWithReporter } from './useReporterEffects/useSyncWithReporter'

export type AttemptHookContext = {
  commands: CypressAppEvent<ConsolePropsCommandLog | TestLifecycleEndEvent>[]
  testRoot: RootRunnable
  attemptNumber: number
  testId: string
  timer: Timer
}

/**
 * helper to type-cast
 * the model downstream:
 */
function getAttemptModel(
  testId: string,
  runnablesStore: runnablesStoreType
): typeof AttemptModel | undefined {
  const testModel = runnablesStore._tests[testId]
  return testModel?.attempts[0]
}

export const useAttemptModel = (
  ctx: AttemptHookContext
): {
  attemptModel?: typeof AttemptModel
  testBodyScrollTargetRef: React.MutableRefObject<Element | null>
} => {
  // hack to keep in sync with Reporter:
  const { sync } = useSyncWithReporter()
  useEffect(sync, [sync, ctx.attemptNumber])

  const testAfterRunEvent = useMemo(() => {
    return ctx.commands.find((c) => {
      return c.type === 'test:after:run' || c.type === 'test:after:run:async'
    }) as CypressAppEvent<TestLifecycleEndEvent>
  }, [ctx.commands])

  /**
   * setup initial state of the reporter when attempt is changed,
   * based on current timestamp, before all other effects are applied:
   */
  useEffect(() => {
    runnablesStore.reset()
    runnablesStore.setRunnables(ctx.testRoot)
    for (const log of ctx.commands) {
      if (log.type === 'log:added') {
        runnablesStore.addLog(log.payload as unknown as LogProps)
      }
      if (log.type === 'log:changed') {
        runnablesStore.updateLog(log.payload as unknown as LogProps)
      }
    }
    runnablesStore.updateTest(
      {
        id: ctx.testId,
        err: testAfterRunEvent?.payload?.err,
        state: testAfterRunEvent?.payload?.state,
      },
      () => {}
    )
    // only run once per attempt selection; initiates
    // all Reporter content in its final state, then
    // the below effects handle time based changes.
    // eslint-disable-next-line
  }, [ctx.attemptNumber])

  // "here be dragons"
  const { scrollTargetRef } = useReporterEffects(ctx)

  return {
    attemptModel: getAttemptModel(ctx.testId, runnablesStore),
    testBodyScrollTargetRef: scrollTargetRef,
  }
}
