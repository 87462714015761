import { useMemo } from 'react'
import type { AttemptHookContext } from '../useAttemptModel'

/**
 * helper hook to reshape data in way that lends itself
 * to the effects we want to create in the Reporter:
 */
export const useGroupedCommands = (
  commands: AttemptHookContext['commands']
) => {
  type ByIdMap = {
    [k: string]: {
      instrument: string
      events: AttemptHookContext['commands']
      start: number
      end: number
    }
  }
  const groupedById = useMemo(() => {
    const map: ByIdMap = {}
    // assumption: ctx.commands are
    // already sorted by timestamp:
    for (const log of commands) {
      // @ts-ignore: "id" exists
      const id = log.payload.id

      if (log.type === 'log:added') {
        map[id] = {
          // @ts-ignore: "instrument" exists
          instrument: log.payload.instrument,
          start: log.timestamp,
          end: log.timestamp,
          events: [log],
        }
      }

      const mapById = map[id]
      if (mapById && log.type === 'log:changed') {
        mapById.end = log.timestamp
        mapById.events.push(log)
      }
    }

    return Object.entries(map)
  }, [commands])

  type ByInstrumentMap = {
    command: typeof groupedById
    session: typeof groupedById
    route: typeof groupedById
    agent: typeof groupedById
  }
  return useMemo(() => {
    const map = {} as ByInstrumentMap
    for (const log of groupedById) {
      const data = log[1]
      type Entry = keyof ByInstrumentMap
      const key = data.instrument as Entry
      if (!map[key]) map[key] = []
      map[key].push(log)
    }
    return map
  }, [groupedById])
}

export type GroupedLogs = ReturnType<typeof useGroupedCommands>
