import 'css-chunk:src/components/ReplayBody/DevTools/NetworkPanel/NetworkPanel.module.scss';export default {
  "container": "_container_1q4gd_7",
  "listContainer": "_listContainer_1q4gd_12",
  "hidden": "_hidden_1q4gd_19",
  "detailsContainer": "_detailsContainer_1q4gd_23",
  "network-filters": "_network-filters_1q4gd_36",
  "search-input-filter": "_search-input-filter_1q4gd_44",
  "network-item-list": "_network-item-list_1q4gd_48",
  "emptyContainer": "_emptyContainer_1q4gd_52",
  "emptyStateDescription": "_emptyStateDescription_1q4gd_61"
};