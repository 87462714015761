export function downloadFile({
  filename,
  text,
}: {
  filename: string
  text: string
}) {
  const element = document.createElement('a')
  const txt = encodeURIComponent(text)
  element.setAttribute('href', `data:text/plain;charset=utf-8,${txt}`)
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
