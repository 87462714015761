import React, { useLayoutEffect, useMemo } from 'react'
import styles from './ConsolePanel.module.scss'
import { ConsoleLogListItem } from './ConsoleLogListItem'
import { SearchInput } from '../SearchInput/SearchInput'
import Tabs from '@cypress-design/react-tabs'
import { TabsShim } from '../../../../utils/TabsShim/TabsShim'
import type { ConsoleEvent } from '~/src/webworkers/database.worker'
import {
  ReplayContextValue,
  useReplayContext,
} from '../../../Context/ReplayContext'
import { NoResultsFilter } from '../../../../utils/NoResultsFilter/NoResultsFilter'
import { ConsolePanelEmptyState } from './ConsolePanelEmptyState'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
import {
  CellMeasurer,
  CellMeasurerCache,
  CellMeasurerChildProps,
} from 'react-virtualized/dist/commonjs/CellMeasurer'
import type { GridCellProps } from 'react-virtualized'
import { useSnapshotChangeContext } from '~/src/components/Context/SnapshotChangeContext'
import { usePreviousValueOf } from '@frontend/dashboard/src/lib/hooks/usePreviousValueOf'
import { useClearParamIfCommandHidden } from '~/src/utils/useClearParamIfCommandHidden'
import { useHandleDownloadMessage } from '~/src/utils/useDownloadMessage'
import { useShouldScrollToEdge } from '~/src/utils/useShouldScrollToEdge'
import { useTargetIsMounted } from '~/src/utils/useTargetIsMounted'
import cs from 'clsx'

const DEFAULT_HEIGHT = 50
const cache = new CellMeasurerCache({
  defaultHeight: DEFAULT_HEIGHT,
  fixedWidth: true,
})

// Keeps track of the virtualized console items that are expanded, so when they re-mount
// we render then in the correct state, preserving the correct height
const expandedCache = new Map()

export type DevToolHeaderProps = {}

const CONSOLE_OPTS = [
  { id: 'all', label: 'All' },
  { id: 'error', label: 'Error' },
  { id: 'warning', label: 'Warning' },
  { id: 'verbose', label: 'Info' },
] as const
type TabId = (typeof CONSOLE_OPTS)[number]['id']

export interface ConsoleDrillShape extends CellMeasurerChildProps {
  cache: CellMeasurerCache
  index: number
  listRef: React.RefObject<List>
}

function renderConsoleItem({
  // react-virtualized:
  key,
  style,
  parent,
  listRef,
  index,

  // test-replay:
  pin,
  timer,
  event,
  setHoveredSnapshots,
  clearHoveredSnapshots,
  clearPinned,
  setPinned,
  expanded,
  setExpanded,
  handleDownloadMessage,
  topSeparator,
}: {
  // react-virtualized:
  key: string
  style: React.CSSProperties
  parent: GridCellProps['parent']
  listRef: React.RefObject<List>
  index: number

  // test-replay:
  event: ConsoleEvent
  timer: ReplayContextValue['timer']
  pin: ReplayContextValue['helpers']['pin']
  setHoveredSnapshots: ReplayContextValue['helpers']['setHoveredSnapshots']
  clearHoveredSnapshots: ReplayContextValue['helpers']['clearHoveredSnapshots']
  clearPinned: ReplayContextValue['helpers']['clearPinned']
  setPinned: ReplayContextValue['helpers']['setPinned']
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  handleDownloadMessage: (eventId: string | number) => void
  topSeparator: boolean
}) {
  const isPinnedItem = pin?.section === 'console-logs' && pin?.id === event.id
  const logSnapshots = [
    {
      name: event.summaryCount > 1 ? 'first' : undefined,
      timestamp: event.eventStart,
      elementsToHighlight: [],
    },
  ]
  if (event.summaryCount > 1) {
    logSnapshots.push({
      name: 'last',
      timestamp: event.eventEnd,
      elementsToHighlight: [],
    })
  }
  const isGhost = event.eventStart > timer.time

  const eventPinInfo = {
    id: event.id,
    section: 'console-logs',
  } as const

  return (
    <CellMeasurer
      key={key}
      cache={cache}
      columnIndex={0}
      rowIndex={index}
      parent={parent}
    >
      {({ measure, registerChild }) => (
        <ConsoleLogListItem
          data-cy-event-id={eventPinInfo.id}
          data-cy-event-start={event.eventStart}
          data-cy-event-end={event.eventEnd}
          style={style}
          drill={{
            measure,
            registerChild,
            cache,
            index,
            listRef,
          }}
          snapshotsUpdate={() => {
            setHoveredSnapshots(eventPinInfo, logSnapshots)
          }}
          snapshotsReset={clearHoveredSnapshots}
          handleDownloadMessage={handleDownloadMessage}
          format={event.format}
          variant={event.variant}
          message={event.message}
          table={event.table}
          focused={isPinnedItem}
          onFocus={() => {
            if (isPinnedItem) {
              clearPinned()
            } else {
              setPinned(eventPinInfo)
            }
          }}
          timeline={{
            min: timer.min,
            max: timer.max,
            eventStart: event.eventStart,
            eventEnd: event.eventEnd,
          }}
          summaryCount={event.summaryCount > 1 ? event.summaryCount : undefined}
          expanded={expanded}
          setExpanded={setExpanded}
          collapsible={
            event.callFrames.length > 0 && (
              <table
                className={cs(
                  styles['consoleCallFrames'],
                  isGhost && styles['ghost']
                )}
              >
                <tbody>
                  {event.callFrames.map((callFrame, idx) => (
                    <tr key={idx}>
                      <td>{callFrame.functionName}</td>
                      <td>@</td>
                      <td>{callFrame.path}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          }
          clipped={event.clipped}
          originalSize={event.originalSize}
          eventId={event.eventId}
          topSeparator={topSeparator}
          isGhost={isGhost}
        />
      )}
    </CellMeasurer>
  )
}

export const ConsolePanel: React.FC<DevToolHeaderProps> = () => {
  const {
    timer,
    events: { consoleLogs },
    helpers: {
      pin,
      setPinned,
      setHoveredSnapshots,
      clearHoveredSnapshots,
      clearPinned,
      isDragging,
    },
  } = useReplayContext()

  const { listContainerRef } = useSnapshotChangeContext()
  const [tabId, setTabId] = React.useState<TabId>(CONSOLE_OPTS[0].id)
  const [_filterText, setFilterText] = React.useState('')
  const filterText = useMemo(() => _filterText.toLowerCase(), [_filterText])
  const { clearParamIfCommandHidden } = useClearParamIfCommandHidden()

  const filterResults = useMemo(() => {
    let scrollToIdx = -1
    const itemsAfterFilters = consoleLogs.filter((event) => {
      let skip = false
      if (tabId && tabId !== 'all') {
        if (event.variant !== tabId) {
          skip = true
        }
      }
      if (filterText) {
        if (!event.lowerCaseMessage.includes(filterText)) {
          skip = true
        }
      }
      if (skip) {
        clearParamIfCommandHidden(event.id)
        return false
      }

      // this must happen AFTER all
      // conditions that return false:
      if (event.eventStart <= timer.time) {
        // Side-effect:
        // Whatever item last met this condition
        // is the latest item we need to scroll to:
        scrollToIdx += 1
      }
      return true
    })
    return {
      itemsAfterFilters,
      scrollToIdx,
    }
  }, [consoleLogs, filterText, tabId, timer.time, clearParamIfCommandHidden])
  const { itemsAfterFilters, scrollToIdx } = filterResults

  const { handleDownloadMessage } = useHandleDownloadMessage()

  const prevTabId = usePreviousValueOf(tabId)
  const prevFilterText = usePreviousValueOf(filterText)
  const filterTextChange = prevFilterText !== filterText
  const tabIdChanged = tabId !== prevTabId
  // helps wait to check if we should auto-scroll into
  // the Separator at the time that the List is mounted:
  const { nodeRef: listRef, mounted: isListMounted } =
    useTargetIsMounted<List>()

  const shouldScrollToEdge = useShouldScrollToEdge(
    timer.time,
    'console-logs',
    pin
  )
  const shouldScrollDown =
    !isListMounted || shouldScrollToEdge || filterTextChange || tabIdChanged

  if (filterTextChange || tabIdChanged) {
    cache.clearAll()
    expandedCache.clear()
  }

  const wasDragging = usePreviousValueOf(isDragging)
  useLayoutEffect(() => {
    if (!isDragging && wasDragging) {
      cache.clearAll()
      listRef.current?.recomputeRowHeights()
      listRef.current?.forceUpdateGrid()
    }
  }, [isDragging, wasDragging, filterTextChange, tabIdChanged, listRef])

  const hasResults = itemsAfterFilters.length > 0
  const hasFilters = filterText || tabId !== CONSOLE_OPTS[0].id
  const defaultEmpty = !hasResults && !hasFilters

  if (defaultEmpty) {
    return (
      <div
        data-cy="empty-console-panel"
        className={styles['emptyContainer']}
        id="console-tabpanel"
        role="tabpanel"
      >
        <ConsolePanelEmptyState />
      </div>
    )
  }

  return (
    <div
      data-cy={'console-panel'}
      className={styles['container']}
      id="console-tabpanel"
      role="tabpanel"
    >
      <div className={styles['console-filters']}>
        <TabsShim>
          <Tabs
            data-cy="console-filter-tabs"
            variant="dark-small"
            onSwitch={({ id }) => setTabId(id as TabId)}
            activeId={tabId}
            tabs={CONSOLE_OPTS.map((opt) => ({
              id: opt.id,
              label: opt.label,
              'aria-controls': `${opt.id}-tabpanel`,
            }))}
          />
        </TabsShim>
        <div className={styles['search-input-filter']}>
          <SearchInput
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            onReset={() => setFilterText('')}
            placeholder="Filter"
            data-cy="console-text-filter"
            data-pendo="replay-console-text-filter"
          />
        </div>
      </div>

      {hasResults ? (
        <div
          role="tabpanel"
          id={`${tabId}-tabpanel`}
          className={styles['list']}
          data-cy="console-item-list"
          ref={listContainerRef}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listRef}
                width={width}
                height={height}
                deferredMeasurementCache={cache}
                rowCount={itemsAfterFilters.length}
                // NOTE: Due to dynamic item heights, we shouldn't scroll
                // beyond the active item like we do on the Network panel.
                scrollToIndex={scrollToIdx}
                rowHeight={({ index }) => cache.rowHeight({ index }) + 12}
                rowRenderer={({ key, index, style, parent }) => {
                  const event = itemsAfterFilters[index] as ConsoleEvent
                  const expanded = expandedCache.get(index) || false
                  const setExpanded = (expanded: boolean) => {
                    expandedCache.set(index, expanded)
                  }

                  const topSeparator =
                    // if -1, then no item receives separator yet:
                    scrollToIdx === -1 ? false : index - 1 === scrollToIdx

                  return renderConsoleItem({
                    // react-virtualize:
                    key,
                    index,
                    style,
                    parent,
                    listRef,

                    // test-replay:
                    pin,
                    timer,
                    event,
                    setHoveredSnapshots,
                    clearHoveredSnapshots,
                    clearPinned,
                    setPinned,
                    setExpanded,
                    expanded,
                    handleDownloadMessage,
                    topSeparator,
                  })
                }}
                scrollToAlignment={shouldScrollDown ? 'center' : undefined}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <NoResultsFilter
          id={`${tabId}-tabpanel`}
          clearFilterText={() => {
            setTabId('all')
            setFilterText('')
          }}
        />
      )}
    </div>
  )
}
