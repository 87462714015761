import { useEffect, useRef, useState } from 'react'

/**
 * NOTE: meant to induce an extra render.
 *
 * Parent scope is responsible for rerendering when the node is mounted.
 * Then this hook triggers an additional render to sync up ui if needed.
 */
export function useTargetIsMounted<T>() {
  const [mounted, setMounted] = useState(false)
  const nodeRef = useRef<T | null>(null)
  const node = nodeRef.current

  // signal for a rerender once the
  // node is detected as being mounted:
  useEffect(() => {
    if (node) {
      setMounted(true)
    }
  }, [node])

  return {
    nodeRef,
    mounted,
  }
}
