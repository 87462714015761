import 'css-chunk:src/components/ReplayBody/DevTools/NetworkPanel/NetworkItem/NetworkItem.module.scss';export default {
  "leading": "_leading_yx5gc_7",
  "waterfallPill": "_waterfallPill_yx5gc_12",
  "waterfallPillPopper": "_waterfallPillPopper_yx5gc_18",
  "networkResponse": "_networkResponse_yx5gc_30",
  "networkResponseMethod": "_networkResponseMethod_yx5gc_37",
  "ghost": "_ghost_yx5gc_43",
  "networkResponsePath": "_networkResponsePath_yx5gc_46",
  "networkResponseIconContainer": "_networkResponseIconContainer_yx5gc_54",
  "networkResponsePathContainer": "_networkResponsePathContainer_yx5gc_58",
  "networkStatus": "_networkStatus_yx5gc_68",
  "networkIcon": "_networkIcon_yx5gc_71",
  "networkResponseCircle": "_networkResponseCircle_yx5gc_78",
  "networkResponseStatusCode": "_networkResponseStatusCode_yx5gc_87",
  "outOfBoundsTooltip": "_outOfBoundsTooltip_yx5gc_95",
  "divider": "_divider_yx5gc_103"
};