import React from 'react'
import { IconActionDelete } from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'
import styles from './NoResultsFilter.module.scss'

type NoResultsFilterProps = {
  clearFilterText: () => void
  id: string
}

export const NoResultsFilter = (props: NoResultsFilterProps) => {
  return (
    <div
      role="tabpanel"
      id={props.id}
      className={styles['container']}
      data-cy="no-result-text-filter"
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.6667 21.3324C34.6667 25.0143 33.1743 28.3476 30.7614 30.7604C28.3486 33.1733 25.0152 34.6657 21.3333 34.6657C13.9695 34.6657 8 28.6962 8 21.3324C8 13.9686 13.9695 7.99902 21.3333 7.99902C28.6971 7.99902 34.6667 13.9686 34.6667 21.3324Z"
          fill="#2E3247"
        />
        <path
          d="M30.7614 30.7604C33.1743 28.3476 34.6667 25.0143 34.6667 21.3324C34.6667 13.9686 28.6971 7.99902 21.3333 7.99902C13.9695 7.99902 8 13.9686 8 21.3324C8 28.6962 13.9695 34.6657 21.3333 34.6657C25.0152 34.6657 28.3486 33.1733 30.7614 30.7604ZM30.7614 30.7604L40 39.999M17.5 25.499L21.5 21.499M25.5 17.499L21.5 21.499M21.5 21.499L17.5 17.499M21.5 21.499L25.5 25.499"
          stroke="#E45770"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <span className={styles['noResults']}>
        No results found for your filters
      </span>

      <Button
        className={styles['clearFiltersBtn']}
        onClick={props.clearFilterText}
        variant="outline-dark"
        data-cy="no-result-text-clear-filter"
      >
        <IconActionDelete className="mr-4" strokeColor="indigo-300" />
        Clear filters
      </Button>
    </div>
  )
}
