import React, { useCallback, useEffect, useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import {
  ScrubberThumbRange,
  ScrubberThumb,
} from './ScrubberThumb/ScrubberThumb'
import styles from './Scrubber.module.scss'
import { useThrottledCallback } from 'use-debounce'

export type GhostRange = {
  startAt: number
  endAt: number
}
export type ScrubberProps = {
  min: number
  max: number
  value: number
  ghostValue?: number
  ghostRange?: GhostRange
  cliffAt?: number // NOTE: min <= cliffAt <= max
  onChange: (
    n: number,
    // optionally pass event object if necessary:
    event?: React.ChangeEventHandler<HTMLInputElement>
  ) => void
  setIsScrubbing?: (b: boolean) => void
  displayTime?: boolean
}

type ContainerNodeRef = React.MutableRefObject<HTMLDivElement | null>

function useContainerWidth(containerRef: ContainerNodeRef) {
  const dimensions = useResizeObserver({ ref: containerRef })
  return { containerWidth: dimensions.width ?? 0 }
}

function useMousePixelPosition(nodeRef: ContainerNodeRef) {
  const [x, setX] = useState<number | null>()

  useEffect(() => {
    function update(e: MouseEvent) {
      setX(
        e?.clientX - (e?.target as HTMLElement)?.getBoundingClientRect().left
      )
    }
    const { current } = nodeRef
    current?.addEventListener('mousemove', update)
    return () => current?.removeEventListener('mousemove', update)
  }, [setX, nodeRef])

  return { mousePxlPosition: x }
}

function stepPercentSize(min: number, max: number, current: number) {
  if (current === min) return 0
  const percentage = ((current - min) / (max - min)) * 100
  return Number(percentage.toFixed(2))
}

function cliffPercentSize(min: number, max: number, cliffAt: number) {
  return 100 - stepPercentSize(min, max, cliffAt)
}

function getGhostPosition({
  ghostValue,
  isMouseOver,
  mousePxlPosition,
  min,
  max,
}: {
  min: number
  max: number
  ghostValue?: number
  isMouseOver?: boolean
  mousePxlPosition?: number | null
}) {
  const ghostPercentPosition =
    typeof ghostValue === 'number' && min <= ghostValue && ghostValue <= max
      ? stepPercentSize(min, max, ghostValue)
      : null
  if (isMouseOver) {
    return `${mousePxlPosition}px`
  }
  if (typeof ghostPercentPosition === 'number') {
    return `${ghostPercentPosition}%`
  }
  return null
}

function getGhostRangePosition({
  ghostRange,
  min,
  max,
  containerWidth,
}: {
  ghostRange?: {
    startAt: number
    endAt: number
  }
  min: number
  max: number
  containerWidth: number
}) {
  if (!ghostRange) {
    return null
  }
  const start = getGhostPosition({
    ghostValue: ghostRange.startAt,
    min,
    max,
  })
  const end = getGhostPosition({
    ghostValue: ghostRange!.endAt,
    min,
    max,
  })
  // removes '%' from string and turns into Number:
  const extractNumber = (str: string | null) =>
    Number(str?.slice(0, str.length - 1))
  const percent = extractNumber(end) - extractNumber(start)
  return {
    leftOffset: start!,
    width: (containerWidth * percent) / 100,
  }
}

const ScrubberTrack: React.FC<{
  min: number
  max: number
  value: number
  cliffAt?: number
}> = ({ min, max, value, cliffAt }) => {
  const virtualValue = value < min ? min : value > max ? max : value
  // NOTE: the order of the "*-track" divs determines
  // their layering priority: so the first div is the
  // bottommost and the last div is the topmost layer
  return (
    <div className={styles['scrubber-tracks-container']}>
      <div
        data-cy="scrubber-passive-track"
        className={styles['passive-track']}
      />
      <div
        data-cy="scrubber-active-track"
        className={styles['active-track']}
        style={{ width: `${stepPercentSize(min, max, virtualValue)}%` }}
      />
      {cliffAt && (
        <div
          data-cy="scrubber-failed-track"
          className={styles['cliff-track']}
          style={{ width: `${cliffPercentSize(min, max, cliffAt)}%` }}
        />
      )}
    </div>
  )
}

const useThrottledExternals = (
  externalValue: number,
  externalOnChange: (n: number) => void
) => {
  const [privateValue, setPrivateValue] = useState<number>(externalValue)
  // keep local state in sync with external state:
  useEffect(() => setPrivateValue(externalValue), [externalValue])
  const throttleExternalOnChange = useThrottledCallback(externalOnChange, 350, {
    leading: true,
    trailing: true,
  })
  const localOnChange = useCallback(
    (val: number) => {
      throttleExternalOnChange(val)
      setPrivateValue(val)
    },
    [throttleExternalOnChange]
  )
  return {
    onChange: localOnChange,
    value: privateValue,
  }
}

export const Scrubber: React.FC<ScrubberProps> = ({
  min,
  max,
  cliffAt,
  ghostValue,
  ghostRange,
  setIsScrubbing,
  value: value_external,
  onChange: onChange_external,
  displayTime,
}) => {
  const { value, onChange } = useThrottledExternals(
    value_external,
    onChange_external
  )
  // axis used to calculate the location of the ghost thumb and
  // the relative position of the mouse within the input range:
  const containerRef: ContainerNodeRef = useRef(null)

  const [isPressed, setIsPressed] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const { mousePxlPosition } = useMousePixelPosition(containerRef)
  const { containerWidth } = useContainerWidth(containerRef)

  const ghostPosition = getGhostPosition({
    ghostValue,
    isMouseOver,
    mousePxlPosition,
    min,
    max,
  })

  const ghostRangePosition = getGhostRangePosition({
    containerWidth,
    ghostRange,
    min,
    max,
  })

  function sanitize(unsafeValue: string | number) {
    // Math.round() will enforce integer values
    // for all changes between the min and max:
    let nextValue = Math.floor(Number(unsafeValue))
    // then we force the value into one
    // extra tick in case the max is a float:
    if (max - nextValue < 1) {
      nextValue += 1
    }
    // then we ensure that we never go
    // past the min or max float numbers:
    return Math.max(Math.min(nextValue, max), min)
  }

  function handleMouse() {
    if (
      typeof mousePxlPosition === 'number' &&
      typeof containerWidth === 'number' &&
      containerWidth > 0
    ) {
      const percent = mousePxlPosition / containerWidth
      onChange(sanitize(min + (max - min) * percent))
    }
  }

  function handleKeyboard(e: React.FormEvent<HTMLInputElement>) {
    const eventValue = Number(e.currentTarget.value)
    const nextValue = value > eventValue ? value - 1 : value + 1
    onChange(sanitize(nextValue))
  }

  function handleInputChanged(e: React.FormEvent<HTMLInputElement>) {
    if (isPressed) {
      handleMouse()
    } else {
      handleKeyboard(e)
    }
  }

  return (
    <div
      ref={containerRef}
      data-cy="scrubber-container"
      data-pendo="replay-footer-scrubber"
      className={`${styles['scrubber-container']}`}
    >
      <input
        aria-label="Time"
        type="range"
        className={styles['scrubber-input']}
        onInput={handleInputChanged}
        onChange={() => null}
        onMouseEnter={() => {
          setIsMouseOver(true)
        }}
        onMouseLeave={() => {
          setIsMouseOver(false)
        }}
        onMouseDown={() => {
          setIsScrubbing?.(true)
          setIsPressed(true)
        }}
        onMouseUp={() => {
          setIsScrubbing?.(false)
          setIsPressed(false)
        }}
        value={value}
        step="any"
        min={min}
        max={max}
      />
      <div className={styles['input-track-layers']}>
        <ScrubberTrack cliffAt={cliffAt} value={value} min={min} max={max} />
        <ScrubberThumb
          data-cy="MainScrubber"
          leftOffset={`${stepPercentSize(min, max, value)}%`}
          time={displayTime ? { min, max, value } : undefined}
          variant={
            typeof cliffAt === 'number' && value >= cliffAt
              ? 'failing'
              : 'passing'
          }
        />
        {!isPressed && ghostPosition && (
          <ScrubberThumb
            data-cy="GhostScrubber"
            leftOffset={ghostPosition}
            lightMode={isMouseOver}
            variant="ghost"
          />
        )}
        {ghostRangePosition && (
          <ScrubberThumbRange
            leftOffset={ghostRangePosition.leftOffset}
            width={ghostRangePosition.width}
          />
        )}
      </div>
    </div>
  )
}
