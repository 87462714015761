import { useCallback, useEffect, useRef } from 'react'
import { useUrlContext } from '../components/Context/UrlContext'
import { useReplayContext } from '../components/Context/ReplayContext'

/**
 * Meant to remove URL search param when moving in between nested
 * panel tabs (like "all", "error", ""xhr", etc) if the new tab
 * does not contain  the previously pinned command:
 */
export const useClearParamIfCommandHidden = () => {
  const { setSearchParams } = useUrlContext()
  const {
    helpers: { pin },
  } = useReplayContext()

  // use ref to prevent recalculating callback and avoid
  // inducing unnecessary re-renders down the React tree:
  const pinIdRef = useRef<undefined | string>(undefined)
  useEffect(() => {
    pinIdRef.current = pin?.id
  })

  const clearParamIfCommandHidden = useCallback(
    (commandId: string) => {
      if (commandId === pinIdRef.current) {
        setSearchParams({
          pc: undefined,
        })
      }
    },
    [setSearchParams]
  )

  return {
    clearParamIfCommandHidden,
  }
}
