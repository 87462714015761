import { warnOnce } from './warnOnce'
import URL from 'url-parse'

export class HrefResolver {
  private frameToDocumentMap: Map<string, Document>

  constructor() {
    this.frameToDocumentMap = new Map()
    this.resolveCache = {}
  }

  /**
   * Given a document within the replay frame we return the frameId.
   * The top level frame uses 'parent-frame' as an id.
   *
   * @param documentInFrame A document within Replay
   * @returns
   */
  public getDocumentFrameId = (documentInFrame: Document | null): string => {
    if (!documentInFrame) {
      return 'parent-frame'
    }
    return (
      documentInFrame.defaultView?.frameElement?.getAttribute(
        'data-cy-replay-frame-id'
      ) ||
      documentInFrame.defaultView?.frameElement?.getAttribute(
        'data-cy-replay-pending-iframe'
      ) ||
      'parent-frame'
    )
  }

  private getOrCreateDocumentForFrame = (frameId: string) => {
    const hasMapping = this.frameToDocumentMap.get(frameId)
    if (hasMapping) {
      return hasMapping
    }
    const createdDocument = document.implementation.createHTMLDocument()
    const createdBaseTag = createdDocument.createElement('base')
    createdDocument.head.appendChild(createdBaseTag)
    this.frameToDocumentMap.set(frameId, createdDocument)
    this.resolveCache[frameId] = {}
    return createdDocument
  }

  /**
   * Sets the current location for a given frame. This will be used for future resolves.
   *
   * @param frameId Frame to update location
   * @param location New href to set as location
   * @param opts Options for setting location
   * @param opts.isNavigationEvent Whether this is a navigation event. This should be called when navigating to ensure
   * that the resolving cache is cleared. Otherwise, we'll get results for stale urls when resolving relative urls.
   */
  public setCurrentLocation = (
    frameId: string,
    location: string,
    opts: { isNavigationEvent: boolean } = { isNavigationEvent: false }
  ) => {
    const documentToUpdate = this.getOrCreateDocumentForFrame(frameId)
    try {
      const url = new URL(location)
      documentToUpdate
        ?.querySelector('base')!
        .setAttribute('href', `${url.origin}${url.pathname}`)
      if (opts.isNavigationEvent) {
        this.resolveCache[frameId] = {}
      }
    } catch (err) {
      warnOnce(
        `${frameId}-${location}`,
        `Failed to set current location to ${location} for frame ${frameId}`
      )
    }
  }

  /**
   * Returns the current location for a given frame
   *
   * @param frameId Frame to update location
   */
  public getFrameLocation = (frameId: string): string => {
    const documentToUpdate = this.getOrCreateDocumentForFrame(frameId)
    return documentToUpdate?.querySelector('base')?.getAttribute('href') || ''
  }

  /**
   * Resolves a given href value against the frame of the resolvingNode element.
   *
   * @param href Href to resolve
   * @param resolvingNode Document to resolve in
   * @returns
   */
  private resolveCache: Record<string, Record<string, string>>
  public resolve = (href: string, doc: Document | null): string => {
    if (href.startsWith('data:')) {
      return href
    }

    const frameIdForNode = this.getDocumentFrameId(doc)
    if (this.resolveCache?.[frameIdForNode]?.[href]) {
      return this.resolveCache[frameIdForNode]![href] as string
    }
    const documentToResolve = this.getOrCreateDocumentForFrame(frameIdForNode)
    const anchor = documentToResolve!.createElement('a')
    anchor.setAttribute('href', href)
    const resolvedHref = new URL(anchor.href)
    anchor.remove()

    let resolvedUrl: string
    // Non-HTTP protocols can resolve a "null" origin value that we don't want to use
    if (resolvedHref.origin && resolvedHref.origin !== 'null') {
      resolvedUrl = resolvedHref.origin
    } else if (resolvedHref.protocol) {
      // If no origin was built then attempt to reconstruct a valid URL string from the resolved protocol & host if they exist
      resolvedUrl = `${resolvedHref.protocol}//`
      if (resolvedHref.host) {
        resolvedUrl += resolvedHref.host
      }
    } else {
      // As a fallback, just move forward without protocol+host info since they couldn't be resolved
      resolvedUrl = ''
    }

    resolvedUrl += `${resolvedHref.pathname}${resolvedHref.query}`
    this.resolveCache[frameIdForNode]![href] = resolvedUrl
    return resolvedUrl
  }

  /**
   * Resolves the provided href against a provided location. The provided location will also be resolved against
   * the document of the resolving node.
   *
   * @param href
   * @param location
   * @param resolvingNode
   * @returns Resolved Href
   */
  public resolveAgainst = (
    href: string,
    location: string,
    resolvingDocument: Document
  ): string => {
    const frameIdForNode = this.getDocumentFrameId(resolvingDocument)

    const currentUrl = this.getOrCreateDocumentForFrame(frameIdForNode)!
      .querySelector('base')!
      .getAttribute('href')
    const locationUrl = this.resolve(location, resolvingDocument)

    this.setCurrentLocation(frameIdForNode, locationUrl)
    const resolvedUrl = this.resolve(href, resolvingDocument)
    this.setCurrentLocation(frameIdForNode, currentUrl || '')
    return resolvedUrl
  }
}
