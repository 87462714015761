// Taken originally from https://github.com/rrweb-io/rrweb/blob/master/packages/rrweb/src/utils.ts#L590
/**
 * Get the direct shadow host of a node in shadow dom. Returns null if it is not in a shadow dom.
 * @param n Node
 * @returns Element | null
 */
const getShadowHost = (n: Node): Element | null => {
  let shadowHost: Element | null = null
  if (
    n.getRootNode?.()?.nodeType === Node.DOCUMENT_FRAGMENT_NODE &&
    (n.getRootNode() as ShadowRoot).host
  )
    shadowHost = (n.getRootNode() as ShadowRoot).host
  return shadowHost
}

/**
 * Get the root shadow host of a node in nested shadow doms. Returns the node itself if it is not in a shadow dom.
 * @param n Node
 * @returns Node
 */
const getRootShadowHost = (n: Node): Node => {
  let rootShadowHost: Node = n

  let shadowHost: Element | null
  // If n is in a nested shadow dom.
  while ((shadowHost = getShadowHost(rootShadowHost)))
    rootShadowHost = shadowHost

  return rootShadowHost
}

/**
 * Returns true if the shadowHost is attached to the root document. Otherwise false.
 * @param n Node
 * @returns boolean
 */
const shadowHostInDom = (n: Node): boolean => {
  const doc = n.ownerDocument
  if (!doc) return false
  const shadowHost = getRootShadowHost(n)
  return doc.contains(shadowHost)
}

/**
 * Returns true if the Node is attached to the root document. Otherwise false.
 * @param n Node
 * @returns boolean
 */
export const inDom = (n: Node): boolean => {
  const doc = n.ownerDocument
  if (!doc) return false
  return doc.contains(n) || shadowHostInDom(n)
}
