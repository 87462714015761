import React, { useRef, useState, useEffect, useCallback } from 'react'
import styles from './ResizableWrapper.module.scss'
import cs from 'clsx'

type DragSide = 'right' | 'left'

export const usePanelSizeStorage = (side: DragSide, storageKeyRoot: string) => {
  const PANEL_KEY = `${storageKeyRoot}:${side}`
  const storedSizeStr = localStorage.getItem(PANEL_KEY)
  const update = useCallback(
    (width: number) => {
      localStorage.setItem(PANEL_KEY, String(width))
    },
    [PANEL_KEY]
  )
  return {
    baseSize: storedSizeStr ? Number(storedSizeStr) : null,
    updateBaseSize: update,
  }
}

const useResizableElement = (minWidth: number, drag: DragSide) => {
  const sidebarRef = useRef<HTMLDivElement>(null)
  const [sidebarWidth, setSidebarWidth] = useState(minWidth)

  const [isResizing, setResizing] = useState(false)
  const startResizing = useCallback(() => setResizing(true), [])
  const stopResizing = useCallback(() => setResizing(false), [])

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing && sidebarRef.current) {
        const rect = sidebarRef.current?.getBoundingClientRect()
        const diff =
          drag === 'right'
            ? mouseMoveEvent.clientX - (rect?.left ?? 0)
            : (rect?.right ?? 0) - mouseMoveEvent.clientX
        setSidebarWidth(diff)
      }
    },
    [drag, isResizing]
  )

  useEffect(() => {
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResizing)
    return () => {
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResizing)
    }
  }, [resize, stopResizing])

  return {
    isResizing,
    sidebarRef,
    startResizing,
    sidebarWidth,
  }
}

export const ResizableWrapper: React.FC<{
  min: number
  max: number
  initial: number
  drag: DragSide
  setIsDragging: (b: boolean) => void
  darkTheme?: boolean
  resizerClassName?: string
  sidebarClassName?: string
  storageKeyRoot?: string
}> = ({
  children,
  min,
  max,
  initial,
  drag,
  setIsDragging,
  darkTheme = true,
  resizerClassName,
  sidebarClassName,
  storageKeyRoot = 'test-replay:panel:size',
}) => {
  const { baseSize, updateBaseSize } = usePanelSizeStorage(
    // a panel name is opposite to its draggable side:
    drag === 'left' ? 'right' : 'left',
    storageKeyRoot
  )
  const { sidebarRef, startResizing, sidebarWidth, isResizing } =
    useResizableElement(baseSize ?? initial ?? min, drag)

  useEffect(() => {
    setIsDragging(isResizing)
  }, [isResizing, setIsDragging])

  useEffect(() => {
    updateBaseSize(sidebarWidth)
  }, [updateBaseSize, sidebarWidth])

  const resizer = (
    <div
      data-cy="resizable-sidebar-resizer"
      className={cs(
        styles['resizable-sidebar-resizer'],
        isResizing && styles['active'],
        styles[drag],
        resizerClassName
      )}
      onMouseDown={startResizing}
    />
  )

  return (
    <div
      ref={sidebarRef}
      style={{ width: Math.min(max, sidebarWidth), minWidth: min }}
      className={cs(
        styles['resizable-sidebar'],
        styles[darkTheme ? 'dark' : 'light'],
        sidebarClassName
      )}
    >
      {drag === 'left' && resizer}
      {children}
      {drag === 'right' && resizer}
    </div>
  )
}
