import 'css-chunk:src/components/ReplayBody/DevTools/DevtoolListItem/DevtoolListItem.module.scss';export default {
  "virtualized-item": "_virtualized-item_1gl4p_7",
  "devtools-item-separator": "_devtools-item-separator_1gl4p_11",
  "top": "_top_1gl4p_15",
  "bottom": "_bottom_1gl4p_18",
  "container": "_container_1gl4p_22",
  "focusBorder": "_focusBorder_1gl4p_32",
  "primaryBar": "_primaryBar_1gl4p_41",
  "leading": "_leading_1gl4p_48",
  "collapsible": "_collapsible_1gl4p_53",
  "collapsedContent": "_collapsedContent_1gl4p_58",
  "primaryContent": "_primaryContent_1gl4p_63",
  "children": "_children_1gl4p_70",
  "caret": "_caret_1gl4p_73",
  "hoverContainer": "_hoverContainer_1gl4p_82"
};