import React from 'react'
import { Scrubber } from './Scrubber/Scrubber'
import { useReplayActions, useReplayContext } from '../Context/ReplayContext'
import { TimerDisplay } from './TimerDisplay/TimerDisplay'
import { NextButton, PlayButton, PreviousButton } from './PlayButton/PlayButton'
import { AttemptPicker } from './AttemptPicker/AttemptPicker'
import { SpeedPicker } from './SpeedPicker/SpeedPicker'
import styles from './ReplayFooter.module.scss'

export const ReplayFooter: React.FC = () => {
  const {
    timer,
    scrubber: {
      ghostValueHovered,
      ghostRangeHovered,
      ghostRangePinned,
      onScrubberChange,
      setIsScrubbing,
    },
    helpers: { pin },
  } = useReplayContext()
  const { setSpeed } = useReplayActions()

  // If "ghostValueHovered" is defined, then we
  // are hovering over non-pinned items, hence we
  // only care about hover effects. Otherwise, if nothing
  // is being hovered, then we show any pin related ghosts:
  const visibleRange = ghostValueHovered ? ghostRangeHovered : ghostRangePinned

  // when items are pinned, or playing, we avoid the "snapshots cycling" animation:
  const hideGHostValue = (timer.playing || pin) && !!visibleRange
  const isPaused = !timer.playing
  const isSecondsLong = timer.max - timer.min >= 1000
  const displayTime = isPaused && isSecondsLong
  return (
    <div data-cy="ReplayFooter" className={styles['replay-footer-container']}>
      <AttemptPicker />
      <PreviousButton />
      <PlayButton />
      <NextButton />
      <Scrubber
        min={timer.min}
        max={timer.max}
        value={timer.time}
        cliffAt={timer.cliffAt}
        displayTime={displayTime}
        ghostValue={hideGHostValue ? undefined : ghostValueHovered}
        ghostRange={visibleRange}
        onChange={onScrubberChange}
        setIsScrubbing={setIsScrubbing}
      />
      <TimerDisplay min={timer.min} max={timer.max} time={timer.time} />
      <SpeedPicker setSpeed={setSpeed} />
    </div>
  )
}
