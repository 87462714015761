export class ElementIdToDelayedCallbacksArrayMap {
  private map: Map<number, (() => void)[]> = new Map()

  set(elementId: number, callback: () => void) {
    let callbacks = this.map.get(elementId)
    if (!callbacks) {
      callbacks = []
      this.map.set(elementId, callbacks)
    }

    callbacks.push(callback)
  }

  get(elementId: number) {
    return this.map.get(elementId)
  }

  forEach(callback: (elementId: number, callbacks: (() => void)[]) => void) {
    this.map.forEach((callbacks, elementId) => {
      callback(elementId, callbacks)
    })
  }

  delete(elementId: number) {
    this.map.delete(elementId)
  }
}
