import { warnOnce } from './Replay/warnOnce'

export function replayPrefix(field: string) {
  return `data-cy-replay-${field}`
}

const ALWAYS_REWRITE_NAME_MAP: Record<
  string,
  (v?: string, n?: string) => string
> = {
  // Iframes should always remove the src attribute as to prevent actual page loads.
  src: (_?: string, n?: string) =>
    n === 'IFRAME' ? replayPrefix('src') : 'src',
  // meta tags with <meta http-equiv = "refresh" content = "2; url = https://www.tutorialspoint.com" />
  // can redirect outside test replay
  content: (_?: string, n?: string) =>
    n === 'META' ? replayPrefix('content') : 'content',
  // html events
  autofocus: () => replayPrefix('autofocus'),
  oncanplay: () => replayPrefix('oncanplay'),
  oncanplaythrough: () => replayPrefix('oncanplaythrough'),
  ondurationchange: () => replayPrefix('ondurationchange'),
  onemptied: () => replayPrefix('onemptied'),
  onended: () => replayPrefix('onended'),
  onerror: () => replayPrefix('onerror'),
  onloadeddata: () => replayPrefix('onloadeddata'),
  onloadedmetadata: () => replayPrefix('onloadedmetadata'),
  onloadstart: () => replayPrefix('onloadstart'),
  onpause: () => replayPrefix('onpause'),
  onplay: () => replayPrefix('onplay'),
  onplaying: () => replayPrefix('onplaying'),
  onprogress: () => replayPrefix('onprogress'),
  onratechange: () => replayPrefix('onratechange'),
  onreadystatechange: () => replayPrefix('onreadystatechange'),
  onseeked: () => replayPrefix('onseeked'),
  onseeking: () => replayPrefix('onseeking'),
  onstalled: () => replayPrefix('onstalled'),
  onsuspend: () => replayPrefix('onsuspend'),
  ontimeupdate: () => replayPrefix('ontimeupdate'),
  onvolumechange: () => replayPrefix('onvolumechange'),
  onwaiting: () => replayPrefix('onwaiting'),
  onclick: () => replayPrefix('onclick'),
  ondblclick: () => replayPrefix('ondblclick'),
  ondrag: () => replayPrefix('ondrag'),
  ondragend: () => replayPrefix('ondragend'),
  ondragenter: () => replayPrefix('ondragenter'),
  ondragleave: () => replayPrefix('ondragleave'),
  ondragover: () => replayPrefix('ondragover'),
  ondragstart: () => replayPrefix('ondragstart'),
  ondrop: () => replayPrefix('ondrop'),
  onmousedown: () => replayPrefix('onmousedown'),
  onmousemove: () => replayPrefix('onmousemove'),
  onmouseout: () => replayPrefix('onmouseout'),
  onmouseover: () => replayPrefix('onmouseover'),
  onmouseup: () => replayPrefix('onmouseup'),
  onmousewheel: () => replayPrefix('onmousewheel'),
  onscroll: () => replayPrefix('onscroll'),
  onkeydown: () => replayPrefix('onkeydown'),
  onkeypress: () => replayPrefix('onkeypress'),
  onkeyup: () => replayPrefix('onkeyup'),
  onblur: () => replayPrefix('onblur'),
  onchange: () => replayPrefix('onchange'),
  oncontextmenu: () => replayPrefix('oncontextmenu'),
  onfocus: () => replayPrefix('onfocus'),
  onformchange: () => replayPrefix('onformchange'),
  onforminput: () => replayPrefix('onforminput'),
  oninput: () => replayPrefix('oninput'),
  oninvalid: () => replayPrefix('oninvalid'),
  onreset: () => replayPrefix('onreset'),
  onselect: () => replayPrefix('onselect'),
  onsubmit: () => replayPrefix('onsubmit'),
  onafterprint: () => replayPrefix('onafterprint'),
  onbeforeprint: () => replayPrefix('onbeforeprint'),
  onbeforeunload: () => replayPrefix('onbeforeunload'),
  onhaschange: () => replayPrefix('onhaschange'),
  onload: () => replayPrefix('onload'),
  onmessage: () => replayPrefix('onmessage'),
  onoffline: () => replayPrefix('onoffline'),
  onpagehide: () => replayPrefix('onpagehide'),
  onpageshow: () => replayPrefix('onpageshow'),
  onpopstate: () => replayPrefix('onpopstate'),
  onredo: () => replayPrefix('onredo'),
  onresize: () => replayPrefix('onresize'),
  onstorage: () => replayPrefix('onstorage'),
  onundo: () => replayPrefix('onundo'),
  onunload: () => replayPrefix('onunload'),
}

export const ATTRIBUTE_NAME_MAP: Record<
  string,
  (v?: string, n?: string) => string
> = {
  ...ALWAYS_REWRITE_NAME_MAP,
  src: (value?: string) =>
    value?.includes('data:') ? 'src' : replayPrefix('src'),
  srcset: (value?: string) =>
    value?.includes('data:') ? 'srcset' : replayPrefix('srcset'),
  href: (_, n) => (n === 'BASE' ? 'href' : replayPrefix('href')),
  allow: () => replayPrefix('allow'),
  rel: () => replayPrefix('rel'),
  data: (_?: string, n?: string) =>
    n === 'OBJECT' ? replayPrefix('data') : 'data',
  controls: (_?: string, n?: string) =>
    n === 'VIDEO' ? replayPrefix('controls') : 'controls',
  // srcdoc will rewrite the contents of the iframe over the modifications we make
  // so we need to rewrite the srcdoc attribute to prevent this
  srcdoc: () => replayPrefix('srcdoc'),
}

export const safelyApplyAttribute = (
  node: Element,
  name: string,
  value: string,
  isChildOfSvg?: boolean
): string | undefined => {
  try {
    const mapping =
      // @ts-ignore
      typeof window !== 'undefined' && window.IN_DISCOVERY
        ? ALWAYS_REWRITE_NAME_MAP[name]
        : ATTRIBUTE_NAME_MAP[name]

    if (isChildOfSvg) {
      // https://stackoverflow.com/a/55773909
      const isXLink = name.startsWith('xlink')

      const namespace = isXLink ? 'http://www.w3.org/1999/xlink' : null

      const attr = mapping
        ? mapping(value, node.localName?.toUpperCase())
        : name

      let final = value

      // remove the path and only
      // pick out the symbol's id:
      if (isXLink && value.includes('#')) {
        final = `#${value.split('#').pop()}`
      }

      node.setAttributeNS(namespace, attr, final)

      // if no mapping, apply a default mapping
      // in case we changed the original value:
      if (final !== value) {
        node.setAttribute(replayPrefix(name), value)
      }
      return attr
    }
    const updatedAttrName = mapping
      ? mapping(value, node.localName?.toUpperCase())
      : name
    node.setAttribute(updatedAttrName, value)
    return updatedAttrName
  } catch (err) {
    warnOnce(
      `${name}_${value}`,
      `Error trying to add invalid attribute [${name}] to Element`
    )
    return
  }
}

/**
 * Updates all interactive elements to use our prefixed
 * data-cy-replay-X attribute
 * @param node node to clean
 */
export const updateInteractiveAttributes = (node: Element) => {
  Object.keys(ATTRIBUTE_NAME_MAP).forEach((attributeName: string) => {
    const attrValue = node.getAttribute(attributeName)
    if (attrValue) {
      const updatedAttr = safelyApplyAttribute(node, attributeName, attrValue)
      if (updatedAttr !== attributeName) {
        node.removeAttribute(attributeName)
      }
    }
  })
}
