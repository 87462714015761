import React from 'react'
import styles from './Timeline.module.scss'
import parentStyles from '../AttemptPicker.module.scss'
import cs from 'clsx'

/**
 * These timelines are only meant as a small illustration of the attempts-picker.
 * They do not need to be a hyper accurate representation of the proportions.
 * Hence it should be safe to Math.round() to get integers for the styles.
 * And so we have minimum sizes for aspects of these styles.
 */
export const Timeline: React.FC<{
  endAt: number
  startAt: number
  cliffAt?: number
  longestTime: number
}> = ({ longestTime, startAt, endAt, cliffAt }) => {
  // avoid floating point issues when doing arithmetic:
  const selfLength = Math.round(endAt) - Math.round(startAt)
  const longLength = Math.round(longestTime)

  const mainPercent = Math.round((selfLength / longLength) * 100)
  const failurePercent =
    typeof cliffAt === 'number'
      ? 100 - Math.round((cliffAt / selfLength) * 100)
      : undefined

  return (
    <div className={styles['timeline-container']}>
      <div
        data-cy="main-line"
        className={`${styles['main-line']}`}
        style={{ width: `max(${mainPercent}%, 10px)` }}
      >
        <div className={cs(styles['base-time'], parentStyles['base-time'])} />
        {typeof failurePercent === 'number' && (
          <div
            className={styles['failure-time']}
            style={{
              width: `max(${failurePercent}%, 4px)`,
              borderLeft: failurePercent < 100 ? '1px solid white' : '',
            }}
          />
        )}
      </div>
    </div>
  )
}
