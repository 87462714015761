import 'css-chunk:src/components/ReplayBody/DevTools/NetworkPanel/NetworkItemDetails/NetworkItemDetails.module.scss';export default {
  "container": "_container_1bxr9_7",
  "header": "_header_1bxr9_12",
  "networkItem": "_networkItem_1bxr9_20",
  "body": "_body_1bxr9_24",
  "requestInfo": "_requestInfo_1bxr9_29",
  "requestInfoValue": "_requestInfoValue_1bxr9_37",
  "requestInfoValueTitle": "_requestInfoValueTitle_1bxr9_43",
  "dotDivider": "_dotDivider_1bxr9_46",
  "tabs": "_tabs_1bxr9_53",
  "content": "_content_1bxr9_81",
  "contentHeader": "_contentHeader_1bxr9_85",
  "contentBody": "_contentBody_1bxr9_93",
  "collapsingDiv": "_collapsingDiv_1bxr9_97",
  "collapsingDivTitleIcon": "_collapsingDivTitleIcon_1bxr9_103",
  "collapsingDivTitle": "_collapsingDivTitle_1bxr9_103",
  "collapsingDivContent": "_collapsingDivContent_1bxr9_115",
  "collapsingDivCodeContent": "_collapsingDivCodeContent_1bxr9_118",
  "requestContent": "_requestContent_1bxr9_126",
  "requestContentKey": "_requestContentKey_1bxr9_126",
  "requestContentNetworkStatus": "_requestContentNetworkStatus_1bxr9_132",
  "requestContentValue": "_requestContentValue_1bxr9_135",
  "tooltipContainer": "_tooltipContainer_1bxr9_142",
  "alert-info": "_alert-info_1bxr9_151",
  "alert-danger": "_alert-danger_1bxr9_161",
  "learn-more": "_learn-more_1bxr9_169"
};