export * from './auth'
import type * as dbt from './db'
import type * as dbtInsert from './dbInsert'
import type * as dbtSnake from './db-snake.gen'
import type * as gqlTypes from './allNexusTypes.gen'
import type { DBTables } from './db-tables.gen'
import type { FilteredKeys } from './types'
export * from './db-type-utils'
export * from './db-enum-vals'
export * from './common'
export * from './types'
export * from './clickhouse'
export * from './db-tables.gen'
export * from './Auth0Types'
export * from './queueTypes'
export * from './scoreTracking'
export {
  DBTablesSnake,
  DBTableNameSnake,
  DBTableNamesSnake,
} from './db-tables-snake.gen'
export type { dbt, dbtInsert, gqlTypes, dbtSnake }

declare global {
  interface ObjectConstructor {
    keys<O extends object>(obj: O): Extract<keyof O, string>[]
  }
}

export type DBTablesTs = Pick<
  DBTables,
  FilteredKeys<DBTables, { createdAt: any; updatedAt: any }>
>

// Where the user value was loaded from. This is necessary to know whether we're
// supposed to save a session for the user or not... we don't for any of the old
// auth scenarios.
export type UserSource = 'session' | 'legacy' | 'jwt' | 'userKey'

export type LoginAuthSource = 'test_runner' | 'dashboard'
export type LoginProvider =
  | 'waad'
  | 'local'
  | 'github'
  | 'google'
  | 'saml'
  | 'cloned'

/**
 * A type-safe pattern for flattening out return
 * values from async/await:
 *
 * When we have an error, return { error: Error }
 * When we don't, return { value: V }
 */
export type MaybeAsyncErr<V> = Promise<
  { error: Error; value?: undefined } | { error?: undefined; value: V }
>
