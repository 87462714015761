import { useEffect } from 'react'
import { runnablesStore } from '~/submodules'
import type { AttemptHookContext } from '../useAttemptModel'
import type { GroupedLogs } from './useGroupedCommands'

/**
 * mutates data fed into the Reporter so it has
 * the effect of adding "stub" calls over time:
 */
export const useStubsEffect = (
  agentGroup: GroupedLogs['agent'],
  ctx: AttemptHookContext
) => {
  useEffect(() => {
    if (!agentGroup) {
      return
    }
    for (const [, data] of agentGroup) {
      if (data.events.length < 1) {
        continue
      }
      // @ts-ignore: fix lastEvent type:
      const lastEvent = data.events.at(-1)
      const totalCalls = lastEvent?.payload?.callCount ?? '?'
      if (totalCalls === 0) {
        // allow the Reporter to naturally handle,
        // do not highjack if nothing happened.
        continue
      }
      const isActiveStub =
        data.events[0] && data.events[0].timestamp <= ctx.timer.time
      if (isActiveStub) {
        for (const e of data.events) {
          if (e.timestamp <= ctx.timer.time) {
            const info = e.payload
            runnablesStore.updateLog({
              ...info,
              // @ts-ignore: we want to pass a
              // formatted string for TestReplay:
              callCount: `${info.callCount}/${totalCalls}`,
            })
          }
        }
      } else {
        // push update that represents
        // the empty 0/totalCalls state:
        runnablesStore.updateLog({
          // @ts-ignore: fix lastEvent type:
          ...lastEvent.payload,
          // @ts-ignore: we want to pass a
          // formatted string for TestReplay:
          callCount: `0/${totalCalls}`,
        })
      }
    }
  }, [agentGroup, ctx.timer.time])
}
