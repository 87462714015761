import type {
  AllPossibleStatuses,
  RunGroupStatusesValue,
} from '@packages/common/src'
import type { dbt } from '.'
import type { recordService } from '@packages/validations'

export type ClaimRecordingServiceInstanceJobData = {
  keyPath: string
  instanceId: string
  attemptNumber?: number
}

export type CreateRecordingServiceTestsJobData = {
  keyPath: string
  instanceId: string
  attemptNumber?: number
}

export type UpdateRecordingServiceInstanceJobData = {
  keyPath: string
  instanceId: string
  attemptNumber?: number
}

export type UpdateRecordingServiceInstanceV2JobData = {
  keyPath: string
  instanceId: string
  attemptNumber?: number
}

export type CompleteRecordingServiceRunJobData = {
  runsToComplete: string[] // UUID[]
  cancelledRunId?: string
  attemptNumber?: number
  isCancelBehaviorV2?: boolean
}

export type CreateRecordingServiceRunJobData = {
  keyPath: string
  runId: string
  isRunLeader: boolean
  attemptNumber?: number
}

export type UpdateRecordingServiceInstanceStdoutJobData = {
  keyPath: string
  instanceId: string
  attemptNumber?: number
}

export type RecordingServiceQueueType =
  | 'createRecordingServiceRun'
  | 'claimRecordingServiceInstance'
  | 'createRecordingServiceTests'
  | 'updateRecordingServiceInstance'
  | 'updateRecordingServiceInstanceV2'
  | 'updateRecordingServiceInstanceStdout'
  | 'completeRecordingServiceRun'

export type FailedRecordingJobData = {
  type: RecordingServiceQueueType
  data: object
}

export type SlackMessageRunJobData = {
  orgId: number
  buildId: number
  projectId: number
  projectSlug: string
} & SetFinalizeDiscoveryResultsCommentData

export interface SlackMessageTestOrchestrationMuteJobData {
  testOrchestrationId: number
  action: 'MUTED' | 'UNMUTED'
  actionUrl: string
}

export interface SlackTokensRevokedEvent {
  type: string
  tokens: { oauth: string[]; bot: string[] }
}

export interface SlackTokensRevokedJobData {
  team_id: string
  event: SlackTokensRevokedEvent
}

export interface SlackAppUninstalledJobData {
  team_id: string
}

export interface SlackAppLink {
  domain: string
  url: string
}

export interface SlackAppAuthorization {
  enterprise_id: string
  is_bot: boolean
  is_enterprise_install: boolean
  team_id: string
  user_id: string
}

export interface SlackAppLinkUnfurlEvent {
  channel: string
  message_ts: string
  links: SlackAppLink[]
}

export interface SlackAppLinkUnfurlJobData {
  api_app_id: string
  authorizations: SlackAppAuthorization[]
  event: SlackAppLinkUnfurlEvent
  event_context: string
  event_id: string
  event_time: number
  is_ext_shared_channel: boolean
  team_id: string
  token: string
  type: string
}

export interface GetJiraProjectsJobData {
  clientKey: string
  sharedSecret: string
  baseUrl: string
  installationId: number
  attemptNumber?: number
}

export type SetPullRequestCommentData = {
  commitSha: string
  projectId: number
  runUuid: string
  runId: number
  commitMessage: string | null
  retried?: boolean
} & SetFinalizeDiscoveryResultsCommentData

export type SetFinalizeDiscoveryResultsCommentData = {
  accessibilityResults?: recordService.PostFinalizeAccessibilityResults
  uiCoverageResults?: recordService.PostFinalizeUICoverageResults
}

export type SetGitLabPullRequestCommentData = SetPullRequestCommentData & {
  isEnterprise: boolean
} & SetFinalizeDiscoveryResultsCommentData

export type SetBitbucketPullRequestCommentData = SetPullRequestCommentData & {
  commitBranch: string
} & SetFinalizeDiscoveryResultsCommentData

export type SetFinalizeA11yResults =
  recordService.PostFinalizeAccessibilityResults & {
    runNumber: number
  }

export type SetFinalizeUICovResults =
  recordService.PostFinalizeUICoverageResults & {
    runNumber: number
  }

export type StatusCheckRunGroupInfo = {
  runGroupStatus: RunGroupStatusesValue | 'pending'
  runGroupName: string | null
  runGroupTotalPasses: number | null
  runGroupTotalFailures: number | null
  runGroupDuration: number | null
}

export type SetCommitStatusData = {
  buildId: number
  projectId: number
  githubAppInstallId: number
  repositoryId: string
  instanceInfo?: StatusCheckInstanceInfo
  instancesInfo?: StatusCheckInstanceInfo[]
  groupInfo?: StatusCheckRunGroupInfo
  groupsInfo?: StatusCheckRunGroupInfo[]
}

export type StatusCheckInstanceInfo = {
  spec: string
  groupName: string | null
  groupId: string
  passes: number | null
  failures: number | null
  status: AllPossibleStatuses
  wallClockDuration: number | null
}

export type SetFlakyTestsCommitStatusCheckData = {
  buildId: number
  projectId: number
}

export type SetBitbucketCommitStatusData = {
  buildId: number
  projectId: number
}

export type SetGitlabCommitStatusData = {
  buildId: number
  projectId: number
  retried?: boolean
  isEnterprise: boolean
}

export type MsTeamsRunJobData = {
  orgId: number
  buildId: number
  projectId: number
}

export type ProtocolDataProcessedData = {
  instanceProtocol: dbt.ProtocolInstanceRaw
  hashData?: Pick<
    dbt.ProtocolHashData,
    'bucket' | 'key' | 'fileSize' | 'hash'
  >[]
  testMapping?: Pick<
    dbt.ProtocolTestData,
    'bucket' | 'key' | 'fileSize' | 'testId'
  >[]
}

export type ProtocolDataUploadedData = {
  Records: {
    s3: {
      object: {
        key: string
        size: number
      }
    }
  }[]
}

export type ProtocolProcessInstanceData = {
  instanceDetails: dbt.ProtocolInstanceRaw
  testResultMappings: {
    runnableId: string | null
    testId: number
    testUuid: string
  }[]
  readableIds: {
    buildUuid: string
    instanceUuid: string
    projectSlug: string
  }
}
