import React from 'react'
import {
  IconObjectPinModern,
  IconActionDelete,
} from '@cypress-design/react-icon'
import styles from './AutControls.module.scss'
import cs from 'clsx'

type SnapshotButton = {
  name?: string
  active: boolean
  onClick: () => void
}

type HighlightProps = {
  active: boolean
  toggle: () => void
}

/**
 * NOTE:
 * Custom Switch component while we figure out how
 * to import the styles from dashboard's <Switch />
 */
const CustomSwitch: React.FC<{
  active: boolean
  toggle: () => void
}> = ({ active, toggle }) => (
  <button
    role="switch"
    onClick={toggle}
    className={cs(styles['custom-switch'], active && styles['active'])}
    data-cy="custom-switch-button"
  >
    <div className={styles['lever']} />
  </button>
)

const Highlights: React.FC<HighlightProps> = ({ active, toggle }) => (
  <div data-cy="highlights-control" className={styles['highlights-controls']}>
    <CustomSwitch active={active} toggle={toggle} />
    Highlights
  </div>
)

const CloseButton: React.FC<{
  onClose: () => void
}> = ({ onClose }) => (
  <button
    onClick={onClose}
    data-cy="aut-close-button"
    className={styles['close-button']}
  >
    <IconActionDelete strokeColor="gray-100" />
  </button>
)

const SnapshotButtons: React.FC<{
  snapshots: SnapshotButton[]
}> = ({ snapshots }) => (
  <div className={styles['button-list']} data-cy="snapshots-list">
    {snapshots.map((el, idx) => (
      <button
        key={idx}
        type="button"
        onClick={el.onClick}
        className={cs(el.active && styles['active'])}
        data-cy={`snapshot-button-${idx + 1}`}
      >
        {el.name ?? idx + 1}
      </button>
    ))}
  </div>
)

const Leading: React.FC<{ title: string }> = ({ title }) => (
  <div className={styles['leading']}>
    <IconObjectPinModern fillColor="purple-700" strokeColor="purple-400" />
    <div data-cy="aut-controls-title" className={styles['title']}>
      {title}
    </div>
  </div>
)

export type AutControlsProps = {
  title: string
  snapshots?: SnapshotButton[]
  highlight?: HighlightProps
  onClose?: () => void
}

export const AutControls: React.FC<AutControlsProps> = ({
  title,
  snapshots,
  highlight,
  onClose,
}) => {
  return (
    <div
      data-cy="aut-controls-container"
      className={styles['aut-controls-container']}
    >
      <Leading title={title} />
      {!!snapshots?.length && <SnapshotButtons snapshots={snapshots} />}
      {highlight && <Highlights {...highlight} />}
      {onClose && <CloseButton onClose={onClose} />}
    </div>
  )
}
