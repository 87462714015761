import type React from 'react'
import type { CypressAppEvent } from '../webworkers/database.worker'
import type { EventsPayload as EventsPayloadV7 } from '@packages/app-capture-protocol/src/db/schemas/v7'
import type { EventsPayload as EventsPayloadV9 } from '@packages/app-capture-protocol/src/db/schemas/v9'
import type {
  EventsPayload,
  Node,
} from '@packages/app-capture-protocol/src/db/schemas/latest'
import type { HrefResolver } from './Replay/hrefResolver'
import type { NodeMap } from './Replay/nodeMap'
import type { Base64AssetMetadata } from './toBase64Asset'
import { ATTRIBUTE_NAME_MAP } from './safelyApplyAttribute'
import { createNode } from './createNode'
import type { NetworkResources } from './useDomReplay'
import { warnOnce } from './Replay/warnOnce'
import {
  applyCSSAdoptedStyleSheets,
  applyCSSReplaced,
  applyCSSRuleDeleted,
  applyCSSRuleInserted,
  applyCSSStyleDeclarationPropertyRemoved,
  applyCSSStyleDeclarationPropertySet,
  formatStyleSheetText,
} from './Events/cssEvents'
import { applyElementBlurred, applyElementFocused } from './Events/focusEvents'
import { getElementFromPayload } from './getElementFromPayload'
import {
  applyAttributeModification,
  applyAttributeRemoval,
  applyCharacterDataModification,
  applyNodeAddition,
  applyNodeRemoval,
} from './Events/domMutationEvents'
import { PendingAdditionQueue } from './Events/PendingAdditionQueue'

export type ApplyEventContext = {
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
  nodeMap: React.MutableRefObject<NodeMap>
  hrefResolver: React.MutableRefObject<HrefResolver>
  networkResources: NetworkResources
  config?: {
    // Include the shadow dom when applying events. Defaults to false.
    includeShadowDom?: boolean
  }
  nodePatches?: {
    [key: string]: Partial<Node>
  }
  base64AssetMetadata?: Base64AssetMetadata
}

export const applyEventToReplay = (
  event: CypressAppEvent,
  ctx: ApplyEventContext
) => {
  switch (event.type) {
    case 'dom:full-snapshot': {
      ctx.nodeMap.current.reset()
      createNode({
        eventId: event.id ?? -1,
        doc: ctx.iframeRef.current!.contentDocument!,
        // @ts-ignore
        node: (event.payload as EventsPayload['cdp']['dom:full-snapshot']).node,
        networkResources: ctx.networkResources,
        nodeMap: ctx.nodeMap.current,
        hrefResolver: ctx.hrefResolver.current,
        config: ctx.config,
        nodePatches: ctx.nodePatches,
        base64AssetMetadata: ctx.base64AssetMetadata,
      })
      break
    }

    case 'dom:child-iframe-full-snapshot': {
      const frameFullSnapshotPayload =
        event.payload as EventsPayload['cdp']['dom:child-iframe-full-snapshot']
      let doc = ctx.nodeMap.current.getFrameDoc(
        frameFullSnapshotPayload.frameId
      )

      // If we have a frame, but it no longer has a defaultView (or inner window), then
      // that means the frame has been removed from the main window fairly
      // quickly after addition. There is no reason to process this event in this case.
      // // https://github.com/cypress-io/cypress-services/issues/9042
      if (doc && !doc.defaultView) {
        return
      }

      // If we don't have a doc yet, that mean's the parent is not aware of the iframe yet. In this case
      // create a temporary iframe in the main document and move the contents of it to the iframe
      // when the parent becomes aware of it.
      if (!doc) {
        const iframe =
          ctx.iframeRef.current!.contentDocument!.createElement('iframe')
        iframe.style.display = 'none'
        iframe.setAttribute(
          'data-cy-replay-pending-iframe',
          frameFullSnapshotPayload.frameId
        )
        ctx.nodeMap.current.addPendingIFrame(frameFullSnapshotPayload.frameId, {
          iframe,
          contentDocumentNode: frameFullSnapshotPayload.node,
        })
        ctx.nodeMap.current.addFrame(
          iframe.contentDocument!,
          frameFullSnapshotPayload.frameId
        )
        document.documentElement.appendChild(iframe)
        doc = iframe.contentDocument!
      }
      createNode({
        eventId: event.id ?? -1,
        doc,
        node: frameFullSnapshotPayload.node,
        networkResources: ctx.networkResources,
        nodeMap: ctx.nodeMap.current,
        hrefResolver: ctx.hrefResolver.current,
        config: ctx.config,
      })

      break
    }
    case 'dom:aggregate-mutation': {
      const aggregateMutationPayload =
        event.payload as EventsPayload['cdp']['dom:aggregate-mutation']
      // These callbacks are used to queue up the addition of nodes to the live DOM
      // or to queue up nodes that are not ready to be added to the DOM.
      // We defer these for performance reasons so that we add entire trees
      // rather than one node at a time.
      const pendingAdditionQueue: PendingAdditionQueue =
        new PendingAdditionQueue()
      aggregateMutationPayload.removals.forEach((removal) => {
        applyNodeRemoval({
          ctx,
          removalPayload: removal,
        })
      })
      aggregateMutationPayload.additions.forEach((addition) => {
        applyNodeAddition({
          eventId: event.id ?? -1,
          ctx,
          additionPayload: addition,
          pendingAdditionQueue,
        })
      })
      aggregateMutationPayload.attributeModifications.forEach(
        (modification) => {
          applyAttributeModification({
            eventId: event.id ?? -1,
            ctx,
            attributeModificationPayload: modification,
          })
        }
      )
      aggregateMutationPayload.attributeRemovals.forEach((removal) => {
        applyAttributeRemoval({
          ctx,
          attributeRemovalPayload: removal,
        })
      })
      aggregateMutationPayload.characterDataModifications.forEach(
        (modification) => {
          applyCharacterDataModification({
            ctx,
            characterDataPayload: modification,
          })
        }
      )
      pendingAdditionQueue.flush()

      // If we had a situation where we were waiting on a document element to be created
      // (e.g. pending iframe contents swapping)
      ctx.nodeMap.current.documentElementCallbackMap.forEach(
        (callback, key) => {
          const shouldDelete = callback()
          // Only delete the callback if it we could actually run it with a document element
          if (shouldDelete) {
            ctx.nodeMap.current.documentElementCallbackMap.delete(key)
          }
        }
      )

      break
    }

    // This event has not been used since version 9 of protocol. It is aggregated into the aggregate-mutation event.
    // Keeping this for backwards compatibility.
    case 'dom:node-removed': {
      const removal =
        event.payload as EventsPayloadV9['cdp']['dom:node-removed']
      applyNodeRemoval({
        ctx,
        removalPayload: removal,
      })
      break
    }

    // This event has not been used since version 9 of protocol. It is aggregated into the aggregate-mutation event.
    // Keeping this for backwards compatibility.
    case 'dom:node-added': {
      const addition = event.payload as EventsPayloadV9['cdp']['dom:node-added']
      // These callbacks are used to queue up the addition of nodes to the live DOM
      // or to queue up nodes that are not ready to be added to the DOM.
      // We defer these for performance reasons so that we add entire trees
      // rather than one node at a time.
      const pendingAdditionQueue: PendingAdditionQueue =
        new PendingAdditionQueue()
      applyNodeAddition({
        eventId: event.id ?? -1,
        ctx,
        additionPayload: addition,
        pendingAdditionQueue,
      })
      pendingAdditionQueue.flush()

      break
    }

    // This event has not been used since version 9 of protocol. It is aggregated into the aggregate-mutation event.
    // Keeping this for backwards compatibility.
    case 'dom:attribute-modified': {
      const modification =
        event.payload as EventsPayloadV9['cdp']['dom:attribute-modified']
      applyAttributeModification({
        eventId: event.id ?? -1,
        ctx,
        attributeModificationPayload: modification,
      })
      break
    }

    // This event has not been used since version 9 of protocol. It is aggregated into the aggregate-mutation event.
    // Keeping this for backwards compatibility.
    case 'dom:attribute-removed': {
      const removal =
        event.payload as EventsPayloadV9['cdp']['dom:attribute-removed']
      applyAttributeRemoval({
        ctx,
        attributeRemovalPayload: removal,
      })
      break
    }

    // This event has not been used since version 9 of protocol. It is aggregated into the aggregate-mutation event.
    // Keeping this for backwards compatibility.
    case 'dom:character-data-modified': {
      const characterDataPayload =
        event.payload as EventsPayloadV9['cdp']['dom:character-data-modified']
      applyCharacterDataModification({
        ctx,
        characterDataPayload,
      })
      break
    }

    case 'dom:select-input-changed': {
      const inputChangePayload =
        event.payload as EventsPayload['cdp']['dom:select-input-changed']
      const inputRef = getElementFromPayload(
        ctx.nodeMap.current,
        inputChangePayload
      ) as HTMLSelectElement
      if (inputRef) {
        const opts = inputRef.querySelectorAll('option')
        opts.forEach((optionNode) => {
          if (inputChangePayload.values.includes(optionNode.value)) {
            optionNode.selected = true
          } else {
            // in the case of multiselect, the option may have previously been selected
            // so we need to set selected to false.
            optionNode.selected = false
          }
        })
      }

      break
    }

    case 'dom:text-input-changed': {
      const inputChangePayload =
        event.payload as EventsPayload['cdp']['dom:text-input-changed']
      const inputRef = getElementFromPayload(
        ctx.nodeMap.current,
        inputChangePayload
      ) as HTMLInputElement
      if (inputRef && inputRef.type !== 'file') {
        inputRef.value = inputChangePayload.value
      }

      break
    }

    case 'dom:radio-input-changed':
    case 'dom:checkbox-input-changed': {
      const radioInputPayload = event.payload as
        | EventsPayload['cdp']['dom:radio-input-changed']
        | EventsPayload['cdp']['dom:checkbox-input-changed']
      const inputRef = getElementFromPayload(
        ctx.nodeMap.current,
        radioInputPayload
      ) as HTMLInputElement
      if (inputRef) {
        inputRef.checked = radioInputPayload.checked
      }

      break
    }

    case 'dom:window-scrolled': {
      const windowScrolledPayload =
        event.payload as EventsPayload['cdp']['dom:window-scrolled']
      const frameDocToScroll = ctx.nodeMap.current.getFrameDoc(
        windowScrolledPayload.frameId
      )?.defaultView
      if (frameDocToScroll) {
        frameDocToScroll.scroll(
          windowScrolledPayload.scrollX,
          windowScrolledPayload.scrollY
        )
      }

      break
    }

    case 'dom:element-scrolled': {
      const elementScrolledPayload =
        event.payload as EventsPayload['cdp']['dom:element-scrolled']
      if (ctx.iframeRef.current?.contentDocument) {
        const scrolledElement = getElementFromPayload(
          ctx.nodeMap.current,
          elementScrolledPayload
        ) as HTMLElement
        if (scrolledElement) {
          scrolledElement.scrollLeft = elementScrolledPayload.scrollX
          scrolledElement.scrollTop = elementScrolledPayload.scrollY
        }
      }

      break
    }

    case 'dom:style-sheet-added': {
      const stylesheetEvent =
        event.payload as EventsPayloadV7['cdp']['dom:style-sheet-added']
      const ownerNode = ctx.nodeMap.current.getNode(
        stylesheetEvent.ownerNode
      ) as HTMLStyleElement
      let styleSheetText = stylesheetEvent.styleSheetText || ''
      if (styleSheetText) {
        let possibleStylesheetHref

        const attributeNameMapHref = ATTRIBUTE_NAME_MAP['href']
        if (attributeNameMapHref) {
          possibleStylesheetHref = ownerNode?.getAttribute(
            attributeNameMapHref()
          )
        }

        styleSheetText = formatStyleSheetText({
          ctx,
          id: event.id ?? -1,
          styleSheetId: stylesheetEvent.styleSheetId,
          styleSheetText,
          styleSheetHref:
            possibleStylesheetHref ||
            ctx.hrefResolver.current.getFrameLocation(
              stylesheetEvent.frameId
            ) ||
            '',
          base64AssetMetadata: ctx.base64AssetMetadata,
        })
      }

      if (ownerNode) {
        ctx.nodeMap.current.addStyleSheet(
          stylesheetEvent.styleSheetId,
          ownerNode as HTMLStyleElement
        )
        if (styleSheetText && !stylesheetEvent.cdpRetrievalFailed) {
          ownerNode.textContent = styleSheetText
        }
      } else {
        ctx.nodeMap.current.createAdoptedStylesheet(
          stylesheetEvent.styleSheetId,
          stylesheetEvent.frameId,
          styleSheetText
        )
      }

      break
    }

    case 'dom:style-sheet-changed': {
      const stylesheetEvent =
        event.payload as EventsPayloadV7['cdp']['dom:style-sheet-changed']
      const existingStyleSheet = ctx.nodeMap.current.getStyleSheet(
        stylesheetEvent.styleSheetId
      )
      const styleSheetFrame = ctx.nodeMap.current.getAdoptedStyleSheetFrame(
        stylesheetEvent.styleSheetId
      )

      let styleSheetText = stylesheetEvent.styleSheetText || ''
      if (stylesheetEvent.cdpRetrievalFailed) {
        warnOnce(
          `${event.id}`,
          'cdpRetrievalFailed for stylesheet',
          stylesheetEvent
        )
        return
      }

      if (styleSheetText) {
        let possibleStylesheetHref

        const attributeNameMapHref = ATTRIBUTE_NAME_MAP['href']
        if (attributeNameMapHref) {
          possibleStylesheetHref = existingStyleSheet?.getAttribute(
            attributeNameMapHref()
          )
        }

        styleSheetText = formatStyleSheetText({
          ctx,
          id: event.id ?? -1,
          styleSheetId: stylesheetEvent.styleSheetId,
          styleSheetText,
          styleSheetHref:
            possibleStylesheetHref ||
            ctx.hrefResolver.current.getFrameLocation(styleSheetFrame) ||
            '',
          base64AssetMetadata: ctx.base64AssetMetadata,
        })
      }
      if (existingStyleSheet) {
        existingStyleSheet.textContent = styleSheetText
      } else {
        ctx.nodeMap.current.updateAdoptedStylesheet(
          stylesheetEvent.styleSheetId,
          styleSheetText
        )
      }

      break
    }

    case 'dom:style-sheet-removed': {
      const stylesheetEvent =
        event.payload as EventsPayloadV7['cdp']['dom:style-sheet-removed']
      ctx.nodeMap.current.deleteAdoptedStylesheet({
        id: stylesheetEvent.styleSheetId,
      })
      // Let node-removed handle non computed stylesheets.
      const existingStyleSheet = ctx.nodeMap.current.getStyleSheet(
        stylesheetEvent.styleSheetId
      )
      if (!existingStyleSheet) {
        ctx.nodeMap.current.deleteAdoptedStylesheet({
          id: stylesheetEvent.styleSheetId,
        })
      }

      break
    }

    case 'dom:shadow-root-pushed': {
      if (ctx.config?.includeShadowDom) {
        const shadowRootPushedEvent =
          event.payload as EventsPayload['cdp']['dom:shadow-root-pushed']
        const root = shadowRootPushedEvent.root
        if (root.shadowRootType !== 'user-agent') {
          const host = ctx.nodeMap.current.getNode(
            shadowRootPushedEvent.hostId
          ) as Element
          // In some cases the host may be undefined because the host node has not been
          // added to the node map yet.
          // This can happen with DOM Sync if a "shadow-root-pushed" event
          // is pushed via 'attachShadow' BUT the attachShadow is loaded with
          // the rest of the page initially. This is an exception since "shadow-root-pushed" is NOT
          // triggered in CDP when attachShadow is called on initial page load.
          if (host !== undefined) {
            const shadow = host.attachShadow({
              mode: (root.shadowRootType as ShadowRootMode) || 'open',
            })
            ctx.nodeMap.current.add(shadow, root)
          }
        }
      }
      break
    }

    case 'dom:css-rule-inserted': {
      applyCSSRuleInserted({
        ctx,
        eventId: event.id ?? -1,
        cssRuleInsertedEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-rule-inserted'],
      })

      break
    }

    case 'dom:css-rule-deleted': {
      applyCSSRuleDeleted({
        ctx,
        cssRuleDeletedEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-rule-deleted'],
      })

      break
    }

    case 'dom:css-replaced': {
      applyCSSReplaced({
        ctx,
        eventId: event.id ?? -1,
        cssReplacedEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-replaced'],
        sync: false,
      })

      break
    }

    case 'dom:css-replaced-sync': {
      applyCSSReplaced({
        ctx,
        eventId: event.id ?? -1,
        cssReplacedEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-replaced-sync'],
        sync: true,
      })

      break
    }

    case 'dom:css-adopted-style-sheets': {
      applyCSSAdoptedStyleSheets({
        ctx,
        eventId: event.id ?? -1,
        cssAdoptedStyleSheetsEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-adopted-style-sheets'],
      })

      break
    }

    case 'dom:css-style-declaration-property-set': {
      applyCSSStyleDeclarationPropertySet({
        ctx,
        eventId: event.id ?? -1,
        cssStyleDeclarationPropertySetEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-style-declaration-property-set'],
      })

      break
    }

    case 'dom:css-style-declaration-property-removed': {
      applyCSSStyleDeclarationPropertyRemoved({
        ctx,
        cssStyleDeclarationPropertyRemovedEventPayload:
          event.payload as EventsPayload['cdp']['dom:css-style-declaration-property-removed'],
      })

      break
    }

    case 'dom:element-focused': {
      applyElementFocused({
        ctx,
        elementFocusedPayload:
          event.payload as EventsPayload['cdp']['dom:element-focused'],
      })

      break
    }

    case 'dom:element-blurred': {
      applyElementBlurred({
        ctx,
        elementBlurredPayload:
          event.payload as EventsPayload['cdp']['dom:element-blurred'],
      })

      break
    }

    default: {
      break
    }
  }

  // Handle anything requiring the node to be added to the window (e.g. iframe content window interactions)
  ctx.nodeMap.current.postAddingToWindowCallbacks.forEach((callback) => {
    callback()
  })
  ctx.nodeMap.current.postAddingToWindowCallbacks = []

  // This scenario is specific to iframe timing. Occasionally, we don't capture the full snapshot
  // until some of the CSS events have been processed. In this case, we need to run the elementIdToDelayedCallbacksArrayMap
  // after that child iframe snapshot has been processed and added to the window.
  ctx.nodeMap.current.elementIdToDelayedCallbacksArrayMap.forEach(
    (elementId, callbacks) => {
      if (!ctx.nodeMap.current.getNode(elementId)) {
        return
      }
      callbacks.forEach((callback) => {
        callback()
      })
      ctx.nodeMap.current.elementIdToDelayedCallbacksArrayMap.delete(elementId)
    }
  )
}
