import React from 'react'
import {
  DevtoolListItem,
  DevtoolListItemTestingProps,
} from '../../DevtoolListItem/DevtoolListItem'
import { Timeline, WaterfallPill } from '../../WaterfallPill/WaterfallPill'
import {
  IconObjectPinModern,
  IconStatusRunningOutline,
  IconObjectGear,
} from '@cypress-design/react-icon'
import { formatTestDuration } from '../../../../../utils/formatTestDuration'
import { TestIsolationLearnMore } from '../NetworkItemDetails/NetworkItemDetails'
import type { OutOfBoundsKind } from '../NetworkPanel'
import styles from './NetworkItem.module.scss'
import cs from 'clsx'
import { Tooltip } from '@frontend/design-system/src/components/Tooltip'

// for testing purposes:
export const NETWORK_GHOST_MARKER = 'tr-ghost-network-leading'

export type NetworkItemStatus = number | 'Cancelled' | 'Failed' | 'Ghost' | null

export type NetworkResponseType = {
  status: NetworkItemStatus
  method: 'GET' | 'PUT' | 'POST' | 'DELETE'
  path: string
  shortPath?: string
}

type NetworkResponseProps = {
  networkResponse: NetworkResponseType
  isGhost?: boolean
  isServiceWorkerRequest?: boolean
}

type NetworkItemProps = {
  isGhost?: boolean
  topSeparator?: boolean
  networkResponse: NetworkResponseType
  timeline: Timeline
  pinned?: boolean
  onClickCaret: () => void
  onClickContainer: () => void
  loading?: boolean
  reqType?: string
  outOfBoundsKind?: OutOfBoundsKind
  style?: React.CSSProperties // required for react-virtualized
  index?: number
  onMouseEnter?: VoidFunction
  onMouseLeave?: VoidFunction
  snapshotsUpdate?: VoidFunction
  snapshotsReset?: VoidFunction
  isServiceWorkerRequest?: boolean
} & DevtoolListItemTestingProps

type NetworkItemLeadingProps = {
  isGhost?: boolean
  isPinned?: boolean
  status: NetworkItemStatus
  timeline: Timeline
  withoutTooltip?: boolean
  loading?: boolean
  reqType?: string
  outOfBoundsKind?: OutOfBoundsKind
}

type NetworkStatusProps = {
  isGhost?: boolean
  isPinned?: boolean
  status: NetworkItemStatus
  loading?: boolean
}

function getStatusColor(status: NetworkItemStatus) {
  if (typeof status === 'number') {
    const statusCode = Number(status)
    if (statusCode <= 199) return '#D0D2E0'
    if (statusCode <= 299) return '#69D3A7'
    if (statusCode <= 399) return '#EDBB4A'
    if (statusCode <= 499) return '#D65FE3'
    if (statusCode >= 500) return '#E45770'
  }
  if (status === 'Cancelled' || status === 'Failed') {
    return '#E45770'
  }
  return '#9095AD'
}

function formatDuration(duration: number) {
  return formatTestDuration(duration).padStart(5, '0')
}

export const NetworkStatus: React.FC<NetworkStatusProps> = ({
  isGhost,
  isPinned,
  status,
  loading,
}) => {
  const statusColor = getStatusColor(isGhost ? 'Ghost' : status)

  return (
    <span className={styles['networkStatus']}>
      <div className={styles['networkIcon']}>
        {isPinned ? (
          <IconObjectPinModern
            data-cy="network-pinned-icon"
            strokeColor="purple-400"
            fillColor="purple-700"
          />
        ) : loading ? (
          <IconStatusRunningOutline
            size="12"
            strokeColor="indigo-400"
            data-cy="network-loading-icon"
            className="animate-spin"
          />
        ) : (
          <div
            data-cy="network-response-circle"
            className={styles['networkResponseCircle']}
            style={{
              borderColor: statusColor,
            }}
          />
        )}
      </div>
      <span
        data-cy="network-response-status-code"
        className={styles['networkResponseStatusCode']}
        style={{ color: statusColor }}
      >
        {status ?? '--'}
      </span>
    </span>
  )
}

export const NetworkItemLeading: React.FC<NetworkItemLeadingProps> = ({
  isGhost,
  isPinned,
  status,
  timeline,
  withoutTooltip,
  loading,
  reqType,
  outOfBoundsKind,
}) => {
  const statusColor = getStatusColor(isGhost ? 'Ghost' : status)

  const formattedStartTime = formatDuration(timeline.eventStart - timeline.min)
  const formattedDuration =
    outOfBoundsKind === 'end' || !timeline.eventEnd
      ? '--'
      : formatDuration(timeline.eventEnd - timeline.eventStart)

  const outOfBoundsDanger =
    outOfBoundsKind === 'start' || outOfBoundsKind === 'startAndEnd'
  const tooltipContent = outOfBoundsDanger ? (
    <div
      data-cy="out-of-bounds-tooltip"
      className={styles['outOfBoundsTooltip']}
    >
      This request started in a different attempt or test. Enable Test Isolation
      to prevent requests leaking from one attempt or test to another.{' '}
      <TestIsolationLearnMore />
      {!!reqType && (
        <>
          <span className={styles['divider']} />
          <div data-cy="out-of-bounds-tooltip-type">Type: {reqType}</div>
        </>
      )}
    </div>
  ) : (
    <div
      className={styles['waterfallPillPopper']}
      data-cy="devtool-network-item-waterfall-tooltip"
    >
      <span>Request start: {formattedStartTime}</span>
      <span>Duration: {formattedDuration}</span>
      <span>Type: {reqType} </span>
    </div>
  )

  return (
    <div
      data-cy="network-item-leading"
      className={cs(styles['leading'], isGhost && NETWORK_GHOST_MARKER)}
    >
      <div className={`${styles['waterfallPill']}`}>
        <WaterfallPill
          color={statusColor}
          timeline={timeline}
          data-cy="devtool-network-item-waterfall"
          tooltipContent={!withoutTooltip && tooltipContent}
          warningDot={outOfBoundsDanger}
        />
      </div>
      <NetworkStatus
        isPinned={isPinned}
        status={status}
        loading={loading}
        isGhost={isGhost}
      />
    </div>
  )
}

export const NetworkResponse: React.FC<NetworkResponseProps> = ({
  networkResponse,
  isServiceWorkerRequest,
  isGhost,
}) => {
  return (
    <div
      data-cy="devtool-network-item-response"
      className={styles['networkResponse']}
    >
      <span
        data-cy="network-response-method"
        className={cs(
          styles['networkResponseMethod'],
          isGhost && styles['ghost']
        )}
      >
        {networkResponse.method}
      </span>
      <span
        data-cy="network-response-path"
        className={cs(
          styles['networkResponsePath'],
          isGhost && styles['ghost']
        )}
        title={networkResponse.path}
      >
        {isServiceWorkerRequest ? (
          <Tooltip placement={'bottom'} overlay={'Service worker'}>
            <div className={styles['networkResponseIconContainer']}>
              <IconObjectGear
                size={'16'}
                fillColor="gray-300"
                strokeColor={'transparent'}
              />
            </div>
          </Tooltip>
        ) : null}
        <div className={styles['networkResponsePathContainer']}>
          {networkResponse.shortPath || networkResponse.path}
        </div>
      </span>
    </div>
  )
}

// layout component:
export const NetworkItem: React.FC<NetworkItemProps> = ({
  isGhost,
  topSeparator,
  pinned,
  onClickCaret,
  onClickContainer,
  networkResponse,
  timeline,
  loading,
  reqType,
  outOfBoundsKind,
  style,
  index,
  onMouseEnter,
  onMouseLeave,
  snapshotsUpdate,
  snapshotsReset,
  isServiceWorkerRequest,
  ...rest
}) => {
  return (
    <DevtoolListItem
      {...rest}
      index={index}
      style={style}
      topSeparator={topSeparator}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      snapshotsUpdate={snapshotsUpdate}
      snapshotsReset={snapshotsReset}
      onClickCaret={onClickCaret}
      onClickContainer={onClickContainer}
      focusColor="#A06CE4"
      pinned={pinned}
      data-pendo="replay-devtool-network-item"
      data-cy={`devtool-network-item-${index}`}
      leading={
        <NetworkItemLeading
          isGhost={isGhost}
          reqType={reqType}
          outOfBoundsKind={outOfBoundsKind}
          isPinned={pinned}
          timeline={timeline}
          status={networkResponse.status}
          loading={loading}
        />
      }
    >
      <NetworkResponse
        isServiceWorkerRequest={isServiceWorkerRequest}
        isGhost={isGhost}
        networkResponse={networkResponse}
      />
    </DevtoolListItem>
  )
}
