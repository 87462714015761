import React from 'react'
import styles from './TabsShim.module.scss'
/**
 * Styling helper while we handle
 * some issues with design system:
 */
export const TabsShim = ({ children, ...rest }: { children: JSX.Element }) => (
  <div className={styles['tabs-shim']} {...rest}>
    {children}
  </div>
)
