import { useEffect, useState } from 'react'
import { useDatabaseWorker } from '../components/Context/DatabaseWorkerContext'
import type {
  PrepareDatabase,
  ReplayDataError,
  ReplayDataReady,
  ClientMessageEvent,
} from '../webworkers/database.worker'
import { useUrlContext } from '../components/Context/UrlContext'

export const useReplayData = (
  testId: string,
  url: string,
  sqlJsUrl: string,
  assetsUrl: string | null,
  rangeHeader: string | null
) => {
  const { testReplayParams } = useUrlContext()
  const { att: initAttempt } = testReplayParams
  const [data, setData] = useState<ReplayDataReady['payload'] | null>(null)
  const [error, setError] = useState<
    ReplayDataError['payload']['error'] | null
  >(null)
  const { worker } = useDatabaseWorker()

  useEffect(() => {
    setData(null)
    setError(null)
    worker.postMessage({
      type: 'GET_REPLAY_DATA',
      payload: {
        testId,
        url,
        sqlJsUrl,
        assetsUrl,
        rangeHeader,
        initAttempt,
      },
    } as PrepareDatabase)
    // only load once per session onMount.
    // hence we keep array empty.
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    function handleSetup(event: ClientMessageEvent) {
      switch (event.data.type) {
        case 'REPLAY_DATA_READY': {
          setError(null)
          setData(event.data.payload)
          break
        }
        case 'REPLAY_DATA_POSTPONED_ATTEMPT_READY': {
          const { attNum, attemptData } = event.data.payload
          setData((data) => {
            if (!data) {
              throw new Error(
                'TestReplay: postponed data arrived before base payload.'
              )
            }
            return {
              ...data,
              testAttempts: data!.testAttempts.map((curr, idx) => {
                if (idx + 1 === attNum) {
                  return attemptData
                }
                return curr
              }),
            }
          })
          break
        }
        case 'REPLAY_DATA_ERROR': {
          setData(null)
          setError(event.data.payload.error)
          break
        }
        default:
          break
      }
    }
    worker.addEventListener('message', handleSetup)
    return () => {
      worker.removeEventListener('message', handleSetup)
    }
  }, [worker])

  return { data, error }
}
