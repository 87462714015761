import type { ImagesAndStylesAssets } from '../webworkers/database.worker'
import { Base64 } from 'js-base64'

export type Base64AssetMetadata = {
  count: number
  totalSize: number
  largestSize: number
}

export const updateMetadataWithAdditionalMetadata = (
  base64AssetMetadata: Base64AssetMetadata,
  additionalMetadata: Base64AssetMetadata
) => {
  base64AssetMetadata.count += additionalMetadata.count
  base64AssetMetadata.totalSize += additionalMetadata.totalSize
  base64AssetMetadata.largestSize = Math.max(
    additionalMetadata.largestSize,
    base64AssetMetadata.largestSize
  )
}

export const updateMetadataWithBase64Asset = (
  base64AssetMetadata: Base64AssetMetadata,
  asset: string
) => {
  const length = asset.length
  base64AssetMetadata.count += 1
  base64AssetMetadata.totalSize += length
  base64AssetMetadata.largestSize = Math.max(
    length,
    base64AssetMetadata.largestSize
  )
}

type Base64String = string
export const toBase64Asset = (
  asset?: ImagesAndStylesAssets['hashData']['']
): Base64String => {
  if (!asset) {
    return ''
  }
  return `data:${asset.mimeType};base64,${
    asset.isBase64 ? asset.body : Base64.encode(asset.body)
  }`
}
