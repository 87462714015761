import 'css-chunk:src/components/ReplayBody/DevTools/DevToolsHeader/DevToolsHeader.module.scss';export default {
  "container": "_container_k8sdv_7",
  "tooltipContainer": "_tooltipContainer_k8sdv_16",
  "iconQuestionMark": "_iconQuestionMark_k8sdv_19",
  "closeButton": "_closeButton_k8sdv_30",
  "cta-tooltip-overlay": "_cta-tooltip-overlay_k8sdv_35",
  "cta-title": "_cta-title_k8sdv_43",
  "cta-description": "_cta-description_k8sdv_49",
  "keystrokes-container": "_keystrokes-container_k8sdv_54",
  "keystroke": "_keystroke_k8sdv_54"
};