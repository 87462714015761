import { useEffect, useState } from 'react'
import { useThrottledCallback } from 'use-debounce'

/**
 * Controls the frequency of changes detected in value.
 * For example, if you only want to see a value's change
 * no more than 10x per second, then you'd say something like:
 *
 * const frequentlyChangingVal: any
 * const slowedDownVal = useThrottle(frequentlyChangingVal, 100)
 *
 * useEffect(() => {
 *   console.log(
 *     slowedDownVal + ': this log happens no more than 10x per second'
 *   )
 * }, [slowedDownVal])
 */
export function useThrottle<T>(value: T, interval: number): T {
  const [val, setVal] = useState<T>(value)
  const throttle = useThrottledCallback(setVal, interval, {
    leading: true,
    trailing: true,
  })
  useEffect(() => {
    throttle(value)
  }, [throttle, value])
  return val
}
