import React, { useState } from 'react'
import StatusIcon from '@cypress-design/react-statusicon'
import {
  IconCheckmarkSmall,
  IconActionQuestionMarkCircle,
} from '@cypress-design/react-icon'
import { SingleSelect } from '@frontend/design-system/src/components/Select/Select'
import {
  ReplayContextValue,
  useReplayActions,
  useReplayContext,
} from '~/src/components/Context/ReplayContext/index'
import { Tooltip } from '@frontend/design-system/src/components/Tooltip'
import type { AttemptOptionInfo } from '~/src/webworkers/database.worker'
import type { AttemptOption } from '~/src/utils/useAttemptOptions'
import { PlayButtonSmall } from '../PlayButton/PlayButton'
import { Timeline } from './Timeline/Timeline'
import styles from './AttemptPicker.module.scss'
import cs from 'clsx'

// used for hacking the Select component and
// conditionally acting based on clicked elements:
export const ALT_CLICKABLE_ELEMENT = 'ALT_CLICKABLE_ELEMENT'
const TR_POSTPONED_ATTEMPT = 'TR_POSTPONED_ATTEMPT'

export function itemFactory(
  attNum: number,
  total: number,
  duration: string,
  longestTime: number,
  attempt: AttemptOptionInfo,
  isPostponed: boolean
) {
  const CustomAttemptOpt: React.FC<{
    asValue?: boolean
    asOption?: boolean
    isSelected?: boolean
  }> = ({ asValue, isSelected, asOption }) => {
    const [isHovered, setIsHovered] = useState(false)
    const { changeAttempt } = useReplayActions()

    let leadingSlot = (
      <span
        title={attempt.status}
        data-cy={`att-status-${attempt.status}`}
        className={cs(
          styles['status-icon-container'],
          styles[`status-color-${attempt.status}`]
        )}
      >
        <StatusIcon size="16" status={attempt.status} variant="simple" />
      </span>
    )
    if (asOption && isHovered) {
      leadingSlot = isPostponed ? (
        <IconActionQuestionMarkCircle
          style={{
            marginRight: 4,
            marginTop: -1,
          }}
        />
      ) : (
        <span style={{ marginRight: 2 }}>
          <PlayButtonSmall
            onClick={() =>
              changeAttempt(attNum - 1, {
                play: true,
              })
            }
          />
        </span>
      )
    }
    const widthByAttempts = total < 10 ? 72 : 80
    const content = (
      <div
        data-cy={`CustomAttemptOpt-${asValue ? 'asValue' : 'asOption'}`}
        data-pendo={'replay-footer-attempt-item'}
        className={cs(styles['attempt-list-item-container'], {
          TR_POSTPONED_ATTEMPT: isPostponed,
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {leadingSlot}
        <div
          data-cy="att-number"
          className={styles['att-number']}
          style={{
            minWidth: widthByAttempts,
            width: widthByAttempts,
          }}
        >
          Attempt {attNum} {asValue && <span>of {total}</span>}
        </div>
        {asOption && (
          <>
            <div className={styles['timeline-wrapper']}>
              <Timeline
                longestTime={longestTime}
                startAt={attempt.min}
                endAt={attempt.max}
                cliffAt={
                  attempt.cliffAt ? attempt.cliffAt - attempt.min : undefined
                }
              />
            </div>
            <div
              data-cy="att-duration"
              className={`${cs(styles['duration-container'])}`}
            >
              {duration}
            </div>
          </>
        )}
        <span
          data-cy="checkmark-container"
          className={styles['checkmark-container']}
          style={{ opacity: asOption && isSelected ? '1' : '0' }}
        >
          <IconCheckmarkSmall color="indigo-100" />
        </span>
      </div>
    )

    if (isPostponed) {
      return (
        <Tooltip
          placement="right"
          overlay="Loading, give it a few more seconds."
        >
          {content}
        </Tooltip>
      )
    }

    return content
  }
  return CustomAttemptOpt
}

export const AttemptPicker: React.FC = () => {
  const { attempts } = useReplayContext()
  return <AttemptPickerMemo attempts={attempts} />
}

const AttemptPickerMemo: React.FC<{
  attempts: ReplayContextValue['attempts']
}> = React.memo(({ attempts }) => {
  const widthByAttempts = attempts.options.length < 10 ? 168 : 194
  return (
    <div
      data-cy="AttemptPicker"
      className={cs(styles['single-select-wrapper'])}
      // Forces a rerender when other controls outside
      // of this picker also change the selected attempt:
      key={attempts.selectedOption.value}
      style={{
        minWidth: widthByAttempts,
        width: widthByAttempts,
      }}
    >
      <SingleSelect
        menuIsOpen
        menuPlacement="top"
        options={attempts.options}
        defaultValue={attempts.selectedOption}
        onChange={(att) => {
          attempts.changeAttempt((att as AttemptOption).value)
        }}
        onClickOption={({ nativeOnClick, closeMenu, event }) => {
          if (
            // @ts-ignore: closest should exist:
            event.target.closest(`.${TR_POSTPONED_ATTEMPT}`) ||
            event.currentTarget.closest(`.${TR_POSTPONED_ATTEMPT}`)
          ) {
            // Behaves like a disabled button when
            // clicking on a postponed option:
            event.preventDefault()
            return
          }

          // @ts-ignore: classList should exist:
          const classes = event.target.classList
          const isFromAltClickable =
            classes && Array.from(classes).includes(ALT_CLICKABLE_ELEMENT)

          if (isFromAltClickable) {
            closeMenu()
          } else {
            // NOTE: this will internally trigger
            // the onChange prop callback above:
            nativeOnClick(event)
          }
        }}
        removeListShadow
        displaySelected
        customListItems
        // We need to force the root theme into allowing
        // us to set the height fo the control picker:
        theme={(defaults) => ({
          ...defaults,
          spacing: {
            ...defaults.spacing,
            controlHeight: 30,
          },
        })}
        styles={{
          menu: (styles) => ({
            ...styles,
            boxShadow: 'none !important',
            border: 'none',
            width: 368,
          }),
        }}
      />
    </div>
  )
})
