import React, { useLayoutEffect, useRef } from 'react'
import { usePreviousValueOf } from '@frontend/dashboard/src/lib/hooks/usePreviousValueOf'
import { useHandleDevtoolsItemSnapshotEvents } from '../../../../components/Context/SnapshotChangeContext'
import { Separator } from '../../Separator/Separator'
import styles from './DevtoolListItem.module.scss'
import Button from '@cypress-design/react-button'
import { IconChevronRightSmall } from '@cypress-design/react-icon'
import cx from 'classnames'
import type { ConsoleDrillShape } from '../ConsolePanel/ConsolePanel'

export type DevtoolListItemTestingProps = {
  'data-cy-event-id'?: string | number
  'data-cy-event-end'?: string | number
  'data-cy-event-start'?: string | number
}

export type DevtoolListItemProps = {
  children: React.ReactNode
  leading?: React.ReactNode
  focusColor?: string
  forceFocused?: boolean
  pinned?: boolean
  collapsibleContent?: React.ReactNode
  onClickContainer?: VoidFunction
  onClickCaret?: VoidFunction
  ['data-cy']?: string
  ['data-pendo']?: string
  persistBackgroundOnExpanded?: boolean
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
  style?: React.CSSProperties // required for react-virtualized
  drill?: ConsoleDrillShape
  topSeparator?: boolean
  index?: number
  onMouseEnter?: VoidFunction
  onMouseLeave?: VoidFunction
  snapshotsUpdate?: VoidFunction
  snapshotsReset?: VoidFunction
} & DevtoolListItemTestingProps

export const DevtoolListItem = (props: DevtoolListItemProps) => {
  const {
    focusColor = '#9095AD',
    forceFocused = false,
    pinned,
    onClickCaret,
    onClickContainer,
    persistBackgroundOnExpanded,
    onMouseEnter,
    onMouseLeave,
    style,
    drill,
    expanded = false,
    setExpanded = () => {},
    topSeparator,
    index,
    snapshotsUpdate,
    snapshotsReset,
    ...rest
  } = props

  const { devtoolsItemRef } = useHandleDevtoolsItemSnapshotEvents(
    snapshotsUpdate,
    snapshotsReset
  )

  const [isLocalExpanded, setLocalExpanded] = React.useState(expanded)
  const [isHovered, setIsHovered] = React.useState<boolean>(false)
  const focused = forceFocused || pinned

  const caretShouldDisplay = onClickCaret || props.collapsibleContent
  const onCaretClick: React.MouseEventHandler<HTMLElement> = React.useCallback(
    (event) => {
      event.stopPropagation()
      if (onClickCaret) {
        onClickCaret()
      } else {
        setLocalExpanded(!isLocalExpanded)
        setExpanded(!expanded)
      }
    },
    [isLocalExpanded, setExpanded, expanded, onClickCaret]
  )

  const expandChanged = isLocalExpanded !== usePreviousValueOf(isLocalExpanded)
  const isMeasured = useRef(false)
  useLayoutEffect(() => {
    if (isMeasured.current && expandChanged && drill?.listRef.current) {
      drill.cache.clear(drill.index, 0)
      drill.listRef?.current?.recomputeRowHeights(drill.index)
      drill.listRef?.current?.forceUpdateGrid()
      if (drill.index === drill.listRef.current.props.rowCount - 1) {
        // If it's the last row, we assume the scroll area has likely shifted and we want
        // to expand a bit further than where we're at
        requestAnimationFrame(() => {
          drill.listRef.current?.scrollToRow(drill.index)
        })
      }
    } else {
      isMeasured.current = true
    }
  }, [expandChanged, drill?.index, drill?.listRef, drill?.cache])

  return (
    <div
      ref={devtoolsItemRef}
      data-cy={`virtualized-item-${index ?? ''}`}
      className={styles['virtualized-item']}
      style={style}
    >
      <>
        {topSeparator && (
          <Separator
            className={cx(styles['devtools-item-separator'], styles['top'])}
          />
        )}
        <div
          data-cy={props['data-cy']}
          data-cy-event-id={rest['data-cy-event-id']}
          data-cy-event-end={rest['data-cy-event-end']}
          data-cy-event-start={rest['data-cy-event-start']}
          data-pendo={props['data-pendo']}
          className={cx(
            styles.container,
            pinned && 'bg-indigo-900',
            focused || isHovered ? 'bg-gray-900' : 'bg-gray-1000',
            isHovered && !isLocalExpanded && !pinned
              ? styles['hoverContainer']
              : '',
            onClickContainer && 'cursor-pointer',
            persistBackgroundOnExpanded && isLocalExpanded ? 'bg-gray-900' : ''
          )}
          onClick={onClickContainer}
          onMouseEnter={() => {
            onMouseEnter?.()
            setIsHovered(true)
          }}
          onMouseLeave={() => {
            onMouseLeave?.()
            setIsHovered(false)
          }}
        >
          {(focused || isLocalExpanded) && (
            <div
              data-cy={`${props['data-cy']}-border`}
              className={styles['focusBorder']}
              style={{ borderColor: focusColor }}
            />
          )}

          <div className={styles['primaryBar']}>
            {props.leading && (
              <div className={styles['leading']}>{props.leading}</div>
            )}
            <div
              className={styles['collapsible']}
              ref={drill?.registerChild as React.LegacyRef<HTMLDivElement>}
            >
              <div className={styles['primaryContent']}>
                <div className={styles['children']}>{props.children}</div>
                {caretShouldDisplay && (
                  <Button
                    className={styles['caret']}
                    data-cy={`${props['data-cy']}-caret`}
                    data-pendo={`${props['data-pendo']}-caret`}
                    size="20"
                    style={{ background: 'transparent' }}
                    variant={isHovered || pinned ? 'outline-dark' : 'link'}
                    onClick={onCaretClick}
                    square
                  >
                    <IconChevronRightSmall
                      className={expanded ? 'rotate-90' : undefined}
                      size="16"
                      strokeColor="gray-600"
                    />
                  </Button>
                )}
              </div>
              {expanded && (
                <div
                  className={styles['collapsedContent']}
                  data-cy="expanded-content"
                >
                  {props.collapsibleContent}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  )
}
