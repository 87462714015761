import { useEffect, useState } from 'react'
import { useDatabaseWorker } from '../components/Context/DatabaseWorkerContext'
import type {
  GetNetworkResponse,
  NetworkResponseReady,
} from '../webworkers/database.worker'

export const useResponseBody = (attNum?: number, reqId?: string) => {
  const [data, setData] = useState<NetworkResponseReady['payload'] | null>(null)
  const { worker } = useDatabaseWorker()

  useEffect(() => {
    if (!attNum || !reqId) {
      setData(null)
      return
    }
    worker.postMessage({
      type: 'GET_NETWORK_RESPONSE',
      payload: {
        attNum,
        reqId,
      },
    } as GetNetworkResponse)
  }, [worker, attNum, reqId])

  useEffect(() => {
    function handleNetworkResponse(event: MessageEvent<NetworkResponseReady>) {
      switch (event.data.type) {
        case 'NETWORK_RESPONSE_READY':
          setData(event.data.payload)
          break
        default:
          break
      }
    }
    worker.addEventListener('message', handleNetworkResponse)
    return () => {
      worker.removeEventListener('message', handleNetworkResponse)
    }
  }, [worker])

  return data
}
