/**
 * Extend as needed.
 */
export function getOperatingSystem() {
  const info = window?.navigator?.userAgent
  if (info?.toLowerCase()?.includes(' mac ')) {
    return 'mac'
  }
  return 'windows'
}
