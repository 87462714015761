const WARN_ONCE_PER_REPLAY: Record<string, boolean> = {}
export const warnOnce = (key: string, ...args: any) => {
  if (
    !WARN_ONCE_PER_REPLAY[key] &&
    // @ts-ignore
    typeof window !== 'undefined' &&
    // @ts-ignore
    window.env !== 'production'
  ) {
    WARN_ONCE_PER_REPLAY[key] = true
    console.info(...args)
  }
}
