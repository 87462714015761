import type { NetworkItemDetailsProps } from './NetworkItemDetails/NetworkItemDetails'
import type { Maybe } from '@frontend/dashboard/src/graphql-codegen-operations.gen'
import type { CypressAppEvent } from '~/src/webworkers/database.worker'
import type { Timeline } from '../WaterfallPill/WaterfallPill'
import type { OutOfBoundsKind } from './NetworkPanel'
import qs from 'query-string'

function getQueryParams(callPath?: string | null) {
  if (!callPath) return null
  const searchStartIdx = callPath.indexOf('?')
  if (searchStartIdx === -1) return
  const search = callPath.slice(searchStartIdx)
  return qs.parse(search)
}

export function makeNetworkDetails(ctx: {
  requestEvt?: CypressAppEvent
  responseEvt?: CypressAppEvent
  timeline: Timeline
  outOfBoundsKind?: OutOfBoundsKind
  requestExtraEvt?: CypressAppEvent
  responseExtraEvt?: CypressAppEvent
  attNum: number
  reqId: string
  isServiceWorkerRequest?: boolean
}) {
  const reqType: Maybe<string> = ctx.requestEvt?.payload?.type
  const status: Maybe<string> =
    ctx.responseEvt?.payload?.response?.status ||
    ctx.responseExtraEvt?.payload?.response?.status
  const method: Maybe<string> = ctx.requestEvt?.payload?.request?.method
  const callPath: Maybe<string> = ctx.requestEvt?.payload?.request?.url
  const shortPath: Maybe<string> = ctx.requestEvt?.payload?.request?.shortUrl

  function makeRequest() {
    const request: any[] = []
    if (callPath || method) {
      request.push({
        type: 'key_value_pairs',
        title: 'General',
        content: {
          ...(callPath && { 'Request URL': callPath }),
          ...(method && { 'Request Method': method }),
        },
      })
    }
    if (
      ctx.requestEvt?.payload?.request?.headers ||
      ctx.requestExtraEvt?.payload?.request?.headers
    ) {
      request.push({
        type: 'key_value_pairs',
        title: 'Request headers',
        content: {
          ...ctx.requestEvt?.payload?.request?.headers,
          ...ctx.requestExtraEvt?.payload?.request?.headers,
        },
      })
    }
    const params = getQueryParams(callPath)
    if (params) {
      request.push({
        type: 'key_value_pairs',
        title: 'Query parameters',
        content: params,
      })
    }
    return request
  }

  function makeResponse() {
    const response: any[] = []
    if (
      ctx.responseEvt?.payload?.response?.headers ||
      ctx.responseExtraEvt?.payload?.response?.headers
    ) {
      response.push({
        type: 'key_value_pairs',
        title: 'Response headers',
        content: {
          ...ctx.responseEvt?.payload?.response?.headers,
          ...ctx.responseExtraEvt?.payload?.response?.headers,
        },
      })
    }
    return response
  }

  return {
    status,
    requestType: reqType,
    timeline: ctx.timeline,
    requestStart: ctx.timeline.eventStart - ctx.timeline.min,
    requestDuration: ctx.timeline.eventEnd! - ctx.timeline.eventStart,
    outOfBoundsKind: ctx.outOfBoundsKind,
    isServiceWorkerRequest: ctx.isServiceWorkerRequest,
    networkResponse: {
      status,
      method,
      path: callPath,
      shortPath,
    },
    content: {
      request: makeRequest(),
      response: makeResponse(),
    },
    reqId: ctx.reqId,
    attNum: ctx.attNum,
  } as NetworkItemDetailsProps
}
