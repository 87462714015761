import { useCallback, useEffect } from 'react'
import { useDatabaseWorker } from '../components/Context/DatabaseWorkerContext'
import type {
  ConsolePropsMessageReady,
  GetConsolePropsMessage,
} from '../webworkers/database.worker'
import { logger } from './logger'

export function useHandleConsoleProps() {
  const { worker } = useDatabaseWorker()
  const handleGetConsoleProps = useCallback(
    (attNum: number | string, logId: string) => {
      worker.postMessage({
        type: 'GET_CONSOLE_PROPS_MESSAGE',
        payload: {
          attNum,
          logId,
        },
      } as GetConsolePropsMessage)
    },
    [worker]
  )
  useEffect(() => {
    function handleConsoleProps(event: MessageEvent<ConsolePropsMessageReady>) {
      switch (event.data.type) {
        case 'CONSOLE_PROPS_MESSAGE_READY':
          logger.clearLog()
          logger.logFormatted(event.data.payload.message)
          break
        default:
          break
      }
    }
    worker.addEventListener('message', handleConsoleProps)
    return () => {
      worker.removeEventListener('message', handleConsoleProps)
    }
  }, [worker])
  return {
    handleGetConsoleProps,
  }
}
