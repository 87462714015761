import { useState, useEffect, useCallback, useRef } from 'react'
import { useAnimationFrame } from './useAnimationFrame'
import { useUrlContext } from '../components/Context/UrlContext'
import { clamp } from './clamp'

type TimeChangeSources = 'play-action' | 'other-action' | undefined
type Setter = ReturnType<typeof useGlobalTimer>['setSpeed']
export type TimerSpeed = Parameters<Setter>[0]
export type Timer = ReturnType<typeof useGlobalTimer>

export const useGlobalTimer = ({
  min,
  max,
  beginAt,
  initSpeed = 1,
  testAttempt,
}: {
  min: number
  max: number
  beginAt?: number
  initSpeed?: 0.25 | 0.5 | 0.75 | 1
  testAttempt: number
}) => {
  const [playing, setPlaying] = useState(false)
  const [speed, setSpeed] = useState(initSpeed)
  // Default to the timestamp set in the querystring if available
  const { setSearchParams, testReplayParams } = useUrlContext()
  const startTime = testReplayParams.ts ?? beginAt ?? min
  const [time, setTime_Private] = useState(clamp(startTime, min, max))
  const [firstChangeSource, setFirstChangeSource] =
    useState<TimeChangeSources>()

  useEffect(() => {
    // reset "firstChangeSource" whenever
    // the user flips between attempts:
    setFirstChangeSource(undefined)
  }, [testAttempt])

  const setTime = useCallback(
    (arg: Parameters<typeof setTime_Private>[0]) => {
      if (!firstChangeSource) {
        setFirstChangeSource('other-action')
      }
      setTime_Private(arg)
    },
    [firstChangeSource]
  )

  const timeRef = useRef(time)
  timeRef.current = time

  useAnimationFrame(
    (timeSkew) => {
      if (timeRef.current >= max) {
        setPlaying(false)
        return
      }
      const delta = speed * timeSkew
      setTime((n) => Math.min(n + delta, max))
    },
    playing,
    speed
  )

  const reset = useCallback(() => setTime(min), [min, setTime])

  const pause = useCallback(() => setPlaying(false), [setPlaying])

  useEffect(() => {
    if (!playing) {
      setSearchParams({ ts: time })
    }
  }, [playing, setSearchParams, time])

  const play = useCallback(
    (opts?: { beginFrom?: number }) => {
      if (!firstChangeSource) {
        setFirstChangeSource('play-action')
        if (!opts?.beginFrom) {
          reset()
        }
      }
      if (opts?.beginFrom) {
        setTime(opts.beginFrom)
      }
      setPlaying(true)
    },
    [setPlaying, reset, setTime, firstChangeSource]
  )

  return {
    firstChangeSource,
    playing,
    play,
    pause,
    setSpeed,
    speed,
    time,
    setTime,
    reset,
  } as const
}
