import type { ResponseReceived } from '@packages/app-capture-protocol/src/db/schemas/v1/payload-types'
import type { ReplayContextValue } from '../components/Context/ReplayContext'
import type { DisplayedSnapshot } from '../components/Context/ReplayContext/useSnapshotController'
import type { OutOfBoundsKind } from '../components/ReplayBody/DevTools/NetworkPanel/NetworkPanel'
import type { CypressAppEvent } from '../webworkers/database.worker'
import type { RequestWillBeSent } from '@packages/app-capture-protocol/src/db/schemas/v4/payload-types'

// Help performance by creating single
// instance of finder functions to avoid creating
// new callbacks in memory with every rerender:
export const finder = {
  getRequest(e: CypressAppEvent<any>) {
    return e.type === 'network:request-will-be-sent'
  },
  getRequestExtra(e: CypressAppEvent<any>) {
    return e.type === 'network:request-will-be-sent-extra-info'
  },
  getResponse(e: CypressAppEvent<any>) {
    return e.type === 'network:response-received'
  },
  getResponseExtra(e: CypressAppEvent<any>) {
    return e.type === 'network:response-received-extra-info'
  },
  getFinished(e: CypressAppEvent<any>) {
    return (
      e.type === 'network:loading-finished' ||
      e.type === 'network:loading-failed'
    )
  },
}

export const rollupNetworkEvents = (
  events: ReplayContextValue['events']['network'][0][1],
  timer: Pick<ReplayContextValue['timer'], 'min' | 'max'>
) => {
  const requestEvt = events.find(finder.getRequest)
  const requestExtraEvt = events.find(finder.getRequestExtra)
  const responseEvt = events.find(finder.getResponse)
  const responseExtraEvt = events.find(finder.getResponseExtra)
  const finishedEvt = events.find(finder.getFinished)

  // If test-isolation is disabled during the run, then it is possible
  // for a network stream to be missing its request or finish events.
  // In these cases we warn the user regarding bad practices, etc.
  let outOfBoundsKind: OutOfBoundsKind | undefined
  if (!requestEvt && !finishedEvt) {
    outOfBoundsKind = 'startAndEnd'
  } else if (!requestEvt) {
    outOfBoundsKind = 'start'
  } else if (!finishedEvt) {
    outOfBoundsKind = 'end'
  }
  // Either one of the time boundaries might be missing if the network stream began
  // in a previous test or the test ended before the network call completed:
  const startTime = requestEvt?.timestamp ?? timer.min
  const endTime = finishedEvt?.timestamp ?? timer.max

  const status = responseEvt?.payload?.response?.status ?? null
  const method = requestEvt?.payload.request.method
  const path = requestEvt?.payload.request.url
  const shortPath = requestEvt?.payload.request.shortUrl
  const reqType = requestEvt?.payload.type

  // https://vanilla.aslushnikov.com/?Network.requestWillBeSent
  const SERVICE_WORKER_LOADER_ID = ''
  const isServiceWorkerRequest =
    (requestEvt?.payload as RequestWillBeSent)?.loaderId ===
    SERVICE_WORKER_LOADER_ID
  const responseFulfilledByServiceWorker =
    (responseEvt?.payload as ResponseReceived)?.response.fromServiceWorker ||
    false

  /**
   * NOTE:
   *
   * We haven't decided how to handle snapshots for incomplete network data.
   * For now we only show snapshot buttons when all network data available.
   */
  let snapshots: DisplayedSnapshot[]
  if (outOfBoundsKind === 'end') {
    snapshots = [
      {
        name: undefined,
        timestamp: startTime,
        elementsToHighlight: [],
      },
    ]
  } else if (outOfBoundsKind === 'start') {
    snapshots = [
      {
        name: undefined,
        timestamp: endTime,
        elementsToHighlight: [],
      },
    ]
  } else {
    snapshots = [
      {
        name: 'request',
        timestamp: startTime,
        elementsToHighlight: [],
      },
      {
        name: 'response',
        timestamp: endTime,
        elementsToHighlight: [],
      },
    ]
  }

  return {
    source: {
      requestEvt,
      requestExtraEvt,
      responseEvt,
      responseExtraEvt,
      finishedEvt,
    },
    isServiceWorkerRequest,
    responseFulfilledByServiceWorker,
    startTime,
    endTime,
    outOfBoundsKind,
    status,
    method,
    path,
    shortPath,
    reqType,
    snapshots,
  }
}
