import { useState, useMemo, useCallback } from 'react'
import type {
  AttemptOptionInfo,
  TestAttempt,
} from '../webworkers/database.worker'
import { itemFactory } from '~/src/components/ReplayFooter/AttemptPicker/AttemptPicker'
import { useUrlContext } from '../components/Context/UrlContext'
import { formatTestDuration } from './formatTestDuration'
import { assetsCache } from './assetsCache'

type AttemptInfo = {
  value: number
  isPostponed: boolean
  CustomListItem: React.FC<{
    asValue?: boolean | undefined
    asOption?: boolean | undefined
    isSelected?: boolean | undefined
  }>
}

export const useAttemptOptions = (
  optionsInfo: AttemptOptionInfo[],
  initAttempt: number = 1,
  testAttempts: (TestAttempt | null)[]
) => {
  const { setSearchParams } = useUrlContext()

  const options = useMemo(() => {
    const longestTime = Math.max(...optionsInfo.map((att) => att.max - att.min))
    return optionsInfo.map((optInfo, idx) => {
      const isPostponed = !testAttempts[idx]
      return {
        value: idx,
        isPostponed,
        CustomListItem: itemFactory(
          idx + 1,
          optionsInfo.length,
          formatTestDuration(optInfo.max - optInfo.min),
          longestTime,
          optInfo,
          isPostponed
        ),
      }
    })
  }, [optionsInfo, testAttempts])

  const [attempt, setAttempt_Private] = useState<AttemptInfo>(() => {
    const enforceInit = initAttempt ? initAttempt - 1 : null
    return (options[enforceInit ?? 0] ?? options[0]) as AttemptInfo
  })

  const setAttempt = useCallback(
    (attemptInfo: AttemptInfo) => {
      assetsCache.clear()
      setAttempt_Private(attemptInfo)
      setSearchParams({
        att: attemptInfo.value + 1,
      })
    },
    [setSearchParams]
  )

  return {
    attempt,
    setAttempt,
    options,
  }
}

export type AttemptOption = ReturnType<
  typeof useAttemptOptions
>['options'][number]

export type SetAttemptOption = ReturnType<
  typeof useAttemptOptions
>['setAttempt']
