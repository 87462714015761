import type { Snapshot as SnapshotV9 } from '@packages/app-capture-protocol/src/db/schemas/v9'
import type {
  EventsPayload,
  Snapshot,
} from '@packages/app-capture-protocol/src/db/schemas/latest'
import React, { useState } from 'react'

export type DisplayedSnapshot = SnapshotV9 | Snapshot

export type SnapshotInfo = {
  id: string
  section: 'command-logs' | 'console-logs' | 'network-calls'
  hitbox?: EventsPayload['cypress']['log:changed']['coords']
}

export const useSnapshotController = (args: { isPinned: boolean }) => {
  const { isPinned } = args
  const [snapshotInfo, setSnapshotInfo] = useState<SnapshotInfo | null>(null)
  const [displayedSnapshots, setDisplayedSnapshotsState] = useState<
    DisplayedSnapshot[] | null
  >(null)
  const [highlightEnabled, setHighlight] = useState<boolean>(true)
  const [renderedSnapshotCounter, setRenderedSnapshotCounter] =
    useState<number>(0)

  React.useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    if (!isPinned && displayedSnapshots && displayedSnapshots.length >= 0) {
      interval = setInterval(() => {
        setRenderedSnapshotCounter(
          (val) => (val + 1) % displayedSnapshots.length
        )
      }, 1000)
    }
    return () => {
      if (interval) {
        setRenderedSnapshotCounter(() => 0)
        return clearInterval(interval)
      }
    }
  }, [isPinned, displayedSnapshots, setRenderedSnapshotCounter])
  const renderedSnapshotIndex =
    renderedSnapshotCounter % (displayedSnapshots?.length || 0)
  const renderedSnapshot = React.useMemo<
    DisplayedSnapshot | undefined | null
  >(() => {
    return (
      displayedSnapshots &&
      displayedSnapshots[renderedSnapshotCounter % displayedSnapshots?.length]
    )
  }, [renderedSnapshotCounter, displayedSnapshots])
  const setDisplayedSnapshots = React.useCallback(
    (info: SnapshotInfo, val: DisplayedSnapshot[] | null) => {
      setSnapshotInfo(info)
      setDisplayedSnapshotsState(val)
      setRenderedSnapshotCounter(0)
    },
    [setDisplayedSnapshotsState, setRenderedSnapshotCounter]
  )
  const clearDisplayedSnapshots = React.useCallback(() => {
    setSnapshotInfo(null)
    setDisplayedSnapshotsState(null)
    setRenderedSnapshotCounter(0)
  }, [setDisplayedSnapshotsState, setRenderedSnapshotCounter])
  const toggleHighlight = React.useCallback(() => {
    setHighlight((v) => !v)
  }, [setHighlight])

  return {
    info: snapshotInfo,
    setDisplayedSnapshots,
    clearDisplayedSnapshots,
    setRenderedSnapshotCounter,
    toggleHighlight,

    highlightEnabled,
    renderedSnapshot,
    displayedSnapshots,
    renderedSnapshotIndex,
  }
}
