import { useEffect, useState } from 'react'
import { useDatabaseWorker } from '../components/Context/DatabaseWorkerContext'
import type {
  GetNetworkRequest,
  NetworkRequestReady,
} from '../webworkers/database.worker'

export const useRequestBody = (attNum?: number, reqId?: string) => {
  const [data, setData] = useState<NetworkRequestReady['payload'] | null>(null)
  const { worker } = useDatabaseWorker()

  useEffect(() => {
    if (!attNum || !reqId) {
      setData(null)
      return
    }

    worker.postMessage({
      type: 'GET_NETWORK_REQUEST',
      payload: {
        attNum,
        reqId,
      },
    } as GetNetworkRequest)
  }, [worker, attNum, reqId])

  useEffect(() => {
    function handleNetworkRequest(event: MessageEvent<NetworkRequestReady>) {
      switch (event.data.type) {
        case 'NETWORK_REQUEST_READY':
          setData(event.data.payload)
          break
        default:
          break
      }
    }

    worker.addEventListener('message', handleNetworkRequest)
    return () => {
      worker.removeEventListener('message', handleNetworkRequest)
    }
  }, [worker])

  return data
}
