import { warnOnce } from './Replay/warnOnce'

export const safelyApplyProperty = (
  node: Element,
  name: string,
  value: string | number | boolean
): string | undefined => {
  try {
    ;(node as any)[name] = value
    return name
  } catch (err) {
    warnOnce(
      `${name}_${value}`,
      `Error trying to add invalid property [${name}] to Element`
    )
    return
  }
}
