// @ts-ignore: full filename for esbuild-plugin-inline-worker
import DatabaseWorker, {
  SentryError,
  SentryTransaction,
} from '../../../webworkers/database.worker.js'
import * as Sentry from '@sentry/browser'
import React, { useContext, useEffect, useState } from 'react'

const createWorker = (): Worker => new DatabaseWorker()

type DatabaseWorkerContextType = {
  worker: Worker
}

const DatabaseWorkerContext =
  React.createContext<DatabaseWorkerContextType | null>(null)

export const DatabaseWorkerProvider: React.FC = ({ children }) => {
  // create a true stable instance, since useMemo
  // isn't a semantic guarantee of stability:
  const [worker] = useState(createWorker)

  useEffect(() => {
    function handleTracking(
      event: MessageEvent<SentryTransaction | SentryError>
    ) {
      switch (event.data.type) {
        case 'SENTRY_TRANSACTION':
          // eslint-disable-next-line no-case-declarations
          const s = Sentry?.getCurrentHub()
            ?.getScope()
            ?.getTransaction()
            ?.startChild(event.data.payload)

          if (s) {
            s.finish(event.data.payload.endTimestamp)
          }
          break
        case 'SENTRY_ERROR':
          Sentry?.captureException(event.data.payload.error, {
            tags: event.data.payload.tags,
          })
          break
        default:
          break
      }
    }
    worker.addEventListener('message', handleTracking)
    // prevent memory leak:
    return () => {
      worker.removeEventListener('message', handleTracking)
      worker.terminate()
    }
  }, [worker])

  if (!worker) {
    throw new Error('DatabaseWorker failed to load synchronously.')
  }

  return (
    <DatabaseWorkerContext.Provider value={{ worker }}>
      {children}
    </DatabaseWorkerContext.Provider>
  )
}

export const useDatabaseWorker = () => {
  const workerContext = useContext(DatabaseWorkerContext)
  if (!workerContext) {
    throw new Error('Missing DatabaseWorker context data')
  }
  return workerContext
}
