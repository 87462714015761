import React, { useContext } from 'react'
import { useSearchParams } from './useSearchParams'
import type { NavigateFn } from '@reach/router'

type UrlContextValue = ReturnType<typeof useSearchParams>

const UrlContext = React.createContext<UrlContextValue | null>(null)

export const UrlContextProvider: React.FC<{
  navigate: NavigateFn
}> = ({ children, navigate }) => {
  const val = useSearchParams(navigate)
  return <UrlContext.Provider value={val}>{children}</UrlContext.Provider>
}

export const useUrlContext = () => {
  const val = useContext(UrlContext)
  if (val === null) {
    throw new Error(`Cannot useUrlContext outside of <UrlContextProvider />`)
  }
  return val
}
