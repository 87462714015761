import { useEffect, useRef } from 'react'

export const useAnimationFrame = (
  callback: (timeSkew: number) => void,
  play: boolean,
  speed: number
) => {
  const frameRef = useRef(-1)
  const prevTimeRef = useRef<number | undefined>()
  const playRef = useRef(play)
  playRef.current = play

  function animate(time: number) {
    if (!playRef.current) {
      return
    }
    if (prevTimeRef.current !== undefined) {
      callback(time - prevTimeRef.current)
    }
    prevTimeRef.current = time
    frameRef.current = requestAnimationFrame(animate)
  }

  const animateRef = useRef(animate)
  animateRef.current = animate

  useEffect(() => {
    if (play) {
      frameRef.current = requestAnimationFrame(animateRef.current)
    }
    return () => {
      cancelAnimationFrame(frameRef.current)
      prevTimeRef.current = undefined
      frameRef.current = -1
    }
  }, [play, speed])
}
