import * as Sentry from '@sentry/browser'
import { autMaskPlaceholder } from '../autMaskPlaceholder'
import type {
  CSSAddedStyles,
  CSSAddition,
  CSSAdoptedStyleSheets,
} from '@packages/app-capture-protocol/src/db/schemas/latest'
import { isArray, isString } from 'lodash'
import { swallowCssErrors } from '../Events/cssEvents'
import { ElementIdToDelayedCallbacksArrayMap } from '../Events/ElementIdToDelayedCallbacksArrayMap'

export const TEST_REPLAY_CONTROLLED_STYLESHEET_COMMENT =
  '/* Cypress Test Replay */'

export type ValidDocumentReference =
  | Node
  | Element
  | ReturnType<
      | Document['createElement']
      | Document['implementation']['createDocumentType']
      | Document['createTextNode']
    >
  | Document

type ReturnElementWithShadowRootAnalysis<T> =
  | {
      isShadowRoot: true
      el: ShadowRoot
    }
  | {
      isShadowRoot: false
      el: T | null
    }

type PendingIFrame = {
  iframe: HTMLIFrameElement
  contentDocumentNode: {
    id: number
    documentURL?: string
  }
}

const TEST_REPLAY_DEFINED_ADOPTED_STYLESHEET_ID = '__cy_tr_overrides'

export class NodeMap {
  /**
   * IMPORTANT:
   *
   * If new attributes are added to this class, then
   * we also need to add them in the reset() method.
   */
  adoptedStylesheets: {
    id: string
    frameId: string
    sheet: CSSStyleSheet
    shadowHostElementId?: number
  }[] = []
  contentDocumentNodes: number[] = []
  pendingIFrameMap: Map<string, PendingIFrame> = new Map()
  frameIdMap: Map<string, ValidDocumentReference> = new Map()
  idNodeMap: Map<number, ValidDocumentReference> = new Map()
  nodeSerializedMap: WeakMap<ValidDocumentReference, number> = new WeakMap()
  styleSheetIdMap: Map<string, HTMLStyleElement> = new Map()
  postAddingToWindowCallbacks: (() => void)[] = []
  documentElementCallbackMap: Map<string, () => boolean> = new Map()
  elementIdToDelayedCallbacksArrayMap: ElementIdToDelayedCallbacksArrayMap =
    new ElementIdToDelayedCallbacksArrayMap()
  private _pendingElementStylesheets: Set<HTMLStyleElement> = new Set()
  private _pendingAdoptedStylesheets: Set<string> = new Set()

  reset() {
    this.adoptedStylesheets = []
    this.contentDocumentNodes = []
    this.frameIdMap = new Map()
    this.idNodeMap = new Map()
    this.nodeSerializedMap = new WeakMap()
    this.styleSheetIdMap = new Map()
    this._pendingElementStylesheets = new Set()
    this._pendingAdoptedStylesheets = new Set()
    this.pendingIFrameMap = new Map()

    // Also remove the pending iframes from the DOM
    document
      .querySelectorAll('[data-cy-replay-pending-iframe]')
      .forEach((el) => {
        el.remove()
      })

    this.postAddingToWindowCallbacks = []
    this.elementIdToDelayedCallbacksArrayMap =
      new ElementIdToDelayedCallbacksArrayMap()
    this.documentElementCallbackMap = new Map()
  }

  /**
   * Applies all pending adopted stylesheets to their respective frames.
   * This is necessary because the frames might not be ready when the stylesheets are created.
   */
  applyPendingAdoptedStylesheets() {
    this._pendingAdoptedStylesheets.forEach((frameId) =>
      this.setAdoptedStylesheets(frameId)
    )
    this._pendingAdoptedStylesheets.clear()
  }

  applyPendingElementStylesheets() {
    this._pendingElementStylesheets.forEach((element) => {
      if (element.sheet) {
        element.textContent = `${TEST_REPLAY_CONTROLLED_STYLESHEET_COMMENT}\n${Array.from(
          element.sheet.cssRules
        )
          .map((rule) => rule.cssText)
          .join('\n')}`
      }
    })

    this._pendingElementStylesheets.clear()
  }

  getId(node: ReturnType<Document['createElement']>) {
    return this.nodeSerializedMap.get(node)
  }

  getNode(id?: number) {
    if (!id) {
      return undefined
    }
    const node = this.idNodeMap.get(id)
    const isContentDocument = this.contentDocumentNodes.includes(id)
    if (!node) {
      return
    }
    if (isContentDocument) {
      return (node as HTMLIFrameElement).contentDocument
    }
    return node
  }

  // TODO: Types also not right here for DomNode
  add(
    createdNode: ValidDocumentReference | null,
    serialized: { id: number },
    isContentDocument: boolean = false
  ) {
    if (createdNode == null) {
      return
    }
    this.idNodeMap.set(serialized.id, createdNode)
    this.nodeSerializedMap.set(createdNode, serialized.id)
    if (isContentDocument) {
      this.contentDocumentNodes.push(serialized.id)
    }
  }

  remove(nodeId: number) {
    const nodeToRemove = this.getNode(nodeId)
    this.idNodeMap.delete(nodeId)
    if (nodeToRemove) {
      this.nodeSerializedMap.delete(nodeToRemove)
    }
  }

  addFrame(createdNode: ValidDocumentReference, frameId: string) {
    this.frameIdMap.set(frameId, createdNode)
    // Setup a default stylesheet that allows us to modify elements displayed in test replay
    this.createAdoptedStylesheet(
      TEST_REPLAY_DEFINED_ADOPTED_STYLESHEET_ID,
      frameId,
      `
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 0 !important;
}
video::-webkit-media-controls {
    display: none !important;
}
canvas {
  display: block;
  background-image: url(${autMaskPlaceholder}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
video {
  background-image: url(${autMaskPlaceholder}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
object:not([type='image/svg+xml']) {
  background-image: url(${autMaskPlaceholder}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
`
    )
  }

  addPendingIFrame(frameId: string, pendingIFrame: PendingIFrame) {
    this.pendingIFrameMap.set(frameId, pendingIFrame)
  }

  getFrameDoc(frameId: string): Document | null | undefined {
    const frame = this.frameIdMap.get(frameId) as
      | HTMLIFrameElement
      | HTMLHtmlElement

    if (!frame) {
      return null
    }

    // If the frame is linked to an Iframe, or object, it's safe to assume we want the content
    // for the nested iframe. It's okay for this to be null, that might happen if the node is removed
    // before the stylesheet events happen.
    if (['IFRAME', 'OBJECT'].includes(frame.localName.toUpperCase())) {
      return (frame as HTMLIFrameElement).contentDocument
    }
    return (frame as HTMLElement).ownerDocument
  }

  get frameIds() {
    return this.frameIdMap.keys()
  }

  private queryDocumentOrShadowRoot<T extends HTMLElement>(
    elementSelector: string,
    frame: Document | ShadowRoot
  ): ReturnElementWithShadowRootAnalysis<T> {
    const element = frame.querySelector<T>(elementSelector)
    if (element?.shadowRoot) {
      return {
        isShadowRoot: true,
        el: element.shadowRoot,
      }
    }

    return {
      isShadowRoot: false,
      el: element,
    }
  }

  private queryInFrameMixin<T extends HTMLElement, V>(
    frame: Document | null | undefined,
    elementSelector: string | string[],
    mixinQuerySelector: (
      frame: Document | ShadowRoot,
      selector: string
    ) => V | null
  ) {
    if (frame) {
      if (
        isString(elementSelector) ||
        (isArray(elementSelector) && elementSelector.length === 1)
      ) {
        const selector = isString(elementSelector)
          ? elementSelector
          : (elementSelector[0] as string)
        return mixinQuerySelector(frame, selector)
      }
      let currentIndexDocument: Document | ShadowRoot = frame
      // Since shadow DOM elements are hidden to the rest of the general DOM, we need to traverse each shadowRoot
      // in order to find the last element in the array, which is our element to highlight. Every node in between should be a shadowRoot
      try {
        for (const selector of elementSelector) {
          // @ts-ignore
          const { isShadowRoot, el } = this.queryDocumentOrShadowRoot<T>(
            selector,
            currentIndexDocument
          )
          if (isShadowRoot) {
            currentIndexDocument = el
          } else {
            // now that we are down the the base shadowRoot, we can either apply 'querySelector' or
            // 'querySelectorAll' to the correct document to return the nodes discovered

            // NOTE: there is a bit of a redundant step of re-querying with querySelector for what was likely found in
            // 'queryDocumentOrShadowRoot', but it's good to keep the concerns separated as 'queryDocumentOrShadowRoot'
            // is only responsibly for traversing the shadowRoot
            return mixinQuerySelector(currentIndexDocument, selector)
          }
        }
      } catch (e) {
        return null
      }
    }
    return null
  }

  queryInFrame<T extends HTMLElement>(
    frame: Document | null | undefined,
    elementSelector: string | string[]
  ): T | null {
    if (frame) {
      return this.queryInFrameMixin<T, T>(
        frame,
        elementSelector,
        (foundFrame, elSelector) => foundFrame.querySelector<T>(elSelector)
      )
    }
    return null
  }

  queryInFrameByFrameId<T extends HTMLElement>(
    frameId: string,
    elementSelector: string | string[]
  ): T | null {
    const frameDoc = this.getFrameDoc(frameId)
    if (frameDoc) {
      return this.queryInFrameMixin<T, T>(
        frameDoc,
        elementSelector,
        (foundFrame, elSelector) => foundFrame.querySelector<T>(elSelector)
      )
    }
    return null
  }

  queryAllInFrame<T extends HTMLElement>(
    frameId: string,
    elementSelector: string | string[]
  ): NodeListOf<T> | null {
    const frameDoc = this.getFrameDoc(frameId)
    if (frameDoc) {
      return this.queryInFrameMixin<T, NodeListOf<T>>(
        frameDoc,
        elementSelector,
        (foundFrame, elSelector) => foundFrame.querySelectorAll<T>(elSelector)
      )
    }
    return null
  }

  addStyleSheet(stylesheetId: string, node: HTMLStyleElement) {
    this.styleSheetIdMap.set(stylesheetId, node)
  }

  getStyleSheet(stylesheetId: string): HTMLStyleElement | undefined {
    return this.styleSheetIdMap.get(stylesheetId)
  }

  getAdoptedStyleSheet(styleSheetId: string) {
    return this.adoptedStylesheets.find((as) => as.id === styleSheetId)
  }

  getAdoptedStyleSheetFrame(stylesheetId: string): string {
    return this.getAdoptedStyleSheet(stylesheetId)?.frameId || ''
  }

  /**
   * Polyfill for adopted stylesheets. Creates a style element with the same content that the adopted stylesheet has.
   * Always sets it as the last element in the head, so it has the highest priority.
   * @param frameId Frame to add the stylesheet to
   */
  private setAdoptedStylesheets(frameId: string) {
    const frameDoc = this.getFrameDoc(frameId)
    if (frameDoc) {
      try {
        const stylesheet = frameDoc.querySelectorAll(
          'style[data-cy-replay-adopted-stylesheet]'
        )
        stylesheet.forEach((s) => s.remove())
        this.adoptedStylesheets
          .filter((as) => as.frameId === frameId)
          .forEach((frameSheet) => {
            const style = frameDoc.createElement('style')
            style.setAttribute(
              'data-cy-replay-adopted-stylesheet',
              frameSheet.id
            )

            let stylesheetText =
              '/* This is a polyfill for displaying adopted stylesheets. See: https://developer.mozilla.org/en-US/docs/Web/API/Document/adoptedStyleSheets */\n'
            for (let i = 0; i < frameSheet.sheet.cssRules.length; i++) {
              stylesheetText += `${frameSheet.sheet.cssRules[i]!.cssText}\n`
            }
            style.textContent = stylesheetText
            if (frameSheet.shadowHostElementId) {
              const element = this.getNode(
                frameSheet.shadowHostElementId
              ) as HTMLElement
              element?.shadowRoot?.appendChild(style)
              // If there is no head or body yet, we shouldn't try and append the default test replay adopted stylesheet
            } else if (
              frameDoc.head ||
              frameDoc.body ||
              frameDoc.documentElement ||
              frameSheet.id !== TEST_REPLAY_DEFINED_ADOPTED_STYLESHEET_ID
            ) {
              const styleParent =
                frameDoc.head ?? frameDoc.body ?? frameDoc.documentElement
              styleParent.appendChild(style)
            }
          })
      } catch (err) {
        if (!((err as Error).name === 'NotAllowedError')) {
          Sentry.captureException(err)
        }
      }
    }
  }

  queueElementStyleSheetUpdate(element: HTMLStyleElement) {
    this._pendingElementStylesheets.add(element)
  }

  queueAdoptedStylesheetUpdate(frameId: string) {
    this._pendingAdoptedStylesheets.add(frameId)
  }

  createAdoptedStylesheet(
    stylesheetId: string,
    frameId: string,
    sheetText: string
  ) {
    const frameDoc = this.getFrameDoc(frameId)
    const isStyleSheetCreated = this.adoptedStylesheets.some(
      (as) => as.id === stylesheetId && as.frameId === frameId
    )
    if (frameDoc && !isStyleSheetCreated) {
      const scopeForCSS = frameDoc.defaultView
      if (!scopeForCSS) {
        return
      }
      const sheet = new scopeForCSS.CSSStyleSheet()
      swallowCssErrors(() => {
        sheet.replaceSync(sheetText)
      })
      this.adoptedStylesheets.push({
        id: stylesheetId,
        frameId,
        sheet,
      })
      this.queueAdoptedStylesheetUpdate(frameId)
    }
  }

  resolveAdoptedStylesheets(resolvedStylesheets: CSSAdoptedStyleSheets) {
    const { styleSheetIds, addedStyles, frameId, shadowHostElementId } =
      resolvedStylesheets

    const unprocessedStyleSheetIds = [...styleSheetIds]
    if (addedStyles.length > 0) {
      // Create all of the new stylesheets that have their rules newly added here
      addedStyles.forEach((addedStyle: CSSAddedStyles) => {
        this.createAdoptedStylesheetFromRules(
          addedStyle.styleSheetId,
          frameId,
          addedStyle.additions,
          shadowHostElementId
        )
        unprocessedStyleSheetIds.splice(
          unprocessedStyleSheetIds.indexOf(addedStyle.styleSheetId),
          1
        )
      })
    }

    // For each stylesheet that was not added above, we either load it from an existing stylesheet
    // if one exists with the same id, or we create a new one with no rules
    unprocessedStyleSheetIds.forEach((id) => {
      const stylesheet = this.getAdoptedStyleSheet(id)
      if (stylesheet) {
        this.adoptedStylesheets.push({
          id,
          frameId,
          sheet: stylesheet.sheet,
          shadowHostElementId,
        })
      } else {
        this.createAdoptedStylesheetFromRules(
          id,
          frameId,
          [],
          shadowHostElementId
        )
      }
    })

    // Determine which style sheets potentially need to be removed. Start with all style sheets for the current
    // shadow host (ensuring to keep the test replay defined adopted style sheet), and then remove the ones that
    // are still in use.
    const potentialStyleSheetsToRemove = this.adoptedStylesheets
      .map((as) => ({
        id: as.id,
        shadowHostElementId: as.shadowHostElementId,
      }))
      .filter(
        (metadata) =>
          metadata.id !== TEST_REPLAY_DEFINED_ADOPTED_STYLESHEET_ID &&
          metadata.shadowHostElementId === shadowHostElementId
      )
    styleSheetIds.forEach((id) => {
      potentialStyleSheetsToRemove.splice(
        potentialStyleSheetsToRemove.indexOf({
          id,
          shadowHostElementId,
        }),
        1
      )
    })

    // Remove the stylesheets that are no longer needed
    potentialStyleSheetsToRemove.forEach((metadata) => {
      this.deleteAdoptedStylesheet(metadata)
    })

    // Update the stylesheets
    this.queueAdoptedStylesheetUpdate(frameId)
  }

  private createAdoptedStylesheetFromRules(
    styleSheetId: string,
    frameId: string,
    rules: CSSAddition[],
    shadowHostElementId?: number
  ) {
    const frameDoc = this.getFrameDoc(frameId)
    if (frameDoc) {
      const scopeForCSS = frameDoc.defaultView
      if (!scopeForCSS) {
        return
      }
      const sheet =
        this.getAdoptedStyleSheet(styleSheetId)?.sheet ||
        new scopeForCSS.CSSStyleSheet()
      rules.forEach((rule) => {
        const index = rule.index ? rule.index[0] : 0
        swallowCssErrors(() => {
          sheet.insertRule(rule.rule, index)
        })
      })
      this.adoptedStylesheets.push({
        id: styleSheetId,
        frameId,
        sheet,
        shadowHostElementId,
      })
    }
  }

  getAdoptedStylesheetForModification(stylesheetId: string) {
    const styleSheet = this.getAdoptedStyleSheet(stylesheetId)

    if (styleSheet) {
      this.queueAdoptedStylesheetUpdate(styleSheet.frameId)
    }

    return styleSheet
  }

  updateAdoptedStylesheet(stylesheetId: string, text: string) {
    const adoptedStylesheetIdx = this.adoptedStylesheets.findIndex(
      (as) => as.id === stylesheetId
    )
    if (adoptedStylesheetIdx >= 0) {
      const stylesheet = this.adoptedStylesheets[adoptedStylesheetIdx]
      if (stylesheet) {
        swallowCssErrors(() => {
          stylesheet.sheet.replaceSync(text)
        })
        this.adoptedStylesheets[adoptedStylesheetIdx] = stylesheet
        this.queueAdoptedStylesheetUpdate(stylesheet.frameId)
      }
    }
  }

  deleteAdoptedStylesheet(metadata: {
    id: string
    shadowHostElementId?: number
  }) {
    const adoptedStylesheetIdx = this.adoptedStylesheets.findIndex(
      (as) =>
        as.id === metadata.id &&
        as.shadowHostElementId === metadata.shadowHostElementId
    )
    if (adoptedStylesheetIdx >= 0) {
      const [removedStylesheet] = this.adoptedStylesheets.splice(
        adoptedStylesheetIdx,
        1
      )
      if (removedStylesheet) {
        this.queueAdoptedStylesheetUpdate(removedStylesheet.frameId)
      }
    }
  }
}
