import moment from 'moment'

export function formatTestDuration(
  milliseconds: number,
  opts?: {
    forceSeconds?: boolean
  }
) {
  if (milliseconds < 1000 && !opts?.forceSeconds) {
    return `${Math.round(milliseconds)}ms`
  }
  const duration = moment.duration(milliseconds)
  const minutes = Math.floor(duration.asMinutes())
  const seconds = Math.floor(duration.seconds())
  const withPadding = (str: string) => str.padStart(2, '0')
  return `${withPadding(minutes.toString())}:${withPadding(seconds.toString())}`
}
