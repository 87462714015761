import { usePreviousValueOf } from '@frontend/dashboard/src/lib/hooks/usePreviousValueOf'
import React, { useContext, useEffect, useRef, useState } from 'react'

type SnapshotChangeContextValue = {
  listContainerRef: React.RefObject<HTMLDivElement>
  isSnapshotChangeBlocked: boolean
}

const DELAY = 100 // milliseconds

const SnapshotChangeContext =
  React.createContext<SnapshotChangeContextValue | null>(null)

const useMouseStopDetector = () => {
  const [enabled, setEnabled] = useState(false)
  const listContainerRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const refEnabled = useRef(enabled)
  useEffect(() => {
    refEnabled.current = enabled
  }, [enabled])

  useEffect(() => {
    function onMouseMove() {
      if (refEnabled.current) {
        return
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        setEnabled(true)
      }, DELAY)
    }

    function onMouseLeave() {
      if (refEnabled.current) {
        setEnabled(false)
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }

    const node = listContainerRef.current
    if (node) {
      node.addEventListener('mousemove', onMouseMove)
      node.addEventListener('mouseleave', onMouseLeave)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      if (node) {
        node.removeEventListener('mousemove', onMouseMove)
        node.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [])

  return {
    isSnapshotChangeBlocked: !enabled,
    listContainerRef,
  }
}

export const SnapshotChangeContextProvider: React.FC = ({ children }) => {
  const value = useMouseStopDetector()
  return (
    <SnapshotChangeContext.Provider value={value}>
      {children}
    </SnapshotChangeContext.Provider>
  )
}

export const useSnapshotChangeContext = () => {
  const value = useContext(SnapshotChangeContext)
  if (!value) {
    throw new Error(
      'Cannot invoke useSnapshotChangeContext() outside of SnapshotChangeContext'
    )
  }
  return value
}

const useMouseInsideDevtoolsItem = () => {
  const [isMouseInside, setMouseInside] = useState(false)
  const devtoolsItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleMouseEnter = () => setMouseInside(true)
    const handleMouseLeave = () => setMouseInside(false)

    const element = devtoolsItemRef.current
    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter)
      element.addEventListener('mouseleave', handleMouseLeave)

      return function cleanup() {
        element.removeEventListener('mouseenter', handleMouseEnter)
        element.removeEventListener('mouseleave', handleMouseLeave)
      }
    }

    return function cleanup() {}
  }, [])

  return {
    isMouseInside,
    devtoolsItemRef,
  }
}

export const useHandleDevtoolsItemSnapshotEvents = (
  updateSnapshot?: VoidFunction,
  resetSnapshot?: VoidFunction
) => {
  const { isSnapshotChangeBlocked } = useSnapshotChangeContext()
  const { devtoolsItemRef, isMouseInside } = useMouseInsideDevtoolsItem()
  const wasMouseInside = usePreviousValueOf(isMouseInside)

  // prevents unnecessary rerenders
  // due to unstable callback refs:
  const updateSnapshotRef = useRef(updateSnapshot)
  useEffect(() => {
    updateSnapshotRef.current = updateSnapshot
  }, [updateSnapshot])

  // prevents unnecessary rerenders
  // due to unstable callback refs:
  const resetSnapshotRef = useRef(resetSnapshot)
  useEffect(() => {
    resetSnapshotRef.current = resetSnapshot
  }, [resetSnapshot])

  useEffect(() => {
    if (!isSnapshotChangeBlocked) {
      if (isMouseInside) {
        updateSnapshotRef.current?.()
      } else if (wasMouseInside) {
        resetSnapshotRef.current?.()
      }
    }
  }, [isMouseInside, isSnapshotChangeBlocked, wasMouseInside])

  return {
    devtoolsItemRef,
  }
}
