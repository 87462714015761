import 'css-chunk:src/components/ReplayBody/CommandLogs/CommandLogs.module.scss';export default {
  "status-icon-container": "_status-icon-container_15q3h_7",
  "command-logs-container": "_command-logs-container_15q3h_16",
  "header-container": "_header-container_15q3h_24",
  "titles-container": "_titles-container_15q3h_32",
  "title": "_title_15q3h_32",
  "subtitle": "_subtitle_15q3h_47",
  "attempt-wrapper": "_attempt-wrapper_15q3h_57",
  "tooltip-container": "_tooltip-container_15q3h_61",
  "tooltip-title-row": "_tooltip-title-row_15q3h_65",
  "tooltip-title": "_tooltip-title_15q3h_65"
};