import React, { useMemo, useState } from 'react'
import styles from './NetworkItemDetails.module.scss'
import {
  NetworkItemLeading,
  NetworkItemStatus,
  NetworkResponse,
  NetworkResponseType,
  NetworkStatus,
} from '../NetworkItem/NetworkItem'
import type { useResponseBody } from '../../../../../utils/useResponseBody'
import { formatTestDuration } from '../../../../../utils/formatTestDuration'
import Tabs from '@cypress-design/react-tabs'
import Button from '@cypress-design/react-button'
import Icon from '@cypress-design/react-icon'
import { CodeSnippetV2 } from '@frontend/dashboard/src/common/code-snippet/CodeSnippet'
import Tooltip from '@cypress-design/react-tooltip'
import type { Timeline } from '../../WaterfallPill/WaterfallPill'
import type { OutOfBoundsKind } from '../NetworkPanel'
import './NetworkItemDetails.scss'
import { LargeDownload } from '../../LargeDownload/LargeDownload'
import { getTextSize } from '../../../../../utils/getTextSize'
import { downloadFile } from '../../../../../utils/downloadFile'
import type { useRequestBody } from '~/src/utils/useRequestBody'

type RequestInfoValuePros = {
  title: React.ReactNode
  value: number | string
  dataCy: string
}

type NetworkItemDetailsTypes = 'key_value_pairs' | 'code_snippet'

type CollapsingDivProps = {
  title: string
  children: React.ReactChild
  type: NetworkItemDetailsTypes
}

type RequestContentProps = {
  content: {
    [k: string]: string | number | boolean
  }
}

type NetworkItemDetails = {
  type: NetworkItemDetailsTypes
  title: string
} & RequestContentProps

type NetworkItemDetailsHeaderProps = {
  timeline: Timeline
  status: NetworkItemStatus
  networkResponse: NetworkResponseType
  isServiceWorkerRequest: boolean
  onClose: () => void
  outOfBoundsKind?: OutOfBoundsKind
}

type RequestInfoProps = {
  start: number
  duration: number
  type: string
  outOfBoundsKind?: OutOfBoundsKind
}

type RespDownloadInfo = {
  clipped: boolean
  message: string
  originalSize: string
}

export type NetworkItemDetailsProps = {
  requestStart: number
  requestDuration: number
  requestType: string
  isServiceWorkerRequest: boolean
  content: {
    request: NetworkItemDetails[]
    response: NetworkItemDetails[]
  }
  outOfBoundsKind?: OutOfBoundsKind
  attNum: number
  reqId: string
  responseBody: ReturnType<typeof useResponseBody>
  requestBody: ReturnType<typeof useRequestBody>
} & NetworkItemDetailsHeaderProps

export const TestIsolationLearnMore = () => (
  <a href="https://on.cypress.io/test-isolation" target="_blank">
    Learn more
  </a>
)

const OutOfBoundsInfo = () => (
  <div data-cy="OutOfBoundsInfo" className={styles['alert-info']}>
    Test execution ended before the network response was received.
  </div>
)

const OutOfBoundsDanger = () => (
  <div data-cy="OutOfBoundsDanger" className={styles['alert-danger']}>
    This request started in a different attempt or test. Enable Test Isolation
    to prevent requests leaking from one attempt or test to another.{' '}
    <TestIsolationLearnMore />
  </div>
)

const RequestInfoValue = ({ title, value, dataCy }: RequestInfoValuePros) => {
  return (
    <span data-cy={dataCy} className={styles['requestInfoValue']}>
      <span className={styles['requestInfoValueTitle']}>{title}</span>
      <span>{value}</span>
    </span>
  )
}

const RequestInfo = (props: RequestInfoProps) => {
  const noValueDisplay = '--'

  return (
    <div className={styles['requestInfo']}>
      <RequestInfoValue
        dataCy="request-info-start"
        title="Request start"
        value={
          props.outOfBoundsKind === 'start' ||
          props.outOfBoundsKind === 'startAndEnd'
            ? noValueDisplay
            : formatTestDuration(props.start).padStart(5, '0')
        }
      />
      <div className={styles['dotDivider']}>·</div>

      <Tooltip
        popper={
          <div
            data-cy="request-info-duration-tooltip"
            className={styles['tooltipContainer']}
          >
            <span>
              Time between when the request was sent and when the response was
              received
            </span>
          </div>
        }
        type="light"
        placement="bottom"
      >
        <RequestInfoValue
          dataCy="request-info-duration"
          title="Duration"
          value={
            !!props.outOfBoundsKind
              ? noValueDisplay
              : formatTestDuration(props.duration).padStart(5, '0')
          }
        />
      </Tooltip>

      <div className={styles['dotDivider']}>·</div>
      <RequestInfoValue
        dataCy="request-info-type"
        title="Type"
        value={!!props.type ? props.type : noValueDisplay}
      />
    </div>
  )
}

const NetworkItemDetailsHeader = (props: NetworkItemDetailsHeaderProps) => {
  return (
    <>
      <NetworkItemLeading
        outOfBoundsKind={props.outOfBoundsKind}
        timeline={props.timeline}
        status={props.status}
        withoutTooltip
      />
      <NetworkResponse
        isServiceWorkerRequest={props.isServiceWorkerRequest}
        networkResponse={props.networkResponse}
      />

      <Button
        data-cy="network-details-close"
        onClick={props.onClose}
        style={{ background: 'transparent', padding: 0 }}
        variant="outline-dark"
        size="20"
        data-pendo="replay-network-item-close"
      >
        <Icon name="action-delete-medium" size="16" strokeColor="gray-600" />
      </Button>
    </>
  )
}

const CollapsingDiv = ({ title, children, type }: CollapsingDivProps) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div data-cy="collapsible-div" className={styles['collapsingDiv']}>
      <Button
        className={styles['collapsingDivTitleIcon']}
        style={{ background: 'transparent', padding: 0 }}
        variant="link"
        size="20"
        onClick={() => setIsExpanded(!isExpanded)}
        data-pendo={`replay-collapsible-div-${title
          .toLowerCase()
          .replace(' ', '-')}`}
      >
        <Icon
          name={isExpanded ? 'chevron-down-small' : 'chevron-right-small'}
          size="16"
          strokeColor="gray-600"
        />
      </Button>

      <span
        data-cy="collapsible-div-title"
        className={styles['collapsingDivTitle']}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {title}
      </span>
      {(() => {
        if (!isExpanded) {
          return null
        }

        if (type === 'code_snippet') {
          return (
            <div className={styles['collapsingDivCodeContent']}>{children}</div>
          )
        }
        return (
          <div
            data-cy="collapsible-div-children"
            className={styles['collapsingDivContent']}
          >
            {children}
          </div>
        )
      })()}
    </div>
  )
}

const RequestContent = (props: RequestContentProps) => {
  return (
    <div>
      {Object.keys(props.content).map((key, idx) => (
        <div key={idx} className={styles['requestContent']}>
          <span className={styles['requestContentKey']}>{key}:</span>{' '}
          {key === 'Status Code' ? (
            <span className={styles['requestContentNetworkStatus']}>
              <NetworkStatus status={props.content[key] as number} />
            </span>
          ) : (
            <span className={styles['requestContentValue']}>
              {props.content[key]?.toString()}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

const RequestContentCode = (props: RequestContentProps) => {
  let code = props.content.body as string
  try {
    code = JSON.stringify(JSON.parse(code), null, 2)
  } catch {}

  return (
    <CodeSnippetV2
      code={code}
      copyText={code}
      language="markup"
      variant="test-replay"
      withCopyBtn
      iconOnlyCopyBtn
      overlayClassName="test-replay-code-snippet"
      copyBtnPendo="replay-code-snippet-copy"
    />
  )
}

const getDownloadInfo = (body?: string) => {
  if (!body) {
    return null
  }
  const sizeInfo = getTextSize(body)
  if (sizeInfo.kilobytes > 10) {
    return {
      clipped: true,
      message: body.slice(0, 5000),
      originalSize:
        sizeInfo.kilobytes < 1000
          ? `${sizeInfo.kilobytes}kB`
          : `${sizeInfo.megabytes}MB`,
    }
  }
  return null
}

const PayloadBody = ({
  type,
  title,
  body,
  downloadInfo,
}: {
  type: string
  title: string
  body: string
  downloadInfo: RespDownloadInfo | null
}) => {
  return (
    <CollapsingDiv title={title} type="code_snippet">
      {downloadInfo ? (
        <LargeDownload
          onClick={() =>
            downloadFile({
              filename: `${type}_body.txt`,
              text: body,
            })
          }
          message={`Sorry, we can't show ${type} bodies that are this big.`}
          size={downloadInfo.originalSize}
        />
      ) : (
        <RequestContentCode
          content={{
            body,
          }}
        />
      )}
    </CollapsingDiv>
  )
}

export const NetworkItemDetails = (props: NetworkItemDetailsProps) => {
  const [activeTab, setActiveTab] = React.useState<'request' | 'response'>(
    'request'
  )
  const onTabSwitch = React.useCallback(({ id }: { id: string }) => {
    if (['request', 'response'].includes(id)) {
      setActiveTab(id as 'request' | 'response')
    }
  }, [])

  const respBody = props.responseBody?.data
  const reqBody = props.requestBody?.data

  // tbd: move analysis and clipping to webworker; but
  // testing has shown that this is a large improvement:
  const respDownloadInfo = useMemo(() => getDownloadInfo(respBody), [respBody])
  const reqDownloadInfo = useMemo(() => getDownloadInfo(reqBody), [reqBody])

  return (
    <div className={styles['container']} data-cy="network-details-container">
      <div data-cy="network-details-header" className={styles['header']}>
        <NetworkItemDetailsHeader
          isServiceWorkerRequest={props.isServiceWorkerRequest}
          timeline={props.timeline}
          status={props.status}
          outOfBoundsKind={props.outOfBoundsKind}
          networkResponse={props.networkResponse}
          onClose={props.onClose}
        />
      </div>

      <div className={styles['body']}>
        <RequestInfo
          start={props.requestStart}
          duration={props.requestDuration}
          outOfBoundsKind={props.outOfBoundsKind}
          type={props.requestType}
        />

        <div className="mt-[10px]">
          {props.outOfBoundsKind === 'end' && <OutOfBoundsInfo />}
          {(props.outOfBoundsKind === 'start' ||
            props.outOfBoundsKind === 'startAndEnd') && <OutOfBoundsDanger />}
        </div>

        <div data-cy="network-item-details-tabs" className={styles['tabs']}>
          <Tabs
            variant="dark-large"
            activeId={activeTab}
            tabs={[
              {
                id: 'request',
                label: 'Request',
                'data-pendo': 'replay-network-item-details-request-tab',
                'aria-controls': 'request-tabpanel',
              },
              {
                id: 'response',
                label: 'Response',
                'data-pendo': 'replay-network-item-details-response-tab',
                'aria-controls': 'response-tabpanel',
              },
            ]}
            onSwitch={onTabSwitch}
          />
        </div>

        <div
          className={styles['content']}
          role="tabpanel"
          id={`${activeTab}-tabpanel`}
        >
          <div className={styles['contentHeader']}></div>
          <div className={styles['contentBody']}>
            {props.content[activeTab].map((content, idx) => (
              <CollapsingDiv
                key={`${activeTab}-${idx}`}
                title={content.title}
                type={content.type}
              >
                {content.type === 'code_snippet' && content ? (
                  <RequestContentCode content={content.content} />
                ) : (
                  <RequestContent content={content.content} />
                )}
              </CollapsingDiv>
            ))}
            {/* loaded and fed separately for performance reasons */}
            {activeTab === 'request' && reqBody && (
              <PayloadBody
                type="request"
                body={reqBody}
                title="Request body"
                downloadInfo={reqDownloadInfo}
              />
            )}

            {/* loaded and fed separately for performance reasons */}
            {activeTab === 'response' && respBody && (
              <PayloadBody
                type="response"
                title="Response body"
                body={respBody}
                downloadInfo={respDownloadInfo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
